import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import IconArrow from '@/assets/icons/icon-arrow.svg';
import CURRENCIES from '@/configs/currencies';
import { IS_ONLY_TESTNET_ALLOWED } from '@/configs/devEnv/testnetLaunch';
import LANGUAGES from '@/configs/languages';
import { CurrencyId } from '@/types/env/Currency';
import IsTestnet from '@/types/env/IsTestnet';
import { LanguageId } from '@/types/env/Language';
import { SettingMenu } from '.';

const Wrapper = styled.div`
  position: relative;
  width: 240px;

  ul {
    margin-top: 16px;
    text-align: center;
  }

  li + li {
    margin-top: 16px;
  }
`;

const Title = styled.p`
  font-size: ${(p) => p.theme.size.md};
  ${(p) => p.theme.font.bold};
  line-height: 1.5;
`;

const IconThinArrow = styled(IconArrow)`
  transform: rotate(-90deg) scale(0.68);
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    display: flex;
    gap: 4px;
    outline: none;

    span {
      display: inline-block;
      padding: 0 4px;
      background: ${(p) => p.theme.colors.black};
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400;
      color: ${(p) => p.theme.colors.green};
      line-height: 24px;
    }
  }
`;

interface Props {
  isShow: boolean;
  languageId: LanguageId;
  currencyId: CurrencyId;
  isTestnet: IsTestnet;
  onMenuClick: (menu: SettingMenu) => () => void;
}

function DefaultSettingDialog({
  isShow,
  languageId,
  currencyId,
  isTestnet,
  onMenuClick: handleMenuClick,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const language = useMemo(
    () => LANGUAGES.find((language) => language.id === languageId),
    [languageId]
  );
  const currency = useMemo(
    () => CURRENCIES.find((currency) => currency.id === currencyId),
    [currencyId]
  );

  if (!isShow) {
    return;
  }

  return (
    <Wrapper
      as={motion.div}
      initial={{
        x: '-100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: '-100%',
      }}
      {...rest}
    >
      <Title>{t('common:settings')}</Title>
      <ul>
        <li>
          <FlexRow>
            <span>{t('common:language')}</span>
            <button type="button" onClick={handleMenuClick('language')}>
              <span>{language?.title}</span>
              <IconThinArrow />
            </button>
          </FlexRow>
        </li>
        <li>
          <FlexRow>
            <span>{t('common:baseUnit')}</span>
            <button type="button" onClick={handleMenuClick('currency')}>
              <span>{currency?.title}</span>
              <IconThinArrow />
            </button>
          </FlexRow>
        </li>
        {!IS_ONLY_TESTNET_ALLOWED ? (
          <li>
            <FlexRow>
              <span>{t('common:network')}</span>
              <button type="button" onClick={handleMenuClick('network')}>
                <span>
                  {isTestnet ? t('common:testnet') : t('common:mainnet')}
                </span>
                <IconThinArrow />
              </button>
            </FlexRow>
          </li>
        ) : (
          ''
        )}
      </ul>
    </Wrapper>
  );
}

export default DefaultSettingDialog;
