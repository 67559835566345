export type IconProps = {
  size?: number;
  color?: string;
  height?: number;
  width?: number;
};

export const ArrowIcon = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.9531 11.9141C15.9531 12.0495 15.9271 12.1745 15.875 12.2891C15.8229 12.4036 15.7448 12.513 15.6406 12.6172L9.55469 18.5703C9.38281 18.7474 9.16927 18.8359 8.91406 18.8359C8.7474 18.8359 8.59375 18.7943 8.45312 18.7109C8.3125 18.6328 8.20052 18.5234 8.11719 18.3828C8.03906 18.2474 8 18.0964 8 17.9297C8 17.6797 8.09375 17.4583 8.28125 17.2656L13.7656 11.9141L8.28125 6.5625C8.09375 6.36979 8 6.15104 8 5.90625C8 5.73438 8.03906 5.58073 8.11719 5.44531C8.20052 5.3099 8.3125 5.20313 8.45312 5.125C8.59375 5.04167 8.7474 5 8.91406 5C9.16927 5 9.38281 5.08594 9.55469 5.25781L15.6406 11.2109C15.7448 11.3151 15.8229 11.4245 15.875 11.5391C15.9271 11.6536 15.9531 11.7786 15.9531 11.9141Z"
      fill="#F8F8F8"
    />
  </svg>
);
export const IconX = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <mask
      id="mask0_4368_8027"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4368_8027)">
      <path
        d="M12.0008 13.4L7.10078 18.3C6.91745 18.4833 6.68411 18.575 6.40078 18.575C6.11745 18.575 5.88411 18.4833 5.70078 18.3C5.51745 18.1167 5.42578 17.8833 5.42578 17.6C5.42578 17.3167 5.51745 17.0833 5.70078 16.9L10.6008 12L5.70078 7.1C5.51745 6.91667 5.42578 6.68333 5.42578 6.4C5.42578 6.11667 5.51745 5.88333 5.70078 5.7C5.88411 5.51667 6.11745 5.425 6.40078 5.425C6.68411 5.425 6.91745 5.51667 7.10078 5.7L12.0008 10.6L16.9008 5.7C17.0841 5.51667 17.3174 5.425 17.6008 5.425C17.8841 5.425 18.1174 5.51667 18.3008 5.7C18.4841 5.88333 18.5758 6.11667 18.5758 6.4C18.5758 6.68333 18.4841 6.91667 18.3008 7.1L13.4008 12L18.3008 16.9C18.4841 17.0833 18.5758 17.3167 18.5758 17.6C18.5758 17.8833 18.4841 18.1167 18.3008 18.3C18.1174 18.4833 17.8841 18.575 17.6008 18.575C17.3174 18.575 17.0841 18.4833 16.9008 18.3L12.0008 13.4Z"
        fill="#F8F8F8"
      />
    </g>
  </svg>
);
