export const TERM_SECOND = 1000;
export const TERM_MINUTE = TERM_SECOND * 60;
export const TERM_HOUR = TERM_MINUTE * 60;

export const SYNC_TERM_AVAILABLE_HANDLERS = TERM_HOUR;
export const SYNC_TERM_AVAILABLE_CHAINS = TERM_HOUR;
export const SYNC_TERM_AVAILABLE_PAIRS = TERM_MINUTE * 10;
export const SYNC_TERM_BTCFI_MARKET = TERM_MINUTE;
export const SYNC_TERM_BTCFI_AVAILABLES = TERM_MINUTE * 10;
export const SYNC_TERM_BIFI_AVAILABLES = TERM_MINUTE * 10;
