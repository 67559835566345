// explorer url
export const TESTNET_CHAIN_EXPLORER_URL_BIFROST =
  'https://explorer.testnet.bifrostnetwork.com/';
export const TESTNET_CHAIN_EXPLORER_URL_ETHEREUM =
  'https://sepolia.etherscan.io/';
export const TESTNET_CHAIN_EXPLORER_URL_ARBITRUM =
  'https://sepolia.arbiscan.io/';
export const TESTNET_CHAIN_EXPLORER_URL_POLYGON =
  'https://mumbai.polygonscan.com/';
export const TESTNET_CHAIN_EXPLORER_URL_BSC = 'https://testnet.bscscan.com/';
export const TESTNET_CHAIN_EXPLORER_URL_BASE = 'https://goerli.basescan.org/';

export const TESTNET_CHAIN_EXPLORER_URLS = [
  TESTNET_CHAIN_EXPLORER_URL_BIFROST,
  TESTNET_CHAIN_EXPLORER_URL_ETHEREUM,
  TESTNET_CHAIN_EXPLORER_URL_ARBITRUM,
  TESTNET_CHAIN_EXPLORER_URL_POLYGON,
  TESTNET_CHAIN_EXPLORER_URL_BSC,
  TESTNET_CHAIN_EXPLORER_URL_BASE,
];
