import {
  Token,
  address2shorted,
  isValidAddress,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo, SyntheticEvent } from 'react';
import styled from 'styled-components';
import IconAdd from '@/assets/icons/icon-add.svg';
import IconArrow from '@/assets/icons/icon-arrow.svg';
import IconExternal from '@/assets/icons/icon-external.svg';
import useEnv from '@/hooks/useEnv';
import TokenIcon from '../TokenIcon';
import { StyledText } from '../atom/Text';

interface Props {
  isAddable?: boolean;
  token: Token;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Root = styled.div<{ isAddable?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 8px 12px;
  border-radius: 24px;
  background-color: ${({ isAddable, theme }) =>
    isAddable ? hexToRgba(theme.colors.white, 0.08) : 'transparent'};
  cursor: pointer;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const TokenAddress = styled.div``;
const ChainName = styled.div``;
const Link = styled.a`
  display: block;
  position: relative;
  min-width: 110px;
  height: 18px;

  & ${TokenAddress}, & ${ChainName} {
    display: flex;
    align-items: center;
    gap: 6px;
    ${({ theme }) => theme.transition.default};
  }

  & ${TokenAddress} {
    position: absolute;
    opacity: 0;
  }

  &:hover ${TokenAddress} {
    opacity: 1;
  }
  &:hover ${ChainName} {
    opacity: 0;
  }
`;
const IconRotatedArrow = styled(IconArrow)`
  transform: rotate(-90deg);
`;
const Tag = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  padding: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.black};
`;
const IconScaledAdd = styled(IconAdd)`
  transform: scale(0.7);
  color: ${({ theme }) => theme.colors.green};
  fill: ${({ theme }) => theme.colors.green};
`;

export default function AddTokenItem({
  isAddable,
  token,
  onClick: handleClick,
}: Props) {
  const { t } = useTranslation();

  const { chains, getExplorerUrl } = useEnv();

  const explorerUrl = useMemo(() => {
    const urlOrigin = getExplorerUrl(token.chainId)?.replace(/\/$/, '');

    return urlOrigin && token && isValidAddress(token.address)
      ? `${urlOrigin}/token/${token.address}`
      : undefined;
  }, [getExplorerUrl, token]);

  const handleLinkClick = useCallback(
    (event: SyntheticEvent) => event.stopPropagation(),
    []
  );

  return (
    <Root isAddable={isAddable} onClick={handleClick}>
      <ContentContainer>
        <TokenIcon
          chainBorderColor="#505e92"
          size={32}
          isHideChain={!isAddable}
          {...token}
        />
        <div>
          <StyledText $fontSize="lg" $fontWeight="bold">
            {token.symbol}
          </StyledText>
          {isAddable ? (
            <Link
              target="_blank"
              rel="noreferrer"
              href={explorerUrl}
              onClick={handleLinkClick}
            >
              <StyledText $fontSize="xs">
                <TokenAddress>
                  {address2shorted(token.address)}
                  <IconExternal />
                </TokenAddress>
                <ChainName>
                  {chains.find((chain) => chain.id === token.chainId)?.name}
                </ChainName>
              </StyledText>
            </Link>
          ) : (
            ''
          )}
        </div>
      </ContentContainer>
      <div>
        {isAddable ? (
          <Tag>
            <StyledText $fontSize="xs" $textColor="green" $fontWeight="medium">
              {t('add')}
            </StyledText>
            <IconScaledAdd />
          </Tag>
        ) : (
          <IconRotatedArrow />
        )}
      </div>
    </Root>
  );
}
