import { atom } from 'jotai';

export const everdexLpRewardReservesAtom = atom<string[]>([]);
export const everdexLpRewardRewardRateAtom = atom<string | undefined>(
  undefined
);
export const everdexLpRewardTotalSupplyAtom = atom<string | undefined>(
  undefined
);
export const everdexLpTotalSupplyAtom = atom<string | undefined>(undefined);
export const everdexLpRewardDepositedAmountAtom = atom<string | undefined>(
  undefined
);
export const everdexLpRewardAmountAtom = atom<string | undefined>(undefined);
export const everdexLpRewardFinishPeriodAtom = atom<number | undefined>(
  undefined
);

export const everdexIsLoadingLpRewardReservesAtom = atom(false);
export const everdexIsLoadingLpRewardRewardRateAtom = atom(false);
export const everdexIsLoadingLpRewardTotalSupplyAtom = atom(false);
export const everdexIsLoadingLpTotalSupplyAtom = atom(false);
export const everdexIsLoadingLpRewardDepositedAmountAtom = atom(false);
export const everdexIsLoadingLpRewardAmountAtom = atom(false);
export const everdexIsLoadingLpRewardFinishPeriodAtom = atom(false);
