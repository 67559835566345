import {
  formatUnit,
  isValidAddress,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { FRAG_REWARD_POOL_GET_USER_REWARD_AMOUNT } from '@/configs/fragment';
import { formatNumber } from '@/libs/formatNumber';
import { bifiRewardAmountAtom } from '@/store/bifiStore';
import useEnv from '../../useEnv';
import useIsBifrostChain from '../../useIsBifrostChain';

const useBifiRewardAmount = () => {
  // env
  const { chainBifrost, addressBifiRewardPool } = useEnv();

  // wallet
  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  // store
  const [rewardAmount, setRewardAmount] = useAtom(bifiRewardAmountAtom);

  // callback
  const sync = useCallback(async () => {
    if (!(account && isValidAddress(account))) {
      return;
    }

    try {
      const to = addressBifiRewardPool;
      const fragment = FRAG_REWARD_POOL_GET_USER_REWARD_AMOUNT;
      const values = [account];
      const from = account;
      const defaultBlock = 'latest';

      let rewardAmount = '0';

      if (isBifrostChain) {
        rewardAmount = pickNumberResponse(
          await wallet.call(to, fragment, values, from, defaultBlock)
        );
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        rewardAmount = pickNumberResponse(
          await rpcContractCall(callParams, rpcURL, defaultBlock)
        );
      }

      setRewardAmount(formatNumber(formatUnit(rewardAmount, 18)));
    } catch (error) {}
  }, [
    account,
    addressBifiRewardPool,
    chainBifrost.rpcUrls,
    isBifrostChain,
    setRewardAmount,
    wallet,
  ]);

  // effect
  useEffect(() => {
    if (!account) {
      setRewardAmount('0');
    }
  }, [account, setRewardAmount]);

  return {
    amount: rewardAmount,
    sync,
  };
};

export default useBifiRewardAmount;
