import {
  UnknownNumber,
  formatUnit,
  isValidAddress,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FRAG_LP_REWARD_GET_RESERVES } from '@/configs/fragment';
import useEnv from '@/hooks/useEnv';
import useIsBifrostChain from '@/hooks/useIsBifrostChain';
import { formatNumber } from '@/libs/formatNumber';
import {
  everdexLpRewardReservesAtom,
  everdexIsLoadingLpRewardReservesAtom,
} from '@/store/everdexStore';

const useEverdexLpRewardReserves = (
  swapMergerAddress?: string,
  poolAddress?: string
) => {
  const { chainBifrost, everdexLpRewardPool } = useEnv();

  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  const [reserves, setReserves] = useAtom(everdexLpRewardReservesAtom);
  const [isLoading, setIsLoading] = useAtom(
    everdexIsLoadingLpRewardReservesAtom
  );

  const sync = useCallback(async () => {
    if (
      !(
        poolAddress &&
        swapMergerAddress &&
        isValidAddress(poolAddress) &&
        isValidAddress(swapMergerAddress) &&
        everdexLpRewardPool?.tokens?.length &&
        everdexLpRewardPool.tokens.reduce(
          (pre, token) => pre && isValidAddress(token.address),
          true
        )
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const to = swapMergerAddress;
      const fragment = FRAG_LP_REWARD_GET_RESERVES;
      const values: ReadonlyArray<any> = [poolAddress];
      const from = account;
      const defaultBlock = 'latest';

      let reserves = [];

      if (isBifrostChain) {
        reserves = (
          (await wallet.call(to, fragment, values, from, defaultBlock)) as [
            string[],
            UnknownNumber[],
          ]
        )[1].map((data, dataIndex) =>
          formatNumber(
            formatUnit(
              pickNumberResponse(data),
              everdexLpRewardPool.tokens[dataIndex].decimals ?? 18
            )
          )
        );
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        reserves = (
          await rpcContractCall<[string[], UnknownNumber[]]>(
            callParams,
            rpcURL,
            defaultBlock
          )
        )[1].map((data, dataIndex) =>
          formatNumber(
            formatUnit(
              pickNumberResponse(data),
              everdexLpRewardPool.tokens[dataIndex].decimals ?? 18
            )
          )
        );
      }

      setReserves(reserves);
    } catch (error) {}

    setIsLoading(false);
  }, [
    poolAddress,
    swapMergerAddress,
    everdexLpRewardPool.tokens,
    setIsLoading,
    account,
    isBifrostChain,
    setReserves,
    wallet,
    chainBifrost.rpcUrls,
  ]);

  return { reserves, isLoading, sync };
};

export default useEverdexLpRewardReserves;
