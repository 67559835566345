import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import IconBifrost from '@/assets/icons/icon-bifrost.svg';
import { LINK_DOCS_EN, LINK_DOCS_KO, LINK_HELP_CENTER } from '@/configs/link';
import {
  MEDIA_QUERY_WIDTH_DESKTOP_S,
  MEDIA_QUERY_WIDTH_MOBILE_M,
} from '@/configs/mediaQuery';
import { PATH_TERMS } from '@/configs/path';
import useEnv from '@/hooks/useEnv';
import {
  eventDocsFooterClick,
  eventHelpCenterFooterClick,
  eventPolicyFooterClick,
} from '@/libs/gtag';
import { FlexBox } from './atom/FlexBox';
import { StyledText } from './atom/Text';

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  width: 100%;
  height: 88px;
  color: ${(p) => p.theme.colors.white};
  margin: 0 auto;
  padding: 0 48px;

  color: ${(p) => p.theme.colors.white};
  text-align: center;
  font-size: ${(p) => p.theme.size.md};

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    padding: 24px !important;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${(p) => p.theme.size.xxxl};
    height: auto;
    padding: ${(p) => p.theme.size.xl} 48px;
    font-size: ${(p) => p.theme.size.xs};
  }
`;

const InnerWrapper = styled(FlexBox)`
  width: 100%;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    flex-direction: row-reverse;

    & > span {
      flex-shrink: 0;
      align-self: flex-end;
    }
    & > div {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: ${(p) => p.theme.size.md};
    }
  }
`;

const BifrostLogo = styled(FlexBox)`
  display: none;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    display: flex;
  }
`;

export default function Footer() {
  const { i18n } = useTranslation();

  const { isTestnet } = useEnv();

  const docsLink = useMemo(
    () => (i18n.language?.startsWith('ko') ? LINK_DOCS_KO : LINK_DOCS_EN),
    [i18n]
  );

  const handleDocsClick = useCallback(
    () => eventDocsFooterClick(isTestnet),
    [isTestnet]
  );
  const handleHelpCenterClick = useCallback(
    () => eventHelpCenterFooterClick(isTestnet),
    [isTestnet]
  );
  const handlePolicyClick = useCallback(
    () => eventPolicyFooterClick(isTestnet),
    [isTestnet]
  );

  return (
    <Wrapper>
      <BifrostLogo $flex $gap="xxxs" $align="center">
        <IconBifrost />
        <StyledText $fontSize="lg" $fontWeight="black">
          Bifrost
        </StyledText>
      </BifrostLogo>
      <InnerWrapper $flex $justify="space-between">
        <span>&copy; 2024 Bifrosoft PTE LTD</span>
        <FlexBox $flex $justify="space-between" $align="center" $gap="xxl">
          <a target="_blank" href={docsLink} onClick={handleDocsClick}>
            Docs
          </a>
          <a
            target="_blank"
            href={LINK_HELP_CENTER}
            onClick={handleHelpCenterClick}
          >
            Help Center
          </a>
          <Link href={PATH_TERMS} onClick={handlePolicyClick}>
            Privacy Policy & Terms of Use
          </Link>
        </FlexBox>
      </InnerWrapper>
    </Wrapper>
  );
}
