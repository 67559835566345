import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import IconBtcfi from '@/assets/icons/icon-btcfi.svg';
import IconSetting from '@/assets/icons/icon-setting.svg';
import Footer from '@/components/Footer';
import HeaderMenuPanel from '@/components/GlobalSetting/HeaderMenuPanel';
import PanelSettingDetailDialog from '@/components/GlobalSetting/PanelSettingDetailDialog';
import { IconX } from '@/components/Icon';
import Button from '@/components/atom/Button';
import { MEDIA_QUERY_WIDTH_DESKTOP_MOBILE } from '@/configs/mediaQuery';
import { PATH_DASHBOARD, PATH_HOME } from '@/configs/path';
import useEnv from '@/hooks/useEnv';
import useScrollLock from '@/hooks/useScrollLock';
import useVerticalScrollDetector from '@/hooks/useVerticalScrollDetector';
import { CurrencyId } from '@/types/Currency';
import { LanguageId } from '@/types/Language';
import GlobalSetting, { SettingMenu } from './GlobalSetting';
import { FlexBox } from './atom/FlexBox';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
`;

const HeaderWrapper = styled.header<{
  $isScrolled?: boolean;
  $isExpand?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  ${(p) =>
    p.$isScrolled &&
    !p.$isExpand &&
    `
    backdrop-filter: blur(4px);
    background: #0c0c2066;
    border-bottom: 1px solid #818da666;
  `};
`;

const Header = styled(FlexBox)`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-width: 1920px;
  height: 88px;
  margin: 0 auto;
  padding: 0 48px;
  background-color: transparent;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    padding: 0 16px;
  }

  & > h1 {
    font-size: ${(p) => p.theme.size.xl};
    ${(p) => p.theme.font.bold}
  }

  & > aside {
    display: flex;
    align-items: center;
    gap: ${(p) => p.theme.size.xl};
    margin-left: auto;
  }
  z-index: 9999;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: ${(p) => p.theme.size.xl};
    font-style: italic;
    ${(p) => p.theme.font.black};
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 100) - 88px);
  top: 88px;
  left: 0;
  padding: 40px 16px 20px 16px;
  z-index: 9999;
`;

const SettingButton = styled.button`
  width: 24px;
  height: 24px;
`;

const SubPanel = styled(MenuContainer)`
  top: 0;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(2, 2, 28, 0.4);
  backdrop-filter: blur(45px);
`;

const BlurBG = styled.div<{ $isExpand: boolean }>`
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(2, 2, 28, 0.4);
  backdrop-filter: blur(45px) !important;
  z-index: -1;

  ${(p) =>
    p.$isExpand &&
    `
  display: block;
  `};
`;

interface Props {
  children: ReactNode;
}

export default function LayoutWithoutSidebar({ children }: Props) {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { isScrolled } = useVerticalScrollDetector();
  const [subPanel, setSubPanel] = useState('');
  const [isFullSizeMenuOpen, setIsFullSizeMenuOpen] = useState(false);

  useScrollLock({ isEnable: !isFullSizeMenuOpen });
  const { changeLanguage, changeCurrency, currencyId } = useEnv();

  const isMobile = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE})`,
  });

  const handleStartButton = useCallback(() => {
    router.push(PATH_DASHBOARD);
  }, [router]);

  const handleToggleChild = useCallback(() => {
    setIsFullSizeMenuOpen((prev) => !prev);
  }, []);

  const handleLanguageClick = useCallback(
    (language: LanguageId) => () => {
      changeLanguage(language);
      setSubPanel('');
    },
    [changeLanguage]
  );

  const handleCurrencyClick = useCallback(
    (currency: CurrencyId) => () => {
      changeCurrency(currency);
      setSubPanel('');
    },
    [changeCurrency]
  );

  const handleMenuClick = useCallback(
    (menu: SettingMenu) => () => {
      setSubPanel(menu);
    },
    []
  );

  useEffect(() => {
    if (!isMobile) {
      setIsFullSizeMenuOpen(false);
    }
  }, [isMobile, setIsFullSizeMenuOpen]);

  return (
    <>
      <Head>
        <title>{t('common:metadata:title')}</title>
        <meta name="keywords" content={t('common:metadata:keywords')} />
        <meta name="description" content={t('common:metadata:description')} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={t('common:metadata:openGraph:title')}
        />
        <meta
          property="og:image"
          content={t('common:metadata:openGraph:image')}
        />
        <meta
          property="og:description"
          content={t('common:metadata:openGraph:description')}
        />
        <meta property="og:site_name" content="BTCFi" />
        <meta property="og:locale" content={i18n.language} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>
      <StyledLayout>
        <HeaderWrapper $isScrolled={isScrolled} $isExpand={isFullSizeMenuOpen}>
          <BlurBG $isExpand={isFullSizeMenuOpen} />
          <Header>
            <Link href={PATH_HOME}>
              <Logo>
                <IconBtcfi />
                <span>BTCFi</span>
              </Logo>
            </Link>
            <aside>
              {!isFullSizeMenuOpen && (
                <Button
                  $variant="primary"
                  $padding="xs"
                  onClick={handleStartButton}
                  style={{ borderRadius: '40px', border: 'none' }}
                >
                  {t('landing:getStarted')}
                </Button>
              )}
              {isMobile ? (
                <SettingButton onClick={handleToggleChild}>
                  {isFullSizeMenuOpen ? <IconX /> : <IconSetting />}
                </SettingButton>
              ) : (
                <GlobalSetting />
              )}
            </aside>
          </Header>
          {isFullSizeMenuOpen && isMobile && (
            <>
              {subPanel ? (
                <SubPanel>
                  <PanelSettingDetailDialog
                    isShow={subPanel === 'language'}
                    title={t('common:language')}
                    type={subPanel}
                    menus={[
                      {
                        label: '한국어',
                        onClick: handleLanguageClick('ko'),
                      },
                      {
                        label: 'English',
                        onClick: handleLanguageClick('en'),
                      },
                    ]}
                    onBackClick={handleMenuClick('')}
                  />
                  <PanelSettingDetailDialog
                    isShow={subPanel === 'currency'}
                    title={t('common:baseUnit')}
                    type={subPanel}
                    menus={[
                      {
                        label: 'KRW',
                        onClick: handleCurrencyClick('krw'),
                      },
                      {
                        label: 'USD',
                        onClick: handleCurrencyClick('usd'),
                      },
                    ]}
                    onBackClick={handleMenuClick('')}
                  />
                </SubPanel>
              ) : (
                <MenuContainer>
                  <HeaderMenuPanel
                    subPanel={setSubPanel}
                    currency={currencyId}
                    handleModal={setIsFullSizeMenuOpen}
                  />
                </MenuContainer>
              )}
            </>
          )}
        </HeaderWrapper>
        {children}
        <Footer />
      </StyledLayout>
    </>
  );
}
