const baseTheme = {
  colors: {
    point: '#3861D1',
    green: '#14F28A',
    orange: '#FF5D18',
    white: '#FFFFFF',
    textWhite: '#F8F8F8',
    buttonPoint: '#4068DF',
    gray: '#818DA6',
    textGray: '#BBBBBB',
    textDarkGray: '#888',
    grayBlue: '#3E406499',
    black: '#0C0C20',
    yellow: '#FFA800',
  },
  size: {
    zero: '0px',
    xxxs: '4px',
    xxs: '8px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '28px',
    xxxl: '32px',
  },
} as const;

export const defaultTheme = {
  ...baseTheme,
  text: {
    ...(Object.fromEntries(
      Object.entries(baseTheme.size).map(([key, value]) => [
        key,
        `font-size: ${value};`,
      ])
    ) as {
      [key in keyof typeof baseTheme.size]: `font-size: ${(typeof baseTheme.size)[key]};`;
    }),
  },
  font: {
    regular: `font-weight: 400;`,
    medium: `font-weight: 500;`,
    bold: `font-weight: 700;`,
    black: `font-weight: 900;`,
  },
  border: {
    gray: `border: 1px solid ${baseTheme.colors.gray}66;`,
  },
  opacity: {
    0: 'opacity: 0;',
    10: 'opacity: 0.1;',
    20: 'opacity: 0.2;',
    30: 'opacity: 0.3;',
    40: 'opacity: 0.4;',
    50: 'opacity: 0.5;',
    60: 'opacity: 0.6;',
    70: 'opacity: 0.7;',
    80: 'opacity: 0.8;',
    90: 'opacity: 0.9;',
    100: 'opacity: 1;',
  },
  width: {
    ...(Object.fromEntries(
      Object.entries(baseTheme.size).map(([key, value]) => [
        key,
        `width: ${value};`,
      ])
    ) as {
      [key in keyof typeof baseTheme.size]: `width: ${(typeof baseTheme.size)[key]};`;
    }),
  },
  height: {
    ...(Object.fromEntries(
      Object.entries(baseTheme.size).map(([key, value]) => [
        key,
        `height: ${value};`,
      ])
    ) as {
      [key in keyof typeof baseTheme.size]: `height: ${(typeof baseTheme.size)[key]};`;
    }),
  },
  rounded: {
    full: 'border-radius: 9999px;',
    ...(Object.fromEntries(
      Object.entries(baseTheme.size).map(([key, value]) => [
        key,
        `border-radius: ${value};`,
      ])
    ) as {
      [key in keyof typeof baseTheme.size]: `border-radius: ${(typeof baseTheme.size)[key]};`;
    }),
  },
  gap: {
    ...(Object.fromEntries(
      Object.entries(baseTheme.size).map(([key, value]) => [
        key,
        `gap: ${value};`,
      ])
    ) as {
      [key in keyof typeof baseTheme.size]: `gap: ${(typeof baseTheme.size)[key]};`;
    }),
  },
  bg: {
    ...(Object.fromEntries(
      Object.entries(baseTheme.colors).map(([key, value]) => [
        key,
        `background-color: ${value};`,
      ])
    ) as {
      [key in keyof typeof baseTheme.colors]: `background-color: ${(typeof baseTheme.colors)[key]};`;
    }),
  },
  transition: {
    default: `
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0, 0.49, 0.46, 1.01);
    `,
    all: `
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0, 0.49, 0.46, 1.01);
    `,
  },
  ally: {
    srOnly: `
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    `,
  },
} as const;

export type Theme = typeof defaultTheme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
