import getWindowLocation from '@/libs/getWindowLocation';

export const HOST = getWindowLocation()?.host ?? '';
export const IS_DEV_ENVIRONMENT =
  process.env.NODE_ENV === 'test' ||
  process.env.NODE_ENV === 'development' ||
  process.env.NEXT_PUBLIC_ENV === 'development';
export const IS_DEV_HOST =
  HOST.includes('develop') ||
  HOST.toLowerCase().includes('thebifrost.dev') ||
  HOST.includes('vercel') ||
  HOST.includes('localhost') ||
  HOST.includes('127.0.0.1');

const IS_DEV = IS_DEV_ENVIRONMENT || IS_DEV_HOST;

export default IS_DEV;
