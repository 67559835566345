import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { IS_ONLY_TESTNET_ALLOWED } from '@/configs/devEnv/testnetLaunch';
import { isTestnetAtom } from '@/store/uiStore';
import EnvNetworkTitle from '@/types/env/EnvNetworkTitle';
import IsTestnet from '@/types/env/IsTestnet';
import { getStorageItem, setStorageItem } from '@/utils/storage';

const STORAGE_ID_IS_TESTNET = 'isTestnet';

const useIsTestnet = () => {
  const router = useRouter();
  const { pathname, query, locale } = router;

  const [isTestnetSelected, setIsTestnet] = useAtom(isTestnetAtom);

  const isTestnet = useMemo(
    () => IS_ONLY_TESTNET_ALLOWED || isTestnetSelected,
    [isTestnetSelected]
  );
  const networkTitle: EnvNetworkTitle = useMemo(
    () => (isTestnet ? 'Testnet' : 'Mainnet'),
    [isTestnet]
  );
  const networkApiPath: string = useMemo(
    () => (isTestnet ? 'testnet' : 'mainnet'),
    [isTestnet]
  );

  const changeIsTestnet = useCallback(
    (newIsTestnet: IsTestnet) => {
      setStorageItem(STORAGE_ID_IS_TESTNET, newIsTestnet);
      setIsTestnet(newIsTestnet);
    },
    [setIsTestnet]
  );
  const getStorageIsTestnet = useCallback(
    () => getStorageItem<IsTestnet>(STORAGE_ID_IS_TESTNET) || false,
    []
  );
  const getQueryIsTestnet = useCallback(
    () =>
      (typeof query.isTestnet === 'string'
        ? query.isTestnet
        : query.isTestnet?.[0]) === 'true',
    [query.isTestnet]
  );
  const sync = useCallback(async () => {
    const storageIsTestnet = getStorageIsTestnet();
    const queryIsTestnet = getQueryIsTestnet();
    const isTestnet = queryIsTestnet || storageIsTestnet;

    setIsTestnet(isTestnet);

    if (queryIsTestnet) {
      await router.replace(
        { pathname, query: { ...query, isTestnet: undefined } },
        pathname,
        { locale }
      );
    }
  }, [
    getQueryIsTestnet,
    getStorageIsTestnet,
    locale,
    pathname,
    query,
    router,
    setIsTestnet,
  ]);

  return { isTestnet, networkTitle, networkApiPath, changeIsTestnet, sync };
};

export default useIsTestnet;
