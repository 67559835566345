import React from 'react';

export default function IconBtcfi({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0296 20.3356C24.9544 15.568 25.3517 7.97123 20.9169 3.36762C16.4822 -1.23599 8.8948 -1.1031 3.97001 3.66443C-0.954788 8.43196 -1.35206 16.0288 3.08267 20.6324C7.51741 25.236 15.1048 25.1031 20.0296 20.3356Z"
        fill="#4068DF"
        fillOpacity="0.6"
      />
      <path
        d="M3.14686 14.9283C3.12192 14.9143 3.09798 14.8993 3.07404 14.8843C3.09798 14.8993 3.12292 14.9133 3.14686 14.9283Z"
        fill="#3861D1"
      />
      <path
        d="M11.275 22.4002C11.3159 22.4002 11.3568 22.3992 11.3977 22.3982C11.1004 22.3952 10.8131 22.3862 10.5328 22.3732C10.7772 22.3912 11.0246 22.4002 11.276 22.4002H11.275Z"
        fill="#3861D1"
      />
      <path
        d="M5.19372 8.65832C7.03515 8.65832 8.42371 10.1553 8.29503 12.0013C8.17034 13.7882 6.67005 15.2482 4.90343 15.3392C6.34087 15.2812 7.60574 14.5132 8.80975 13.4642C8.45563 13.0933 8.25513 12.5753 8.29603 12.0013C8.37483 10.8733 9.35041 9.9583 10.4766 9.9583C10.9943 9.9583 11.4532 10.1523 11.7904 10.4713C12.754 9.47731 13.7385 8.53733 14.8059 7.88434C15.5899 7.36235 16.5087 7.03935 17.4803 6.99335C17.5601 6.98935 17.6409 6.98735 17.7227 6.98735C22.2365 6.98735 22.417 10.7863 22.3881 11.7703C22.6584 6.13337 18.379 1.60144 12.726 1.60144C7.07306 1.60144 2.02756 6.25736 1.62656 12.0013C1.75524 10.1553 3.35228 8.65832 5.19372 8.65832Z"
        fill="#3861D1"
      />
      <path
        d="M1.62003 12.1572C1.62103 12.1072 1.62302 12.0572 1.62702 12.0062C1.62402 12.0562 1.62103 12.1072 1.62003 12.1572Z"
        fill="#3861D1"
      />
      <path
        d="M2.97531 14.8213C2.97531 14.8213 2.97131 14.8193 2.97031 14.8173C2.97231 14.8183 2.97431 14.8193 2.97531 14.8213Z"
        fill="#3861D1"
      />
      <path
        d="M4.38686 15.3262C3.94894 15.2812 3.54295 15.1492 3.18384 14.9492C3.53596 15.1482 3.93897 15.2812 4.38686 15.3262Z"
        fill="#3861D1"
      />
      <path
        d="M4.55353 15.3392C4.50266 15.3362 4.45278 15.3322 4.4039 15.3282C4.45378 15.3332 4.50365 15.3372 4.55353 15.3392Z"
        fill="#3861D1"
      />
      <path
        d="M14.8062 7.88439C15.6382 7.3754 16.52 7.04141 17.4806 6.99341C16.509 7.03941 15.5903 7.3624 14.8062 7.88439Z"
        fill="#3861D1"
      />
      <path
        opacity="0.2"
        d="M14.8062 7.88439C15.6382 7.3754 16.52 7.04141 17.4806 6.99341C16.509 7.03941 15.5903 7.3624 14.8062 7.88439Z"
        fill="#3861D1"
      />
      <path
        d="M8.2952 12.0003C8.42388 10.1543 7.03532 8.65735 5.19389 8.65735C3.35245 8.65735 1.75541 10.1543 1.62673 12.0003C1.62673 12.0023 1.62673 12.0043 1.62673 12.0063C1.62374 12.0573 1.62075 12.1073 1.61975 12.1573C1.5998 13.2003 2.09856 14.2223 2.9704 14.8172C2.97239 14.8182 2.97439 14.8192 2.97539 14.8212C3.00731 14.8432 3.04122 14.8642 3.07414 14.8852C3.09808 14.9002 3.12202 14.9152 3.14696 14.9292C3.15893 14.9362 3.1709 14.9432 3.18387 14.9502C3.54298 15.1502 3.94897 15.2822 4.38689 15.3272C4.39187 15.3272 4.39786 15.3272 4.40285 15.3282C4.45272 15.3332 4.5026 15.3372 4.55248 15.3392C4.61033 15.3422 4.66819 15.3442 4.72704 15.3442C4.7859 15.3442 4.84475 15.3422 4.90261 15.3392C6.66923 15.2472 8.16951 13.7883 8.2942 12.0013L8.2952 12.0003Z"
        fill="#3861D1"
      />
      <path
        d="M22.3874 11.7693C22.3834 11.8463 22.3794 11.9233 22.3744 12.0003C22.3744 12.0003 22.3834 11.9153 22.3874 11.7693Z"
        fill="#3861D1"
      />
      <path
        d="M17.723 6.98633C20.4852 6.98633 22.568 9.23129 22.3745 12.0002C22.3795 11.9232 22.3845 11.8462 22.3875 11.7692C22.4164 10.7853 22.2358 6.98633 17.722 6.98633H17.723Z"
        fill="#3861D1"
      />
      <path
        d="M17.723 6.98633C20.4852 6.98633 22.568 9.23129 22.3745 12.0002C22.3795 11.9232 22.3845 11.8462 22.3875 11.7692C22.4164 10.7853 22.2358 6.98633 17.722 6.98633H17.723Z"
        fill="#3861D1"
      />
      <path
        d="M17.4803 6.99233C17.5611 6.98833 17.6419 6.98633 17.7227 6.98633C17.6409 6.98633 17.5601 6.98833 17.4803 6.99233Z"
        fill="#3861D1"
      />
      <path
        d="M17.4803 6.99233C17.5611 6.98833 17.6419 6.98633 17.7227 6.98633C17.6409 6.98633 17.5601 6.98833 17.4803 6.99233Z"
        fill="#3861D1"
      />
      <path
        d="M4.90368 15.3392C4.84483 15.3412 4.78697 15.3442 4.72812 15.3442C4.78697 15.3442 4.84583 15.3422 4.90368 15.3392Z"
        fill="#3861D1"
      />
      <path
        opacity="0.2"
        d="M4.90368 15.3392C4.84483 15.3412 4.78697 15.3442 4.72812 15.3442C4.78697 15.3442 4.84583 15.3422 4.90368 15.3392Z"
        fill="#3861D1"
      />
      <path
        d="M4.90368 15.3392C4.84483 15.3412 4.78697 15.3442 4.72812 15.3442C4.78697 15.3442 4.84583 15.3422 4.90368 15.3392Z"
        fill="#3861D1"
      />
      <path
        d="M4.40381 15.3272C4.39881 15.3272 4.39282 15.3272 4.38782 15.3262C4.39282 15.3262 4.39881 15.3262 4.40381 15.3272Z"
        fill="#3861D1"
      />
      <path
        opacity="0.2"
        d="M4.40381 15.3272C4.39881 15.3272 4.39282 15.3272 4.38782 15.3262C4.39282 15.3262 4.39881 15.3262 4.40381 15.3272Z"
        fill="#3861D1"
      />
      <path
        d="M4.40381 15.3272C4.39881 15.3272 4.39282 15.3272 4.38782 15.3262C4.39282 15.3262 4.39881 15.3262 4.40381 15.3272Z"
        fill="#3861D1"
      />
      <path
        d="M3.18394 14.9492C3.17194 14.9422 3.15994 14.9352 3.14694 14.9282C3.15894 14.9352 3.17094 14.9422 3.18394 14.9492Z"
        fill="#3861D1"
      />
      <path
        opacity="0.2"
        d="M3.18394 14.9492C3.17194 14.9422 3.15994 14.9352 3.14694 14.9282C3.15894 14.9352 3.17094 14.9422 3.18394 14.9492Z"
        fill="#3861D1"
      />
      <path
        d="M3.18394 14.9492C3.17194 14.9422 3.15994 14.9352 3.14694 14.9282C3.15894 14.9352 3.17094 14.9422 3.18394 14.9492Z"
        fill="#3861D1"
      />
      <path
        d="M1.61965 12.1572C1.59372 13.2752 2.1254 14.2492 2.9703 14.8172C2.09846 14.2222 1.5997 13.2002 1.61965 12.1572Z"
        fill="#3861D1"
      />
      <path
        opacity="0.2"
        d="M1.61965 12.1572C1.59372 13.2752 2.1254 14.2492 2.9703 14.8172C2.09846 14.2222 1.5997 13.2002 1.61965 12.1572Z"
        fill="#3861D1"
      />
      <path
        d="M1.61965 12.1572C1.59372 13.2752 2.1254 14.2492 2.9703 14.8172C2.09846 14.2222 1.5997 13.2002 1.61965 12.1572Z"
        fill="#3861D1"
      />
      <path
        d="M3.07425 14.8842C3.04133 14.8632 3.00742 14.8422 2.97549 14.8202C3.00841 14.8422 3.04133 14.8632 3.07425 14.8842Z"
        fill="#3861D1"
      />
      <path
        opacity="0.2"
        d="M3.07425 14.8842C3.04133 14.8632 3.00742 14.8422 2.97549 14.8202C3.00841 14.8422 3.04133 14.8632 3.07425 14.8842Z"
        fill="#3861D1"
      />
      <path
        d="M3.07425 14.8842C3.04133 14.8632 3.00742 14.8422 2.97549 14.8202C3.00841 14.8422 3.04133 14.8632 3.07425 14.8842Z"
        fill="#3861D1"
      />
      <path
        d="M17.7228 6.98633C17.642 6.98633 17.5612 6.98833 17.4804 6.99233C16.5198 7.04033 15.638 7.37532 14.806 7.88331C13.7387 8.5363 12.7541 9.47629 11.7905 10.4703C11.4533 10.1513 10.9945 9.95728 10.4768 9.95728C9.35155 9.95728 8.37497 10.8723 8.29617 12.0002C8.25627 12.5742 8.45677 13.0922 8.80989 13.4632C7.60588 14.5122 6.34101 15.2802 4.90358 15.3382C6.6702 15.2462 8.17048 13.7872 8.29517 12.0002C8.41986 10.2133 7.03529 8.6573 5.19386 8.6573C3.35242 8.6573 1.75538 10.1543 1.6267 12.0002C1.6267 12.0022 1.6267 12.0042 1.6267 12.0062C1.62371 12.0572 1.62072 12.1072 1.61972 12.1572C1.59977 13.2002 2.09853 14.2222 2.97037 14.8172C2.97236 14.8182 2.97436 14.8192 2.97536 14.8212C3.00728 14.8432 3.0412 14.8642 3.07411 14.8852C3.09805 14.9002 3.122 14.9152 3.14693 14.9292C3.1589 14.9362 3.17087 14.9432 3.18384 14.9502C3.54295 15.1502 3.94895 15.2822 4.38686 15.3272C4.39185 15.3272 4.39783 15.3272 4.40282 15.3282C4.4527 15.3332 4.50257 15.3372 4.55245 15.3392C4.50158 15.3362 4.4517 15.3322 4.40282 15.3282C4.39783 15.3282 4.39185 15.3282 4.38686 15.3272C3.93897 15.2822 3.53497 15.1492 3.18384 14.9502C3.17187 14.9432 3.1599 14.9362 3.14693 14.9292C3.122 14.9152 3.09805 14.9002 3.07411 14.8852C3.0402 14.8642 3.00728 14.8432 2.97536 14.8212C2.97336 14.8202 2.97137 14.8192 2.97037 14.8172C2.12546 14.2492 1.59378 13.2752 1.61972 12.1572C1.61972 12.1072 1.62271 12.0562 1.6267 12.0062C1.6267 12.0042 1.6267 12.0022 1.6267 12.0002C1.24365 17.4932 5.1779 21.9901 10.5326 22.3731C10.8129 22.3871 11.1012 22.3951 11.3975 22.3981C17.0754 22.3321 21.9763 17.7022 22.3743 12.0002C22.5678 9.23129 20.485 6.98633 17.7228 6.98633Z"
        fill="#3861D1"
      />
      <path
        d="M5.19371 8.65835C6.23912 8.65835 7.1379 9.14134 7.69651 9.89533C7.72145 8.52435 6.80273 7.39937 5.51292 7.13737C6.51843 6.64938 7.2566 5.6434 7.3374 4.47942C7.37032 4.01142 7.29251 3.56843 7.12792 3.17444C5.47402 4.16642 4.07848 5.5744 3.1029 7.24737C2.95127 7.50737 2.81062 7.77336 2.67994 8.04536C2.27495 8.88934 1.9727 9.78833 1.79314 10.7263C1.71334 11.1443 1.65648 11.5693 1.62656 12.0013C1.65149 11.6513 1.72831 11.3133 1.85 10.9953C2.37071 9.64033 3.70141 8.65835 5.19371 8.65835Z"
        fill="#F8F8F8"
      />
      <path
        d="M14.8059 7.88434C13.7385 8.53733 12.754 9.47731 11.7904 10.4713C10.7958 11.4973 9.82424 12.5803 8.80975 13.4642C7.60574 14.5132 6.34087 15.2812 4.90343 15.3392C4.84458 15.3422 4.78672 15.3442 4.72787 15.3442C4.66901 15.3442 4.61116 15.3422 4.5533 15.3392C4.50243 15.3362 4.45255 15.3322 4.40367 15.3282C4.39868 15.3282 4.3927 15.3282 4.38771 15.3272C3.93982 15.2822 3.53582 15.1492 3.18469 14.9502C3.17272 14.9432 3.16075 14.9362 3.14779 14.9292C3.12285 14.9152 3.09891 14.9002 3.07497 14.8852C3.04105 14.8642 3.00813 14.8432 2.97621 14.8212C2.97422 14.8202 2.97222 14.8192 2.97122 14.8172C2.12632 14.2492 1.59464 13.2752 1.62057 12.1573C1.62057 12.1073 1.62356 12.0563 1.62755 12.0063C1.62755 12.0043 1.62755 12.0023 1.62755 12.0003C1.2445 17.4932 5.17875 21.9901 10.5335 22.3731C10.8138 22.3871 11.1021 22.3951 11.3983 22.3981C17.0753 22.3321 21.9771 17.7032 22.3751 12.0003C22.5686 9.23031 20.4858 6.98535 17.7237 6.98535C17.6429 6.98535 17.5621 6.98735 17.4813 6.99135C16.5206 7.03935 15.6388 7.37435 14.8069 7.88234"
        fill="#F8F8F8"
      />
      <path
        d="M12.2353 1.95846C17.7666 1.95846 22.055 5.94139 22.3702 11.0913C22.3941 11.3723 22.3951 11.6083 22.3911 11.7693C22.6615 6.13239 18.3821 1.60046 12.7291 1.60046C10.8318 1.60046 9.01927 2.11146 7.42722 3.00244C8.90455 2.33345 10.5375 1.95846 12.2353 1.95846Z"
        fill="#F8F8F8"
      />
    </svg>
  );
}
