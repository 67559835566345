import {
  BN,
  UnknownNumber,
  formatCommas,
  formatDecimal,
  isNormalPositive,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { CurrencyId } from '@/types/env/Currency';

export const formatNumber = (value?: UnknownNumber) =>
  formatDecimal(value ?? 0, 36);

export const formatAmount = (value?: UnknownNumber) => {
  let result = formatCommas(value ?? 0, 2);

  if (new BN(value ?? 0).lt('0.01')) {
    if (isNormalPositive(value, true)) {
      result = '< 0.01';
    } else {
      result = '0.00';
    }
  }

  return result;
};

export const formatAmountInComplete = (value?: UnknownNumber) => {
  let result = formatCommas(value ?? 0, 4);

  if (new BN(value ?? 0).lt('0.0001')) {
    if (isNormalPositive(value, true)) {
      result = '< 0.0001';
    } else {
      result = '0.0000';
    }
  }

  return result;
};

export const formatAmountOnHover = (value?: UnknownNumber) => {
  let result = formatCommas(value ?? 0, 8);

  if (new BN(value ?? 0).lt('0.00000001')) {
    if (isNormalPositive(value, true)) {
      result = '< 0.00000001';
    } else {
      result = '0.00000000';
    }
  }

  return result;
};

export const formatAmountDetail = (value?: UnknownNumber) => {
  let result = formatCommas(value ?? 0, 36);

  if (!isNormalPositive(value, true)) {
    result = '0';
  }

  return result;
};

export const formatAmountValue = (
  value?: UnknownNumber,
  currencyId?: string
) => {
  let result = formatCommas(value ?? 0, currencyId === 'usd' ? 2 : 0);

  if (currencyId === 'usd') {
    if (!isNormalPositive(value, true)) {
      result = '0.00';
    } else if (new BN(value ?? 0).lt('0.01')) {
      result = '< 0.01';
    }
  } else {
    if (!isNormalPositive(value, true)) {
      result = '0';
    } else if (new BN(value ?? 0).lt('1')) {
      result = '< 1';
    }
  }

  return result;
};

export const formatRate = (value?: UnknownNumber) => {
  let result = formatCommas(value ?? 0, 2);

  if (!isNormalPositive(value, true)) {
    result = '0.00';
  } else if (new BN(value ?? 0).lt('0.01')) {
    result = '< 0.01';
  }

  return result;
};

export const shortenNotation = ({
  value,
  currencyId,
}: {
  value: string;
  currencyId: CurrencyId;
}) => {
  const inputValue = new BN(value);
  if (currencyId === 'krw') {
    if (inputValue.gte(1e8) && inputValue.lt(1e11)) {
      return `${inputValue.div(1e8).toFixed(0)}억`;
    } else if (inputValue.gte(1e11) && inputValue.lt(1e14)) {
      return `${inputValue.div(1e11).toFixed(0)}조`;
    } else if (inputValue.gte(1e14)) {
      return '1경+';
    } else {
      return formatCommas(inputValue.toFixed(0));
    }
  } else {
    if (inputValue.gte(1e6) && inputValue.lt(1e9)) {
      return `${inputValue.div(1e6).toFixed(0)}M`;
    } else if (inputValue.gte(1e9) && inputValue.lt(1e11)) {
      return `${inputValue.div(1e9).toFixed(0)}B`;
    } else if (inputValue.gte(1e11) && inputValue.lt(1e14)) {
      return `${inputValue.div(1e11).toFixed(0)}T`;
    } else if (inputValue.gte(1e14)) {
      return `10Q+`;
    } else {
      return formatCommas(inputValue.toFixed(0));
    }
  }
};
