import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import IconArrow from '@/assets/icons/icon-arrow.svg';

const Wrapper = styled.div`
  position: relative;
  width: 240px;

  ul {
    margin-top: 16px;
    text-align: center;
  }

  li button {
    width: 100%;
    font-size: ${(p) => p.theme.size.md};
    ${(p) => p.theme.font.regular};
    line-height: 1.5;
  }

  li + li {
    margin-top: 16px;
  }
`;

const Title = styled.p`
  font-size: ${(p) => p.theme.size.md};
  ${(p) => p.theme.font.bold};
  line-height: 1.5;
  text-align: center;
`;

const IconBack = styled(IconArrow)`
  transform: rotate(-270deg);
`;
const BackButton = styled.button`
  position: absolute;
  left: 16px;
`;

interface Props {
  isShow: boolean;
  title: string;
  menus: {
    label: string;
    onClick: () => void;
  }[];
  onBackClick: () => void;
}

function SettingDialog({
  title,
  menus,
  isShow,
  onBackClick: handleBackClick,
  ...rest
}: Props) {
  if (!isShow) {
    return;
  }

  return (
    <Wrapper
      as={motion.div}
      initial={{
        x: '100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: '100%',
      }}
      {...rest}
    >
      <BackButton onClick={handleBackClick}>
        <IconBack />
      </BackButton>
      <Title>{title}</Title>
      <ul>
        {menus.map(({ label, onClick: handleClick }) => (
          <li key={label}>
            <button type="button" onClick={handleClick}>
              {label}
            </button>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
}

export default SettingDialog;
