import {
  ProviderInfo,
  ProviderType,
  address2shorted,
  compareLowerStr,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import hexToRgba from 'hex-to-rgba';
import { useAtom } from 'jotai';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import IconArrow from '@/assets/icons/icon-arrow.svg';
import IconCopy from '@/assets/icons/icon-copy.svg';
import IconExit from '@/assets/icons/icon-exit.svg';
import IconExplorer from '@/assets/icons/icon-explorer.svg';
import IconWallet from '@/assets/icons/icon-wallet.svg';
import PROVIDERS from '@/configs/providers';
import useEnv from '@/hooks/useEnv';
import { isShowConnectWalletAtom } from '@/store/uiStore';
import clipboardCopy from '@/utils/clipboardCopy';
import Popover from './Popover';
import { StyledText } from './atom/Text';

const Wrapper = styled.div`
  position: relative;
`;
const ConnectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  height: 48px;
  border-radius: 24px;
  background: ${({ theme }) => hexToRgba(theme.colors.white, 0.2)};
  font-size: ${({ theme }) => theme.size.md};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.border.gray}
`;
const PopoverPanel = styled.div`
  padding: 16px;
  border-radius: 24px;
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, 0.2)};
  ${({ theme }) => theme.border.gray}
`;
const WalletList = styled(PopoverPanel)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 200px;
  font-size: ${({ theme }) => theme.size.md};
  ${({ theme }) => theme.font.regular}

  & > p {
    ${({ theme }) => theme.font.medium};
  }
`;
const DisconnectWrapper = styled(PopoverPanel)`
  width: 240px;

  div {
    display: flex;
    align-items: center;
  }

  div + div {
    margin-top: 16px;
  }

  div:nth-child(1) {
    justify-content: space-between;

    aside {
      display: flex;
      align-items: center;
      gap: 4px;

      button,
      a {
        opacity: 0.2;

        &:hover {
          opacity: 1;
        }
      }

      button + button {
        margin-left: 4px;
      }
    }
  }

  div:nth-child(2) {
    gap: 4px;

    span {
      margin-left: 4px;
      ${({ theme }) => theme.font.regular}
    }

    svg:hover {
      opacity: 0.6;
    }

    aside {
      margin-left: auto;
    }
  }
`;
const Wallet = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export default function ConnectWallet() {
  // translate
  const { t } = useTranslation();

  // env
  const { chainBifrost } = useEnv();

  // wallet
  const { account, providerInfos, wallet, providerType, providerRdns } =
    useWallet();

  // store
  const [isShow, setIsShow] = useAtom(isShowConnectWalletAtom);

  // state
  const [isCopiedShow, setIsCopiedShow] = useState(false);

  // memo
  const accountExplorerUrl = useMemo(
    () => `${chainBifrost.explorerUrl}address/${account}`,
    [account, chainBifrost.explorerUrl]
  );
  const providers = useMemo<
    {
      key: string;
      target: any;
      name: any;
      icon?: any;
      rdns?: string;
    }[]
  >(
    () =>
      [
        ...PROVIDERS.map((provider) => ({
          key: provider.type,
          target: provider.type,
          icon: provider.src,
          name: provider.name,
        })),
        ...(providerInfos ?? []).map((providerInfo) => ({
          ...providerInfo.info,
          icon: providerInfo.info.icon?.replace('\n', ''),
          key: providerInfo.uuid,
          target: providerInfo,
        })),
      ].filter(
        (provider, index, array) =>
          array.findIndex((item) =>
            compareLowerStr(item.name, provider.name)
          ) === index
      ),
    [providerInfos]
  );
  const connectedWallet = useMemo(() => {
    if (!account) {
      return null;
    }

    if (providerRdns) {
      return {
        icon: providers.find(({ rdns }) => rdns === providerRdns)?.icon,
        address: account,
      };
    }

    return {
      icon: providers.find(
        ({ name }) => name?.toLowerCase() === providerType?.toLowerCase()
      )?.icon,
      address: account,
    };
  }, [account, providers, providerRdns, providerType]);

  // handler
  const handleWalletClick = useCallback(
    (provider: ProviderType | ProviderInfo) => async () => {
      await wallet.connect(provider);
    },
    [wallet]
  );
  const handleDisconnectClick = useCallback(() => {
    wallet.disconnect();
  }, [wallet]);
  const handleCopyClick = useCallback(async () => {
    await clipboardCopy(account ?? '');
    setIsCopiedShow(true);

    setTimeout(() => {
      setIsCopiedShow(false);
    }, 3000);
  }, [account]);

  return (
    <Wrapper>
      {connectedWallet ? (
        <Popover
          triggerElement={
            <ConnectButton>
              <Image
                src={connectedWallet?.icon as string}
                width={24}
                height={24}
                alt=""
              />
              {address2shorted(connectedWallet?.address)} <IconArrow />
            </ConnectButton>
          }
        >
          <DisconnectWrapper>
            <div>
              <span>{t('common:connectedWallet')}</span>
              <aside>
                <a
                  href={accountExplorerUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IconExplorer />
                </a>
                <button type="button" onClick={handleDisconnectClick}>
                  <IconExit />
                </button>
              </aside>
            </div>
            <div>
              <Image
                src={connectedWallet?.icon as string}
                width={24}
                height={24}
                alt=""
              />
              <span>{address2shorted(connectedWallet?.address)}</span>
              <button type="button" onClick={handleCopyClick}>
                <IconCopy />
              </button>
              {isCopiedShow && (
                <aside>
                  <StyledText $fontSize="xs" $textColor="green">
                    {t('common:copied')}
                  </StyledText>
                </aside>
              )}
            </div>
          </DisconnectWrapper>
        </Popover>
      ) : (
        <Popover
          globalOpen={isShow}
          globalOpener={setIsShow}
          triggerElement={
            <ConnectButton>
              <span>{t('common:connectWallet')}</span>
              <IconWallet />
            </ConnectButton>
          }
        >
          <WalletList>
            <p>{t('common:connectWallet')}</p>
            {providers.map(({ key, target, icon, name }) => (
              <Wallet key={key} onClick={handleWalletClick(target)}>
                <Image src={icon as string} width={24} height={24} alt="" />
                <span>{name}</span>
              </Wallet>
            ))}
          </WalletList>
        </Popover>
      )}
    </Wrapper>
  );
}
