import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import IconBtcfi from '@/assets/icons/icon-btcfi';
import { appInitAtom } from '@/store/uiStore';
import { FlexBox } from './FlexBox';

const CurvedItem = styled.div<{ $isRight?: boolean }>`
  position: absolute;
  left: 0;
  bottom: -9vw;
  width: 50%;
  height: 13vw;
  border-top: 3vw solid #02021c;
  border-top-left-radius: 65%;

  ${(p) =>
    p.$isRight &&
    `
        left: auto;
        right: 0;
        bottom: -9vw;
        border-top-left-radius: 0;
        border-top-right-radius: 65%;
  `}
`;

const CurvedItemChild = styled.div<{ $position: 'left' | 'right' }>`
  top: 0;
  border-bottom: 4.65vw solid transparent;
  position: absolute;

  ${(p) =>
    p.$position === 'left' &&
    `
        left: 0;
        width: 100px;
        height: 15px;
        border-left: 9vw solid #02021c;
    `}

  ${(p) =>
    p.$position === 'right' &&
    `
        right: 0;
        border-right: 9vw solid #02021c;            
    `}
`;

const Wrapper = styled.div<{ $isShow: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #02021c;
  transform-style: preserve-3d;
  will-change: transform;
  z-index: 9999;

  span {
    font-size: 100px;
    font-style: italic;
    ${(p) => p.theme.font.black};
  }

  @media (max-width: 1080px) {
    span {
      font-size: 60px;
    }
  }
`;

function Cover() {
  const [init, setInit] = useAtom(appInitAtom);
  const isMobile = useMediaQuery({
    query: '(max-width: 1080px)',
  });
  const iconSize = useMemo(() => (isMobile ? 48 : 80), [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      setInit(true);
    }, 4000);
  }, [setInit]);

  if (init) {
    return null;
  }

  return (
    <Wrapper
      as={motion.div}
      $isShow={!init}
      initial={{ transform: 'translateY(0)' }}
      whileInView={{
        transform: 'translateY(-140%)',
        transition: {
          delay: 2.5,
          type: 'keyframes',
          duration: 1,
        },
      }}
      viewport={{ once: true }}
    >
      <FlexBox
        as={motion.div}
        $flex
        $align="center"
        $gap={8}
        initial={{ opacity: 0, y: 30 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            delay: 0.2,
            type: 'keyframes',
            duration: 1,
          },
        }}
        viewport={{ once: true }}
      >
        <IconBtcfi size={iconSize} />
        <span>BTCFi</span>
      </FlexBox>
      <CurvedItem>
        <CurvedItemChild $position="left" />
      </CurvedItem>
      <CurvedItem $isRight>
        <CurvedItemChild $position="right" />
      </CurvedItem>
    </Wrapper>
  );
}

export default Cover;
