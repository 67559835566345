import {
  formatUnit,
  isValidAddress,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FRAG_TOTAL_SUPPLY } from '@/configs/fragment';
import useEnv from '@/hooks/useEnv';
import useIsBifrostChain from '@/hooks/useIsBifrostChain';
import { formatNumber } from '@/libs/formatNumber';
import {
  everdexLpTotalSupplyAtom,
  everdexIsLoadingLpTotalSupplyAtom,
} from '@/store/everdexStore';

const useEverdexLpTotalSupply = () => {
  const { chainBifrost, tokenBifrostEverdexStakeLp } = useEnv();

  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  const [totalSupply, setTotalSupply] = useAtom(everdexLpTotalSupplyAtom);
  const [isLoading, setIsLoading] = useAtom(everdexIsLoadingLpTotalSupplyAtom);

  const sync = useCallback(async () => {
    if (
      !(
        tokenBifrostEverdexStakeLp?.address &&
        isValidAddress(tokenBifrostEverdexStakeLp?.address)
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const to = tokenBifrostEverdexStakeLp?.address;
      const fragment = FRAG_TOTAL_SUPPLY;
      const values: ReadonlyArray<any> = [];
      const from = account;
      const defaultBlock = 'latest';

      let totalSupply = '0';

      if (isBifrostChain) {
        totalSupply = pickNumberResponse(
          await wallet.call(to, fragment, values, from, defaultBlock)
        );
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        totalSupply = pickNumberResponse(
          await rpcContractCall(callParams, rpcURL, defaultBlock)
        );
      }

      setTotalSupply(formatNumber(formatUnit(totalSupply, 18)));
    } catch (error) {}

    setIsLoading(false);
  }, [
    account,
    chainBifrost.rpcUrls,
    isBifrostChain,
    setIsLoading,
    setTotalSupply,
    tokenBifrostEverdexStakeLp?.address,
    wallet,
  ]);

  return { totalSupply, isLoading, sync };
};

export default useEverdexLpTotalSupply;
