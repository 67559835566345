import {
  BN,
  compareLowerStr,
  useAssetInfoMap,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  isNormalPositive,
  isValidAddress,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { MAX_REPAY_RATIO } from '@/configs/actionAmountRatio';
import { FRAG_REWARD_POOL_CLAIM } from '@/configs/fragment';
import {
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_WBTC,
} from '@/configs/tokens/tokenSymbols';
import useGetCurrencyString from '@/hooks/store/useGetCurrencyString';
import { makeActionSendData, sendWithSendParams } from '@/libs/contract/wallet';
import { formatNumber } from '@/libs/formatNumber';
import log from '@/libs/log';
import {
  bifiLendingStatusesAtom,
  isLoadingBifiLendingStatusesAtom,
} from '@/store/bifiStore';
import useBifiReward from './useBifiReward';
import useEnv from '../../useEnv';
import { getIsCorrectChain } from '../../useIsCorrectChain';
import useAvailableChains from '../useAvailableChains';
import useAvailableHandlers from '../useAvailableHandlers';
import useAvailablePairs from '../useAvailablePairs';
import useLendingStatuses from '../useLendingStatuses';

const useBifi = () => {
  // env
  const {
    networkApiPath,
    chainBifrost,
    tokenBifrostBtcusd,
    addressBifiRewardPool,
  } = useEnv();

  // wallet
  const { wallet, account } = useWallet();

  // currency
  const { krwByUsd } = useAssetInfoMap();
  const { currencyId } = useGetCurrencyString();

  // store
  const [lendingStatuses, setLendingStatuses] = useAtom(
    bifiLendingStatusesAtom
  );
  const [isLoadingLendingStatuses, setIsLoadingLendingStatuses] = useAtom(
    isLoadingBifiLendingStatusesAtom
  );

  // available chains
  const { chains, isLoading: isLoadingChains } = useAvailableChains();

  // available handlers
  const { handlers, isLoading: isLoadingHandlers } =
    useAvailableHandlers('bifi');

  // available pairs
  const {
    tokens,
    symbols,
    pairs,
    isLoading: isLoadingPairs,
  } = useAvailablePairs();

  // lending statuses
  const { sync: syncLendingStatuses } = useLendingStatuses(
    'bifi',
    { handlers },
    {
      setStatuses: setLendingStatuses,
      setIsLoading: setIsLoadingLendingStatuses,
    }
  );

  // reward
  const {
    amount: rewardAmount,
    apr: rewardApr,
    isLoading: isLoadingReward,
    sync: syncReward,
  } = useBifiReward();

  // memo
  const statusesWithPrice = useMemo(
    () =>
      lendingStatuses.map((status) => ({
        ...status,
        limitOfAction: formatNumber(
          new BN(status?.limitOfAction ?? '0').multipliedBy(
            currencyId === 'krw' ? krwByUsd ?? 1 : 1
          )
        ),
        price: formatNumber(
          new BN(status?.price ?? '0').multipliedBy(
            currencyId === 'krw' ? krwByUsd ?? 1 : 1
          )
        ),
      })),
    [currencyId, krwByUsd, lendingStatuses]
  );
  const statusWbtc = useMemo(
    () =>
      statusesWithPrice.find((status) =>
        compareLowerStr(status.symbol, TOKEN_SYMBOL_WBTC)
      ),
    [statusesWithPrice]
  );
  const statusBtcb = useMemo(
    () =>
      statusesWithPrice.find((status) =>
        compareLowerStr(status.symbol, TOKEN_SYMBOL_BTCB)
      ),
    [statusesWithPrice]
  );
  const statusBtcusd = useMemo(
    () =>
      statusesWithPrice.find((status) =>
        compareLowerStr(status.symbol, TOKEN_SYMBOL_BTCUSD)
      ),
    [statusesWithPrice]
  );
  const priceWbtc = useMemo(
    () => statusWbtc?.price ?? '0',
    [statusWbtc?.price]
  );
  const priceBtcb = useMemo(
    () => statusBtcb?.price ?? '0',
    [statusBtcb?.price]
  );
  const priceBtcusd = useMemo(
    () => statusBtcusd?.price ?? '0',
    [statusBtcusd?.price]
  );
  const handlerBtcusd = useMemo(
    () =>
      handlers.find((handler) =>
        compareLowerStr(handler.tokenAddress, tokenBifrostBtcusd?.address)
      ),
    [handlers, tokenBifrostBtcusd?.address]
  );
  const addressHandlerBtcusd = useMemo(
    () => handlerBtcusd?.address,
    [handlerBtcusd?.address]
  );
  const isLoading = useMemo(
    () =>
      isLoadingChains ||
      isLoadingHandlers ||
      isLoadingPairs ||
      isLoadingLendingStatuses ||
      isLoadingReward,
    [
      isLoadingChains,
      isLoadingHandlers,
      isLoadingLendingStatuses,
      isLoadingPairs,
      isLoadingReward,
    ]
  );

  // callback
  const deposit = useCallback(
    async ({ amount }: { amount?: string }) => {
      log('bifi:deposit', tokenBifrostBtcusd, amount, addressHandlerBtcusd);

      if (
        !(
          tokenBifrostBtcusd &&
          amount &&
          addressHandlerBtcusd &&
          chainBifrost &&
          account &&
          isNormalPositive(tokenBifrostBtcusd.chainId, true) &&
          isValidAddress(tokenBifrostBtcusd.address) &&
          isNormalPositive(amount, true) &&
          isValidAddress(addressHandlerBtcusd) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, tokenBifrostBtcusd.chainId)
        )
      ) {
        throw new Error('arguments error');
      }

      const { encodedParams, sendParams } = await makeActionSendData({
        actionType: 'bifi:deposit',
        tokenAddress: tokenBifrostBtcusd.address,
        from: account,
        to: addressHandlerBtcusd,
        unknwonNumberValue: amount,
        isCrossChain: false,
        chainId: tokenBifrostBtcusd.chainId,
        chainIdBifrost: chainBifrost.id,
        networkApiPath,
      });

      log('bifi:deposit', encodedParams, sendParams);

      return await sendWithSendParams(wallet, sendParams);
    },
    [
      account,
      addressHandlerBtcusd,
      chainBifrost,
      networkApiPath,
      tokenBifrostBtcusd,
      wallet,
    ]
  );
  const withdraw = useCallback(
    async ({ amount, isMax }: { amount?: string; isMax?: boolean }) => {
      log(
        'bifi:withdraw',
        tokenBifrostBtcusd,
        amount,
        isMax,
        addressHandlerBtcusd
      );

      if (
        !(
          tokenBifrostBtcusd &&
          amount &&
          addressHandlerBtcusd &&
          chainBifrost &&
          account &&
          isNormalPositive(tokenBifrostBtcusd.chainId, true) &&
          isValidAddress(tokenBifrostBtcusd.address) &&
          isNormalPositive(amount, true) &&
          isValidAddress(addressHandlerBtcusd) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, tokenBifrostBtcusd.chainId)
        )
      ) {
        throw new Error('arguments error');
      }

      const { encodedParams, sendParams } = await makeActionSendData({
        actionType: 'bifi:withdraw',
        tokenAddress: tokenBifrostBtcusd.address,
        from: account,
        to: addressHandlerBtcusd,
        unknwonNumberValue: new BN(amount).multipliedBy(
          isMax ? MAX_REPAY_RATIO : 1
        ),
        isCrossChain: false,
        chainId: tokenBifrostBtcusd.chainId,
        chainIdBifrost: chainBifrost.id,
        networkApiPath,
      });

      log('bifi:withdraw', encodedParams, sendParams);

      return await sendWithSendParams(wallet, sendParams);
    },
    [
      account,
      addressHandlerBtcusd,
      chainBifrost,
      networkApiPath,
      tokenBifrostBtcusd,
      wallet,
    ]
  );
  const claim = useCallback(async () => {
    log('bifi:rewardPoolClaim', addressBifiRewardPool);

    if (
      !(
        addressBifiRewardPool &&
        chainBifrost &&
        account &&
        isValidAddress(addressBifiRewardPool) &&
        isNormalPositive(chainBifrost.id, true) &&
        isValidAddress(account) &&
        getIsCorrectChain(wallet, chainBifrost.id)
      )
    ) {
      throw new Error('arguments error');
    }

    const from = account;
    const to = addressBifiRewardPool;
    const signature = FRAG_REWARD_POOL_CLAIM;
    const values: ReadonlyArray<any> = [];
    const value = '0x0';

    log(from, to, signature, values, value);

    return await wallet.send(to, signature, values, value, from);
  }, [account, addressBifiRewardPool, chainBifrost, wallet]);
  const sync = useCallback(async () => {
    try {
      await Promise.all([syncLendingStatuses(), syncReward()]);
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncLendingStatuses, syncReward]);

  // return
  return {
    tokens,
    symbols,
    pairs,
    handlers,
    chains,
    lendingStatuses: statusesWithPrice,
    statusWbtc,
    statusBtcb,
    statusBtcusd,
    priceWbtc,
    priceBtcb,
    priceBtcusd,
    isLoading,
    rewardAmount,
    rewardApr,
    deposit,
    withdraw,
    claim,
    sync,
  };
};

export default useBifi;
