const path = require('path');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  debug: process.env.NODE_ENV !== 'production',
  i18n: {
    locales: ['en', 'ko'],
    defaultLocale: 'en',
  },
  ...(typeof window === undefined
    ? { localePath: path.resolve('./public/locales') }
    : {}),
};
