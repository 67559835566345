import { AnimatePresence, motion } from 'framer-motion';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import IconSetting from '@/assets/icons/icon-setting.svg';
import Popover from '@/components/Popover';
import { IS_ONLY_TESTNET_ALLOWED } from '@/configs/devEnv/testnetLaunch';
import useEnv from '@/hooks/useEnv';
import { eventSettingClick } from '@/libs/gtag';
import { CurrencyId } from '@/types/env/Currency';
import IsTestnet from '@/types/env/IsTestnet';
import { LanguageId } from '@/types/env/Language';
import DefaultSettingDialog from './DefaultSettingDialog';
import SettingDialog from './SettingDialog';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SettingButton = styled.button`
  width: 24px;
  height: 24px;
`;

const SettingWrapper = styled.div`
  padding: 16px;
  border-radius: 24px;
  background-color: ${(p) => hexToRgba(p.theme.colors.black, 0.8)};
  ${(p) => p.theme.border.gray};
  overflow: hidden;
  position: absolute;
  top: 16px;
  right: 0;
`;

export type SettingMenu = '' | 'language' | 'currency' | 'network';

export default function GlobalSetting() {
  const { t } = useTranslation();

  const {
    currencyId,
    languageId,
    isTestnet,
    changeCurrency,
    changeLanguage,
    changeIsTestnet,
  } = useEnv();

  const [menu, setMenu] = useState<SettingMenu>('');

  const handleMenuClick = useCallback(
    (menu: SettingMenu) => () => {
      setMenu(menu);
    },
    []
  );
  const handleLanguageClick = useCallback(
    (language: LanguageId) => () => {
      changeLanguage(language);
      setMenu('');
    },
    [changeLanguage]
  );
  const handleCurrencyClick = useCallback(
    (currency: CurrencyId) => () => {
      changeCurrency(currency);
      setMenu('');
    },
    [changeCurrency]
  );
  const handleNetworkClick = useCallback(
    (isTestnet: IsTestnet) => () => {
      changeIsTestnet(isTestnet);
      setMenu('');
    },
    [changeIsTestnet]
  );
  const handleSettingButtonClick = useCallback(() => {
    eventSettingClick(isTestnet);
    setMenu('');
  }, [isTestnet]);

  return (
    <Wrapper>
      <Popover
        offsetPx={28}
        triggerElement={
          <SettingButton onClick={handleSettingButtonClick}>
            <IconSetting />
          </SettingButton>
        }
      >
        <SettingWrapper as={motion.div} layout="size">
          <AnimatePresence initial={false}>
            <DefaultSettingDialog
              isShow={!menu}
              currencyId={currencyId}
              languageId={languageId}
              isTestnet={isTestnet}
              onMenuClick={handleMenuClick}
            />
            <SettingDialog
              isShow={menu === 'language'}
              title={t('common:language')}
              menus={[
                {
                  label: '한국어',
                  onClick: handleLanguageClick('ko'),
                },
                {
                  label: 'English',
                  onClick: handleLanguageClick('en'),
                },
              ]}
              onBackClick={handleMenuClick('')}
            />
            <SettingDialog
              isShow={menu === 'currency'}
              title={t('common:baseUnit')}
              menus={[
                {
                  label: 'KRW',
                  onClick: handleCurrencyClick('krw'),
                },
                {
                  label: 'USD',
                  onClick: handleCurrencyClick('usd'),
                },
              ]}
              onBackClick={handleMenuClick('')}
            />
            {!IS_ONLY_TESTNET_ALLOWED ? (
              <SettingDialog
                isShow={menu === 'network'}
                title={t('common:network')}
                menus={[
                  {
                    label: t('common:mainnet'),
                    onClick: handleNetworkClick(false),
                  },
                  {
                    label: t('common:testnet'),
                    onClick: handleNetworkClick(true),
                  },
                ]}
                onBackClick={handleMenuClick('')}
              />
            ) : (
              ''
            )}
          </AnimatePresence>
        </SettingWrapper>
      </Popover>
    </Wrapper>
  );
}
