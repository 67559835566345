import axios from 'axios';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { API_HOST_EVERDEX } from '@/configs/apiHost';
import useEnv from '@/hooks/useEnv';
import Pool from '@/types/Pool';
import ResponseEverdex from '@/types/response/ResponseEverdex';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useEverdexPool = () => {
  const { chainBifrost, addressEverdexRewardPool } = useEnv();

  const { data, error, mutate } = useSWR<ResponseEverdex<Pool>>(
    `${API_HOST_EVERDEX}/v2/chains/${chainBifrost.id}/pools/${addressEverdexRewardPool}`,
    fetcher
  );

  const isError = useMemo(
    () => !!(error || axios.isAxiosError(data)),
    [data, error]
  );
  const isLoading = useMemo(() => !isError && !data, [data, isError]);
  const pool = useMemo(
    () => (isError ? undefined : data?.data),
    [data?.data, isError]
  );

  const sync = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    pool,
    isLoading,
    isError,
    sync,
  };
};

export default useEverdexPool;
