import GoogleAnalyticsEvent from '@/types/GoogleAnalyticsEvent';

export const GOOGLE_ANALYTICS_TRACKING_ID: string =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID || '';

export const GOOGLE_ANALYTICS_EVENT_LAUNCH_APP_CLICK: GoogleAnalyticsEvent = {
  action: 'launch_app_click',
  screen: 'Landing',
  message: 'Click',
  label: 'Launch',
};
export const GOOGLE_ANALYTICS_EVENT_MINTING_DONE: GoogleAnalyticsEvent = {
  action: 'minting_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Minting',
};
export const GOOGLE_ANALYTICS_EVENT_REPAY_DONE: GoogleAnalyticsEvent = {
  action: 'done_repay',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Repay',
};
export const GOOGLE_ANALYTICS_EVENT_WITHDRAW_DONE: GoogleAnalyticsEvent = {
  action: 'done_withdraw',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Withdraw',
};
export const GOOGLE_ANALYTICS_EVENT_SUPPLY_DONE: GoogleAnalyticsEvent = {
  action: 'done_supply',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Supply',
};
export const GOOGLE_ANALYTICS_EVENT_GO_TO_EARN_CLICK: GoogleAnalyticsEvent = {
  action: 'go_to_earn_click',
  screen: 'Dashboard',
  message: 'Click',
  label: 'Go to earn',
};
export const GOOGLE_ANALYTICS_EVENT_FIRST_DEPOSIT_CLICK: GoogleAnalyticsEvent =
  {
    action: 'first_deposit_click',
    screen: 'Dashboard',
    message: 'Click',
    label: 'Find out more',
  };
export const GOOGLE_ANALYTICS_EVENT_BIFI_CLAIM_REWARDS_CLICK: GoogleAnalyticsEvent =
  {
    action: 'claim_rewards_click',
    screen: 'Dashboard',
    message: 'Click',
    label: 'Claim BiFi rewards',
  };
export const GOOGLE_ANALYTICS_EVENT_BIFI_CLAIM_REWARDS_DONE: GoogleAnalyticsEvent =
  {
    action: 'claim_rewards_done',
    screen: 'Dashboard',
    message: 'Done',
    label: 'Claim BiFi rewards',
  };
export const GOOGLE_ANALYTICS_EVENT_BIFI_WITHDRAW_CLICK: GoogleAnalyticsEvent =
  {
    action: 'withdraw_click',
    screen: 'Dashboard',
    message: 'Click',
    label: 'BiFi withdraw',
  };
export const GOOGLE_ANALYTICS_EVENT_BIFI_WITHDRAW_DONE: GoogleAnalyticsEvent = {
  action: 'withdraw_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'BiFi withdraw',
};
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_CLAIM_REWARDS_CLICK: GoogleAnalyticsEvent =
  {
    action: 'claim_rewards_click',
    screen: 'Dashboard',
    message: 'Click',
    label: 'Claim LP rewards',
  };
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_CLAIM_REWARDS_DONE: GoogleAnalyticsEvent =
  {
    action: 'claim_rewards_done',
    screen: 'Dashboard',
    message: 'Done',
    label: 'Claim LP rewards',
  };
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_UNSTAKING_CLICK: GoogleAnalyticsEvent =
  {
    action: 'unstaking_click',
    screen: 'Dashboard',
    message: 'Click',
    label: 'Unstake',
  };
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_UNSTAKING_DONE: GoogleAnalyticsEvent =
  {
    action: 'unstaking_done',
    screen: 'Dashboard',
    message: 'Done',
    label: 'Unstake',
  };
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_REMOVE_LIQUIDITY_CLICK: GoogleAnalyticsEvent =
  {
    action: 'remove_liquidity_click',
    screen: 'Dashboard',
    message: 'Click',
    label: 'Everdex withdraw',
  };
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_REMOVE_LIQUIDITY_DONE: GoogleAnalyticsEvent =
  {
    action: 'remove_liquidity_done',
    screen: 'Dashboard',
    message: 'Done',
    label: 'Everdex withdraw',
  };
export const GOOGLE_ANALYTICS_EVENT_BIFI_DEPOSIT_CLICK: GoogleAnalyticsEvent = {
  action: 'deposit_click',
  screen: 'Earn',
  message: 'Click',
  label: 'BiFi deposit',
};
export const GOOGLE_ANALYTICS_EVENT_BIFI_DEPOSIT_DONE: GoogleAnalyticsEvent = {
  action: 'deposit_done',
  screen: 'Earn',
  message: 'Done',
  label: 'BiFi deposit',
};
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_ADD_LIQUIDITY_CLICK: GoogleAnalyticsEvent =
  {
    action: 'add_liquidity_click',
    screen: 'Earn',
    message: 'Click',
    label: 'Everdex deposit',
  };
export const GOOGLE_ANALYTICS_EVENT_EVERDEX_ADD_LIQUIDITY_DONE: GoogleAnalyticsEvent =
  {
    action: 'add_liquidity_done',
    screen: 'Earn',
    message: 'Done',
    label: 'Everdex deposit',
  };
export const GOOGLE_ANALYTICS_EVENT_MINTING_MAX_DONE: GoogleAnalyticsEvent = {
  action: 'mintingmax_done',
  screen: 'Points',
  message: 'Done',
  label: 'Minting',
};
export const GOOGLE_ANALYTICS_EVENT_ADD_LIQUIDITY_MAX_DONE: GoogleAnalyticsEvent =
  {
    action: 'add_liquiditymax_done',
    screen: 'Points',
    message: 'Done',
    label: 'Everdex',
  };
export const GOOGLE_ANALYTICS_EVENT_FAUCET_CLICK: GoogleAnalyticsEvent = {
  action: 'faucet_click',
  screen: 'Header',
  message: 'Click',
  label: 'Faucet',
};
export const GOOGLE_ANALYTICS_EVENT_SETTING_CLICK: GoogleAnalyticsEvent = {
  action: 'setting_click',
  screen: 'Header',
  message: 'Click',
  label: 'Setting',
};
export const GOOGLE_ANALYTICS_EVENT_DOCS_CLICK: GoogleAnalyticsEvent = {
  action: 'docs_click',
  screen: 'Header',
  message: 'Click',
  label: 'Docs',
};
export const GOOGLE_ANALYTICS_EVENT_HELP_CENTER_CLICK: GoogleAnalyticsEvent = {
  action: 'help_center_click',
  screen: 'Header',
  message: 'Click',
  label: 'Help Center',
};
export const GOOGLE_ANALYTICS_EVENT_POLICY_CLICK: GoogleAnalyticsEvent = {
  action: 'privacy_policy_click',
  screen: 'Header',
  message: 'Click',
  label: 'Privacy policy&Service terms',
};
export const GOOGLE_ANALYTICS_EVENT_DOCS_FOOTER_CLICK: GoogleAnalyticsEvent = {
  action: 'docs_click',
  screen: 'Footer',
  message: 'Click',
  label: 'Docs',
};
export const GOOGLE_ANALYTICS_EVENT_HELP_CENTER_FOOTER_CLICK: GoogleAnalyticsEvent =
  {
    action: 'help_center_click',
    screen: 'Footer',
    message: 'Click',
    label: 'Help Center',
  };
export const GOOGLE_ANALYTICS_EVENT_POLICY_FOOTER_CLICK: GoogleAnalyticsEvent =
  {
    action: 'privacy_policy_click',
    screen: 'Footer',
    message: 'Click',
    label: 'Privacy policy&Service terms',
  };
