import styled from 'styled-components';
import { Theme } from '@/styles/theme';

/**
 * Base Text component.
 * To change HTML tag, use `as` prop
 * ```<Text as="h1">Hello</Text>```
 */
export interface Props {
  $fontSize?: keyof Theme['size'] | number;
  $textColor?: keyof Theme['colors'];
  $fontWeight?: keyof Theme['font'];
  $whiteSpace?: 'pre' | 'pre-wrap' | 'pre-line' | 'nowrap';
  $textAlign?: 'left' | 'right' | 'center';
}

export const StyledText = styled.span<Props>`
  font-size: ${(p) =>
    typeof p.$fontSize === 'number'
      ? `${p.$fontSize}px`
      : p.theme.size[p.$fontSize ?? 'md']};
  color: ${(p) => p.theme.colors[p.$textColor ?? 'textWhite']};
  ${(p) => p.theme.font[p.$fontWeight ?? 'regular']};
  white-space: ${(p) => p.$whiteSpace ?? 'normal'};
  text-align: ${(p) => p.$textAlign ?? 'left'};
`;
