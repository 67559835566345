import { ChainId } from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  ZERO_ADDRESS,
  compareLowerStr,
  isValidAddress,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useMemo } from 'react';
import {
  MAINNET_ADD_BIFI_REWARD_POOL,
  TESTNET_ADD_BIFI_REWARD_POOL,
  MAINNET_ADD_EVERDEX_REWARD_POOL,
  TESTNET_ADD_EVERDEX_REWARD_POOL,
  TESTNET_ADD_EVERDEX_SWAP_MERGER,
  MAINNET_ADD_EVERDEX_SWAP_MERGER,
} from '@/configs/address';
import {
  MAINNET_CHAINS,
  MAINNET_CHAIN_BIFROST,
} from '@/configs/chains/mainnet';
import {
  TESTNET_CHAINS,
  TESTNET_CHAIN_BIFROST,
} from '@/configs/chains/testnet';
import {
  MAINNET_EVERDEX_LP_REWARD_POOL,
  TESTNET_EVERDEX_LP_REWARD_POOL,
} from '@/configs/everdexLpRewardPool';
import { BTC_SYMBOLS } from '@/configs/symbols';
import MAINNET_TOKENS from '@/configs/tokens/mainnetTokens';
import TESTNET_TOKENS from '@/configs/tokens/testnetTokens';
import {
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_EVERDEX_STAKE_LP,
} from '@/configs/tokens/tokenSymbols';
import useCurrency from './store/useCurrency';
import useIsTestnet from './store/useIsTestnet';
import useLanguage from './store/useLanguage';

const useEnv = () => {
  const {
    languageId,
    locale,
    changeLanguage,
    sync: syncLanguage,
  } = useLanguage();
  const { currencyId, changeCurrency, sync: syncCurrency } = useCurrency();
  const {
    isTestnet,
    networkTitle,
    networkApiPath,
    changeIsTestnet,
    sync: syncIsTestnet,
  } = useIsTestnet();

  const chains = useMemo(
    () => (isTestnet ? TESTNET_CHAINS : MAINNET_CHAINS),
    [isTestnet]
  );
  const tokens = useMemo(
    () =>
      (isTestnet ? TESTNET_TOKENS : MAINNET_TOKENS).filter((token) =>
        isValidAddress(token.address)
      ),
    [isTestnet]
  );
  const addressBifiRewardPool = useMemo(
    () =>
      isTestnet ? TESTNET_ADD_BIFI_REWARD_POOL : MAINNET_ADD_BIFI_REWARD_POOL,
    [isTestnet]
  );
  const everdexLpRewardPool = useMemo(
    () =>
      isTestnet
        ? TESTNET_EVERDEX_LP_REWARD_POOL
        : MAINNET_EVERDEX_LP_REWARD_POOL,
    [isTestnet]
  );
  const everdexLpRewardPoolPair = useMemo(
    () => everdexLpRewardPool.tokens,
    [everdexLpRewardPool.tokens]
  );
  const everdexLpRewardPoolPairSymbol = useMemo(
    () => everdexLpRewardPoolPair.map((token) => token.symbol).join('-'),
    [everdexLpRewardPoolPair]
  );
  const addressEverdexRewardPool = useMemo(
    () =>
      isTestnet
        ? TESTNET_ADD_EVERDEX_REWARD_POOL
        : MAINNET_ADD_EVERDEX_REWARD_POOL,
    [isTestnet]
  );
  const addressSwapMerger = useMemo(
    () =>
      isTestnet
        ? TESTNET_ADD_EVERDEX_SWAP_MERGER
        : MAINNET_ADD_EVERDEX_SWAP_MERGER,
    [isTestnet]
  );

  // chain
  const chainBifrost = useMemo(
    () => (isTestnet ? TESTNET_CHAIN_BIFROST : MAINNET_CHAIN_BIFROST),
    [isTestnet]
  );

  // tokens
  const tokensBifrost = useMemo(
    () => tokens.filter((token) => token.chainId === chainBifrost.id),
    [chainBifrost.id, tokens]
  );
  const tokensBifrostBfc = useMemo(
    () =>
      tokens.filter((token) => compareLowerStr(token.symbol, TOKEN_SYMBOL_BFC)),
    [tokens]
  );
  const tokensBtc = useMemo(
    () =>
      tokens.filter((token) =>
        BTC_SYMBOLS.find((symbol) => compareLowerStr(token.symbol, symbol))
      ),
    [tokens]
  );
  const tokensBifrostBtc = useMemo(
    () => tokensBtc.filter((token) => token.chainId === chainBifrost.id),
    [chainBifrost.id, tokensBtc]
  );

  // token
  const tokenBifrostBfc = useMemo(
    () =>
      tokensBifrostBfc.find(
        (token) =>
          token.address === ZERO_ADDRESS && token.chainId === chainBifrost.id
      ),
    [chainBifrost.id, tokensBifrostBfc]
  );
  const tokenBifrostBtcusd = useMemo(
    () =>
      tokens.find(
        (token) =>
          compareLowerStr(token.symbol, TOKEN_SYMBOL_BTCUSD) &&
          token.chainId === chainBifrost.id
      ),
    [chainBifrost.id, tokens]
  );
  const tokenBifrostUsdc = useMemo(
    () =>
      tokens.find(
        (token) =>
          compareLowerStr(token.symbol, TOKEN_SYMBOL_USDC) &&
          token.chainId === chainBifrost.id
      ),
    [chainBifrost.id, tokens]
  );
  const tokenBifrostUsdt = useMemo(
    () =>
      tokens.find(
        (token) =>
          compareLowerStr(token.symbol, TOKEN_SYMBOL_USDT) &&
          token.chainId === chainBifrost.id
      ),
    [chainBifrost.id, tokens]
  );
  const tokenBifrostEverdexStakeLp = useMemo(
    () =>
      tokens.find(
        (token) =>
          compareLowerStr(token.symbol, TOKEN_SYMBOL_EVERDEX_STAKE_LP) &&
          token.chainId === chainBifrost.id
      ),
    [chainBifrost.id, tokens]
  );

  const getExplorerUrl = useCallback(
    (chainId: ChainId) =>
      chains.find((chain) => chain.id === chainId)?.explorerUrl,
    [chains]
  );
  const makeExplorerUrl = useCallback(
    (
      transactionHash: string,
      option?: { chainId?: ChainId; isBridge?: boolean }
    ) => {
      const explorerUrl =
        getExplorerUrl(option?.chainId ?? chainBifrost.id) ??
        chainBifrost.explorerUrl;
      const urlPath = option?.isBridge ? '/bridge-detail?txHash=' : '/tx/';

      return `${explorerUrl.replace(/\/$/, '')}${urlPath}${transactionHash}`;
    },
    [chainBifrost.explorerUrl, chainBifrost.id, getExplorerUrl]
  );

  const sync = useCallback(() => {
    syncLanguage();
    syncCurrency();
    syncIsTestnet();
  }, [syncCurrency, syncIsTestnet, syncLanguage]);

  return {
    currencyId,
    languageId,
    locale,
    isTestnet,
    networkTitle,
    networkApiPath,
    chains,
    tokens,
    addressBifiRewardPool,
    everdexLpRewardPool,
    everdexLpRewardPoolPair,
    everdexLpRewardPoolPairSymbol,
    addressEverdexRewardPool,
    addressSwapMerger,
    chainBifrost,
    tokensBifrost,
    tokensBifrostBfc,
    tokensBtc,
    tokensBifrostBtc,
    tokenBifrostBfc,
    tokenBifrostBtcusd,
    tokenBifrostUsdc,
    tokenBifrostUsdt,
    tokenBifrostEverdexStakeLp,
    getExplorerUrl,
    makeExplorerUrl,
    changeLanguage,
    changeCurrency,
    changeIsTestnet,
    sync,
  };
};

export default useEnv;
