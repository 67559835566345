import { BN, Token } from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  UnknownNumber,
  isNormalPositive,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useMemo } from 'react';
import { formatAmountValue, formatNumber } from '@/libs/formatNumber';
import { CurrencyId } from '@/types/env/Currency';
import useGetPrice from './useGetPrice';
import useEnv from '../useEnv';

export const getCurrencyReplaceString = (id: CurrencyId) => {
  let result = '{}';

  switch (id) {
    case 'usd':
      result = '${}';
      break;
    case 'krw':
      result = '₩{}';
      break;
  }

  return result;
};

const useGetCurrencyString = (forceCurrencyId?: CurrencyId) => {
  // env
  const { currencyId } = useEnv();

  // get price
  const getPrice = useGetPrice();

  // memo
  const currencyReplaceString = useMemo(
    () => getCurrencyReplaceString(forceCurrencyId ?? currencyId),
    [currencyId, forceCurrencyId]
  );
  const currencySymbol = useMemo(
    () => currencyReplaceString.replace('{}', ''),
    [currencyReplaceString]
  );

  // callback
  // amount to currency unit with price
  const getAmountValue = useCallback(
    (amount?: UnknownNumber, price?: UnknownNumber) => {
      const amountValue = isNormalPositive(price, true)
        ? new BN(amount ?? '0').multipliedBy(price ?? '0')
        : new BN('0');
      const result = formatNumber(amountValue);

      return result;
    },
    []
  );
  // currency unit to amount with price
  const getAmount = useCallback(
    (value?: UnknownNumber, price?: UnknownNumber) => {
      const amount = isNormalPositive(price, true)
        ? new BN(value ?? '0').div(price ?? '0')
        : new BN('0');
      const result = formatNumber(amount);

      return result;
    },
    []
  );
  // amount to currency unit
  const formatCurrencyValue = useCallback(
    (amount?: UnknownNumber, token?: Token) => {
      const price = getPrice(token);
      const result = getAmountValue(amount, price);

      return result;
    },
    [getAmountValue, getPrice]
  );
  // currency unit to amount
  const parseCurrencyValue = useCallback(
    (value?: UnknownNumber, token?: Token) => {
      const price = getPrice(token);
      const amount = getAmount(value, price);
      const result = formatNumber(amount);

      return result;
    },
    [getAmount, getPrice]
  );
  const getNumberWithCurrencyReplace = useCallback(
    (
      value?: UnknownNumber,
      formatter: (
        v: UnknownNumber,
        currencyId: string
      ) => string = formatAmountValue
    ) => {
      const result = currencyReplaceString.replace(
        '{}',
        formatter(value ?? 0, currencyId)
      );

      return result;
    },
    [currencyId, currencyReplaceString]
  );
  const getCurrencyString = useCallback(
    (
      amount?: UnknownNumber,
      token?: Token,
      formatter: (
        v: UnknownNumber,
        currencyId: string
      ) => string = formatAmountValue
    ) => {
      const result = getNumberWithCurrencyReplace(
        formatCurrencyValue(amount, token),
        formatter
      );

      return result;
    },
    [formatCurrencyValue, getNumberWithCurrencyReplace]
  );

  return {
    currencyReplaceString,
    currencySymbol,
    currencyId,
    getPrice,
    getAmountValue,
    getAmount,
    formatCurrencyValue,
    parseCurrencyValue,
    getNumberWithCurrencyReplace,
    getCurrencyString,
  };
};

export default useGetCurrencyString;
