import { Token } from '@bifrost-platform/bifront-sdk-react-biholder';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import Pair from '@/types/Pair';
import useEnv from '../useEnv';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useAvailablePairs = () => {
  const { isTestnet } = useEnv();

  const { data, isLoading, error, mutate } = useSWR<{
    pairs: Pair[];
    tokens: Token[];
  }>(`/api/getAvailablePairs?isTestnet=${isTestnet}`, fetcher);

  const availablePairs = useMemo(() => data?.pairs ?? [], [data?.pairs]);
  const availableTokens = useMemo(() => data?.tokens ?? [], [data?.tokens]);
  const availableSymbols = useMemo(
    () =>
      availableTokens
        .map((token) => token.symbol)
        .filter((symbol, index, array) => array.indexOf(symbol) === index),
    [availableTokens]
  );

  const sync = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    pairs: availablePairs,
    tokens: availableTokens,
    symbols: availableSymbols,
    isLoading,
    loadError: error,
    sync,
  };
};

export default useAvailablePairs;
