import styled from 'styled-components';

export interface Props {
  size?: number;
  src?: string;
}

const ImageBase = styled.img<{ size: number }>`
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
`;
const ImageContainer = styled.div<{ size: number }>`
  position: relative;
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.size.xs};
  ${({ theme }) => theme.font.bold}
  color: ${({ theme }) => theme.colors.textGray};
`;
const Image = styled(ImageBase)``;

export default function ImageIcon({ size = 32, src }: Props) {
  return (
    <ImageContainer size={size}>
      {src ? <Image size={size} src={src} alt="" /> : ''}
    </ImageContainer>
  );
}
