import { ChainId } from '@bifrost-platform/bifront-sdk-react-biholder';

// id
export const MAINNET_CHAIN_ID_BIFROST: ChainId = 3068;
export const MAINNET_CHAIN_ID_ETHEREUM: ChainId = 1;
export const MAINNET_CHAIN_ID_ARBITRUM: ChainId = 42161;
export const MAINNET_CHAIN_ID_POLYGON: ChainId = 137;
export const MAINNET_CHAIN_ID_BSC: ChainId = 56;
export const MAINNET_CHAIN_ID_BASE: ChainId = 8453;

export const MAINNET_CHAIN_IDS = [
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_ETHEREUM,
  MAINNET_CHAIN_ID_ARBITRUM,
  MAINNET_CHAIN_ID_POLYGON,
  MAINNET_CHAIN_ID_BSC,
  MAINNET_CHAIN_ID_BASE,
];
