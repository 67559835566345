import {
  GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_ANALYTICS_EVENT_LAUNCH_APP_CLICK,
  GOOGLE_ANALYTICS_EVENT_MINTING_DONE,
  GOOGLE_ANALYTICS_EVENT_REPAY_DONE,
  GOOGLE_ANALYTICS_EVENT_WITHDRAW_DONE,
  GOOGLE_ANALYTICS_EVENT_SUPPLY_DONE,
  GOOGLE_ANALYTICS_EVENT_GO_TO_EARN_CLICK,
  GOOGLE_ANALYTICS_EVENT_BIFI_CLAIM_REWARDS_CLICK,
  GOOGLE_ANALYTICS_EVENT_BIFI_CLAIM_REWARDS_DONE,
  GOOGLE_ANALYTICS_EVENT_BIFI_WITHDRAW_CLICK,
  GOOGLE_ANALYTICS_EVENT_BIFI_WITHDRAW_DONE,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_CLAIM_REWARDS_CLICK,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_CLAIM_REWARDS_DONE,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_UNSTAKING_CLICK,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_UNSTAKING_DONE,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_REMOVE_LIQUIDITY_CLICK,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_REMOVE_LIQUIDITY_DONE,
  GOOGLE_ANALYTICS_EVENT_BIFI_DEPOSIT_CLICK,
  GOOGLE_ANALYTICS_EVENT_BIFI_DEPOSIT_DONE,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_ADD_LIQUIDITY_CLICK,
  GOOGLE_ANALYTICS_EVENT_EVERDEX_ADD_LIQUIDITY_DONE,
  GOOGLE_ANALYTICS_EVENT_MINTING_MAX_DONE,
  GOOGLE_ANALYTICS_EVENT_ADD_LIQUIDITY_MAX_DONE,
  GOOGLE_ANALYTICS_EVENT_FAUCET_CLICK,
  GOOGLE_ANALYTICS_EVENT_SETTING_CLICK,
  GOOGLE_ANALYTICS_EVENT_DOCS_CLICK,
  GOOGLE_ANALYTICS_EVENT_HELP_CENTER_CLICK,
  GOOGLE_ANALYTICS_EVENT_POLICY_CLICK,
  GOOGLE_ANALYTICS_EVENT_DOCS_FOOTER_CLICK,
  GOOGLE_ANALYTICS_EVENT_HELP_CENTER_FOOTER_CLICK,
  GOOGLE_ANALYTICS_EVENT_POLICY_FOOTER_CLICK,
  GOOGLE_ANALYTICS_EVENT_FIRST_DEPOSIT_CLICK,
} from '@/configs/gtag';
import GoogleAnalyticsEvent from '@/types/GoogleAnalyticsEvent';
import getWindow from './getWindow';
import isCorrectOrigin from './isCorrectOrigin';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag?: (type: string, id: string, option: any) => void;
  }
}

export const isUsableGtag = () =>
  !!(
    typeof getWindow()?.gtag === 'function' &&
    GOOGLE_ANALYTICS_TRACKING_ID &&
    isCorrectOrigin()
  );

export const pageview = (url: string, isTestnet: boolean) => {
  if (isUsableGtag()) {
    try {
      getWindow()?.gtag?.('config', GOOGLE_ANALYTICS_TRACKING_ID, {
        page_path: url,
        isTestnet,
      });
    } catch (error) {}
  }
};

export const event = ({
  action,
  screen,
  message,
  type,
  label,
  isTestnet,
}: GoogleAnalyticsEvent) => {
  if (isUsableGtag()) {
    try {
      window?.gtag?.('event', action, {
        event_label: label,
        screen,
        message,
        type,
        label,
        isTestnet,
      });
    } catch (error) {}
  }
};

export const eventLaunchAppClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_LAUNCH_APP_CLICK,
    isTestnet,
  });
};
export const eventMintingDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_MINTING_DONE,
    isTestnet,
  });
};
export const eventRepayDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_REPAY_DONE,
    isTestnet,
  });
};
export const eventWithdrawDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_WITHDRAW_DONE,
    isTestnet,
  });
};
export const eventSupplyDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_SUPPLY_DONE,
    isTestnet,
  });
};
export const eventGoToEarnClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_GO_TO_EARN_CLICK,
    isTestnet,
  });
};
export const eventFirstDepositClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_FIRST_DEPOSIT_CLICK,
    isTestnet,
  });
};
export const eventBifiClaimRewardsClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_BIFI_CLAIM_REWARDS_CLICK,
    isTestnet,
  });
};
export const eventBifiClaimRewardsDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_BIFI_CLAIM_REWARDS_DONE,
    isTestnet,
  });
};
export const eventBifiWithdrawClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_BIFI_WITHDRAW_CLICK,
    isTestnet,
  });
};
export const eventBifiWithdrawDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_BIFI_WITHDRAW_DONE,
    isTestnet,
  });
};
export const eventEverdexClaimRewardsClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_CLAIM_REWARDS_CLICK,
    isTestnet,
  });
};
export const eventEverdexClaimRewardsDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_CLAIM_REWARDS_DONE,
    isTestnet,
  });
};
export const eventEverdexUnstakingClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_UNSTAKING_CLICK,
    isTestnet,
  });
};
export const eventEverdexUnstakingDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_UNSTAKING_DONE,
    isTestnet,
  });
};
export const eventEverdexRemoveLiquidityClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_REMOVE_LIQUIDITY_CLICK,
    isTestnet,
  });
};
export const eventEverdexRemoveLiquidityDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_REMOVE_LIQUIDITY_DONE,
    isTestnet,
  });
};
export const eventBifiDepositClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_BIFI_DEPOSIT_CLICK,
    isTestnet,
  });
};
export const eventBifiDepositDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_BIFI_DEPOSIT_DONE,
    isTestnet,
  });
};
export const eventEverdexAddLiquidityClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_ADD_LIQUIDITY_CLICK,
    isTestnet,
  });
};
export const eventEverdexAddLiquidityDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_EVERDEX_ADD_LIQUIDITY_DONE,
    isTestnet,
  });
};
export const eventMintingMaxDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_MINTING_MAX_DONE,
    isTestnet,
  });
};
export const eventAddLiquidityMaxDone = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_ADD_LIQUIDITY_MAX_DONE,
    isTestnet,
  });
};
export const eventFaucetClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_FAUCET_CLICK,
    isTestnet,
  });
};
export const eventSettingClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_SETTING_CLICK,
    isTestnet,
  });
};
export const eventDocsClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_DOCS_CLICK,
    isTestnet,
  });
};
export const eventHelpCenterClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_HELP_CENTER_CLICK,
    isTestnet,
  });
};
export const eventPolicyClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_POLICY_CLICK,
    isTestnet,
  });
};
export const eventDocsFooterClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_DOCS_FOOTER_CLICK,
    isTestnet,
  });
};
export const eventHelpCenterFooterClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_HELP_CENTER_FOOTER_CLICK,
    isTestnet,
  });
};
export const eventPolicyFooterClick = (isTestnet: boolean) => {
  event({
    ...GOOGLE_ANALYTICS_EVENT_POLICY_FOOTER_CLICK,
    isTestnet,
  });
};
