import {
  Token,
  compareLowerStr,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import IconArrow from '@/assets/icons/icon-arrow.svg';
import useEnv from '@/hooks/useEnv';
import AddTokenItem from './AddTokenItem';

interface Props {
  token?: Token;
  onTokenClick?: (token: Token) => () => void;
  onBackClick?: () => void;
}

const Wrapper = styled.div`
  position: relative;
  width: 240px;

  ul {
    margin-top: 16px;
    text-align: center;
  }

  li button {
    width: 100%;
    font-size: ${(p) => p.theme.size.md};
    ${(p) => p.theme.font.regular};
    line-height: 1.5;
  }

  li + li {
    margin-top: 16px;
  }
`;
const IconBack = styled(IconArrow)`
  transform: rotate(-270deg);
`;
const BackButton = styled.button`
  position: absolute;
  left: 0px;
`;
const Title = styled.p`
  padding-bottom: 16px;
  font-size: ${(p) => p.theme.size.md};
  ${(p) => p.theme.font.bold};
  line-height: 1.5;
  text-align: center;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default function AddDialog({
  token,
  onTokenClick: handleTokenClick,
  onBackClick: handleBackClick,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const { tokens } = useEnv();

  const currentTokens = useMemo(
    () => tokens.filter((t) => compareLowerStr(t.symbol, token?.symbol)),
    [token?.symbol, tokens]
  );

  if (!token) {
    return;
  }

  return (
    <Wrapper
      as={motion.div}
      initial={{
        x: '100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: '100%',
      }}
      {...rest}
    >
      <BackButton onClick={handleBackClick}>
        <IconBack />
      </BackButton>
      <Title>{t('network')}</Title>
      <List>
        {currentTokens.map((token, tokenIndex) => (
          <AddTokenItem
            isAddable
            token={token}
            key={tokenIndex}
            onClick={handleTokenClick?.(token)}
          />
        ))}
      </List>
    </Wrapper>
  );
}
