import {
  formatUnit,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FRAG_REWARD_POOL_GET_CURRENT_APR } from '@/configs/fragment';
import { formatNumber } from '@/libs/formatNumber';
import { bifiRewardAprAtom } from '@/store/bifiStore';
import useEnv from '../../useEnv';
import useIsBifrostChain from '../../useIsBifrostChain';

const useBifiRewardApr = () => {
  // env
  const { chainBifrost, addressBifiRewardPool } = useEnv();

  // wallet
  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  // store
  const [rewardApr, setRewardApr] = useAtom(bifiRewardAprAtom);

  // callback
  const sync = useCallback(async () => {
    try {
      const to = addressBifiRewardPool;
      const fragment = FRAG_REWARD_POOL_GET_CURRENT_APR;
      const values: ReadonlyArray<any> = [];
      const from = account;
      const defaultBlock = 'latest';

      let rewardApr = '0';

      if (isBifrostChain) {
        rewardApr = pickNumberResponse(
          await wallet.call(to, fragment, values, from, defaultBlock)
        ).multipliedBy(100);
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        rewardApr = pickNumberResponse(
          await rpcContractCall(callParams, rpcURL, defaultBlock)
        ).multipliedBy(100);
      }

      setRewardApr(formatNumber(formatUnit(rewardApr, 18)));
    } catch (error) {}
  }, [
    account,
    addressBifiRewardPool,
    chainBifrost.rpcUrls,
    isBifrostChain,
    setRewardApr,
    wallet,
  ]);

  return {
    apr: rewardApr,
    sync,
  };
};

export default useBifiRewardApr;
