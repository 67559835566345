import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IconArrow from '@/assets/icons/icon-arrow.svg';
import useEnv from '@/hooks/useEnv';
import { LanguageId } from '@/types/Language';

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const Item = styled.div<{ enable?: boolean; enableStyle?: string }>`
  color: ${(p) =>
    p.enable && p.enableStyle != 'network' && p.enableStyle != 'wallet'
      ? '#14F28A'
      : p.theme.colors.textWhite};
  padding: 16px;
  border: ${(p) => (p.enable ? '1px solid rgba(129, 141, 166, 0.40)' : '0')};
  border-radius: 16px;
  cursor: pointer;

  display: flex;
  gap: 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  &:hover,
  &:focus-visible {
    filter: brightness(0.8);
  }

  button {
    width: 100%;
    font-size: ${(p) => p.theme.size.md};
    ${(p) => p.theme.font.regular};
    line-height: 1.5;
    text-align: start;
  }

  li + li {
    margin-top: 16px;
  }
`;

const IconBack = styled(IconArrow)`
  transform: rotate(-270deg);
`;
const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  ${(p) => p.theme.font.bold};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
`;

const HeaderContainer = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
`;

interface Props {
  isShow: boolean;
  title: string;
  type: string;
  menus?: {
    label: string;
    onClick: () => void;
  }[];
  wallet?: any[];
  network?: any[];
  handleItemClick?: any;
  onBackClick: () => void;
}

function SettingDialog({
  title,
  menus,
  type,
  isShow,
  wallet,
  network,
  handleItemClick,
  onBackClick: handleBackClick,
  ...rest
}: Props) {
  const { i18n } = useTranslation();

  const { currencyId, isTestnet } = useEnv();

  const { chainId } = useWallet();

  const [enableValue, setEnableValue] = useState(0);

  if (!isShow) {
    return <></>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (type === 'language') {
      setEnableValue((i18n.language as LanguageId) === 'ko' ? 0 : 1);
    } else {
      setEnableValue(currencyId === 'krw' ? 0 : 1);
    }
  }, [chainId, currencyId, i18n.language, isTestnet, network, type]);

  return (
    <Wrapper
      as={motion.div}
      initial={{
        x: '100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: '100%',
      }}
      {...rest}
    >
      <HeaderContainer>
        <BackButton onClick={handleBackClick}>
          <IconBack />
          {title}
        </BackButton>
      </HeaderContainer>
      <ul>
        {menus &&
          menus.map(({ label, onClick: handleClick }, index) => (
            <Item key={label} enable={enableValue === index}>
              <button type="button" onClick={handleClick}>
                {label}
              </button>
            </Item>
          ))}
      </ul>
    </Wrapper>
  );
}

export default SettingDialog;
