import { ChainByAPI } from '@bifrost-platform/bifront-sdk-react-biholder';

// api id
export const TESTNET_CHAIN_API_ID_BIFROST: ChainByAPI | undefined =
  'bfctestnet';
export const TESTNET_CHAIN_API_ID_ETHEREUM: ChainByAPI | undefined = undefined;
export const TESTNET_CHAIN_API_ID_ARBITRUM: ChainByAPI | undefined = undefined;
export const TESTNET_CHAIN_API_ID_POLYGON: ChainByAPI | undefined = undefined;
export const TESTNET_CHAIN_API_ID_BSC: ChainByAPI | undefined = undefined;
export const TESTNET_CHAIN_API_ID_BASE: ChainByAPI | undefined = undefined;

export const TESTNET_CHAIN_API_IDS = [
  TESTNET_CHAIN_API_ID_BIFROST,
  TESTNET_CHAIN_API_ID_ETHEREUM,
  TESTNET_CHAIN_API_ID_ARBITRUM,
  TESTNET_CHAIN_API_ID_POLYGON,
  TESTNET_CHAIN_API_ID_BSC,
  TESTNET_CHAIN_API_ID_BASE,
];
