import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  useTransitionStyles,
  arrow,
  Side,
  FloatingArrow,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import * as React from 'react';
import styled from 'styled-components';
import { PaddingProps } from '@/types/ui';

interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  offset?: number;
}

interface TooltipStyleProps {
  $blur?: number;
  $padding?: PaddingProps;
}

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  offset: offsetAmount = 8,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const arrowRef = React.useRef<SVGSVGElement>(null);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetAmount),
      flip({
        fallbackAxisSideDirection: 'start',
        crossAxis: false,
      }),
      shift(),
      arrow({
        element: arrowRef,
        padding: 24,
      }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [open, setOpen, interactions, data]
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export function Tooltip({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <StyledTooltipTrigger
      type="button"
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </StyledTooltipTrigger>
  );
});

const TRANSFORM_MAP: Record<Side, string> = {
  top: 'translateY(8px)',
  bottom: 'translateY(-8px)',
  left: 'translateX(8px)',
  right: 'translateX(-8px)',
};

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & TooltipStyleProps
>(function TooltipContent(
  { style, children, $blur, $padding, ...props },
  propRef
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const { isMounted, styles: floatingStyles } = useTransitionStyles(
    context.context,
    {
      initial: ({ side }) => ({
        opacity: 0,
        transform: `scale(0.9) ${TRANSFORM_MAP[side]}`,
      }),
      open: {
        opacity: 1,
        transform: 'scale(1)',
      },
    }
  );

  if (!isMounted) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          zIndex: 99999,
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      >
        <StyledTooltipContent
          style={{
            ...floatingStyles,
          }}
          $blur={$blur}
          $padding={$padding}
        >
          {children}
        </StyledTooltipContent>
      </div>
    </FloatingPortal>
  );
});

export const TooltipArrow = React.forwardRef<
  SVGSVGElement,
  React.HTMLProps<SVGSVGElement>
>(function TooltipArrow({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.arrowRef, propRef]);

  return (
    <StyledFloatingArrow
      style={style}
      ref={ref}
      context={context.context}
      tipRadius={4}
      width={12}
      height={10}
    />
  );
});

const StyledTooltipContent = styled.div<{
  $blur?: number;
  $padding?: PaddingProps;
}>`
  ${(p) => p.theme.rounded.lg};
  ${(p) => p.theme.bg.grayBlue};
  backdrop-filter: blur(${(p) => p.$blur ?? 16}px);
  padding: ${(p) =>
    p.$padding
      ? typeof p.$padding === 'object'
        ? `${p.theme.size[p.$padding.y]} ${p.theme.size[p.$padding.x]}`
        : p.theme.size[p.$padding]
      : '12px 16px'};

  font-size: ${(p) => p.theme.size.xs};
  font-weight: ${(p) => p.theme.font.regular};
  line-height: ${(p) => p.theme.size.md};
`;

const StyledTooltipTrigger = styled.button`
  cursor: help;
  display: inline-flex;
`;

const StyledFloatingArrow = styled(FloatingArrow)<{
  $blur?: number;
}>`
  fill: ${(p) => p.theme.colors.grayBlue};
  backdrop-filter: blur(${(p) => p.$blur ?? 16}px);
`;
