import EverdexLpRewardPool from '@/types/EverdexLpRewardPool';
import {
  MAINNET_ADD_EVERDEX_REWARD_POOL,
  MAINNET_ADD_EVERDEX_REWARD_PROXY,
  MAINNET_ADD_EVERDEX_REWARD_STAKING_STATE,
  MAINNET_ADD_EVERDEX_REWARD_STATE,
  TESTNET_ADD_EVERDEX_REWARD_POOL,
  TESTNET_ADD_EVERDEX_REWARD_PROXY,
  TESTNET_ADD_EVERDEX_REWARD_STAKING_STATE,
  TESTNET_ADD_EVERDEX_REWARD_STATE,
} from './address';
import {
  MAINNET_TOKEN_BIFROST_BTCUSD,
  MAINNET_TOKEN_BIFROST_USDC,
  MAINNET_TOKEN_BIFROST_EVERDEX_STAKE_LP,
} from './tokens/mainnetTokens';
import {
  TESTNET_TOKEN_BIFROST_BTCUSD,
  TESTNET_TOKEN_BIFROST_USDT,
  TESTNET_TOKEN_BIFROST_EVERDEX_STAKE_LP,
} from './tokens/testnetTokens';

export const MAINNET_EVERDEX_LP_REWARD_POOL: EverdexLpRewardPool = {
  address: MAINNET_ADD_EVERDEX_REWARD_POOL,
  tokens: [MAINNET_TOKEN_BIFROST_BTCUSD, MAINNET_TOKEN_BIFROST_USDC],
  lpToken: MAINNET_TOKEN_BIFROST_EVERDEX_STAKE_LP,
  rewardContract: {
    lpTokenAddress: MAINNET_TOKEN_BIFROST_EVERDEX_STAKE_LP.address,
    proxyAddress: MAINNET_ADD_EVERDEX_REWARD_PROXY,
    stakingStateAddress: MAINNET_ADD_EVERDEX_REWARD_STAKING_STATE,
    rewardStateAddresses: [MAINNET_ADD_EVERDEX_REWARD_STATE],
  },
};
export const TESTNET_EVERDEX_LP_REWARD_POOL: EverdexLpRewardPool = {
  address: TESTNET_ADD_EVERDEX_REWARD_POOL,
  tokens: [TESTNET_TOKEN_BIFROST_BTCUSD, TESTNET_TOKEN_BIFROST_USDT],
  lpToken: TESTNET_TOKEN_BIFROST_EVERDEX_STAKE_LP,
  rewardContract: {
    lpTokenAddress: TESTNET_TOKEN_BIFROST_EVERDEX_STAKE_LP.address,
    proxyAddress: TESTNET_ADD_EVERDEX_REWARD_PROXY,
    stakingStateAddress: TESTNET_ADD_EVERDEX_REWARD_STAKING_STATE,
    rewardStateAddresses: [TESTNET_ADD_EVERDEX_REWARD_STATE],
  },
};
