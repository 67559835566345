// erc20
export const FRAG_SYMBOL = 'function symbol() view returns (string)';
export const FRAG_DECIMALS = 'function decimals() view returns (uint8)';
export const FRAG_BALANCE =
  'function balanceOf(address) view returns (uint256)';
export const FRAG_ALLOWANCE =
  'function allowance(address,address) view returns (uint256)';
export const FRAG_TOTAL_SUPPLY =
  'function totalSupply() view returns (uint256)';

// reward pool
export const FRAG_REWARD_POOL_GET_USER_REWARD_AMOUNT =
  'function getUserRewardAmount(address) view returns (uint256)';
export const FRAG_REWARD_POOL_GET_CURRENT_APR =
  'function getCurrentAPR() view returns (uint256)';

// lp reward
export const FRAG_LP_REWARD_GET_RESERVES =
  'function getReservesByCurve(address) view returns (address[],uint256[])';
export const FRAG_LP_REWARD_REWARD_RATE =
  'function rewardRate(address) view returns (uint)';
export const FRAG_LP_REWARD_TOTAL_SUPPLY =
  'function totalSupply() view returns (uint)';
export const FRAG_LP_REWARD_BALANCE_OF =
  'function balanceOf(address) view returns (uint)';
export const FRAG_LP_REWARD_EARNED =
  'function earned(address,address) view returns (uint)';
export const FRAG_LP_REWARD_PERIOD_FINISH =
  'function periodFinish(address) view returns (uint)';

// send
export const FRAG_APPROVE = 'function approve(address,uint256)';
export const FRAG_DEPOSIT = 'function deposit(uint256,bool)';
export const FRAG_WITHDRAW = 'function withdraw(uint256,bool)';
export const FRAG_BORROW = 'function borrow(uint256,bool)';
export const FRAG_REPAY = 'function repay(uint256,bool)';
export const FRAG_REQUEST =
  'function request(((bytes4,bytes16),(bytes32,bytes32,address,address,uint256,bytes)))';
export const FRAG_REWARD_POOL_CLAIM = 'function claimReward()';
export const FRAG_EVERDEX_ADD_LIQUIDITY_STABLE =
  'function addLiquidityStableSwap(address,address[],uint256[],uint256[],address,uint256) view returns (uint256[],uint256)';
export const FRAG_EVERDEX_REMOVE_LIQUIDITY_STABLE =
  'function removeLiquidityStableSwap(address,uint,uint[],address,uint) view returns (uint256[])';
export const FRAG_EVERDEX_LP_REWARD_STAKE = 'function stake(uint)';
export const FRAG_EVERDEX_LP_REWARD_EXIT = 'function exit()';
export const FRAG_EVERDEX_LP_REWARD_GET_REWARD = 'function getReward()';

// fragment map
export const ACTION_FRAGMENT_MAP = {
  deposit: FRAG_DEPOSIT,
  withdraw: FRAG_WITHDRAW,
  borrow: FRAG_BORROW,
  repay: FRAG_REPAY,
  'bifi:deposit': FRAG_DEPOSIT,
  'bifi:withdraw': FRAG_WITHDRAW,
  'bifi:rewardPoolClaim': FRAG_REWARD_POOL_CLAIM,
  mobile: '',
  'everdex:addLiquidity': FRAG_EVERDEX_ADD_LIQUIDITY_STABLE,
  'everdex:removeLiquidity': FRAG_EVERDEX_REMOVE_LIQUIDITY_STABLE,
  'everdex:lpStake': FRAG_EVERDEX_LP_REWARD_STAKE,
  'everdex:lpExit': FRAG_EVERDEX_LP_REWARD_EXIT,
  'everdex:lpClaim': FRAG_EVERDEX_LP_REWARD_GET_REWARD,
};
