import { motion } from 'framer-motion';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SettingMenu } from '@/components/GlobalSetting';
import PanelSettingDialog from '@/components/GlobalSetting/PanelSettingDialog';
import { LINK_DOCS_EN, LINK_DOCS_KO, LINK_HELP_CENTER } from '@/configs/link';
import { PATH_TERMS } from '@/configs/path';
import useEnv from '@/hooks/useEnv';
import {
  eventDocsClick,
  eventHelpCenterClick,
  eventPolicyClick,
} from '@/libs/gtag';
import { CurrencyId } from '@/types/Currency';
import { LanguageId } from '@/types/Language';
import { FlexBox } from '../atom/FlexBox';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;

  span {
    opacity: 0.4;
  }
`;

const FooterWrapper = styled(FlexBox)`
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  font-size: 16px;

  border-top: 1px solid rgba(129, 141, 166, 0.4);
  border-bottom: 1px solid rgba(129, 141, 166, 0.4);
`;

const PanelTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${(p) => p.theme.colors.textWhite};
  opacity: 0.4;
`;

interface Props {
  subPanel: React.Dispatch<React.SetStateAction<string>>;
  currency: CurrencyId;
  handleModal: (mode: boolean) => void;
}

export default function HeaderMenuPanel(props: Props) {
  const { i18n } = useTranslation();

  const { isTestnet } = useEnv();

  const [, setMenu] = useState<SettingMenu>('');

  const docsLink = useMemo(
    () => (i18n.language?.startsWith('ko') ? LINK_DOCS_KO : LINK_DOCS_EN),
    [i18n]
  );

  const handleDocsClick = useCallback(
    () => eventDocsClick(isTestnet),
    [isTestnet]
  );
  const handleHelpCenterClick = useCallback(
    () => eventHelpCenterClick(isTestnet),
    [isTestnet]
  );
  const handlePolicyClick = useCallback(
    () => eventPolicyClick(isTestnet),
    [isTestnet]
  );
  const handleMenuClick = useCallback(
    (menu: SettingMenu) => () => {
      setMenu(menu);
      props.subPanel(menu);
    },
    [props]
  );

  return (
    <Wrapper
      as={motion.div}
      initial={{
        x: '-100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: '-100%',
      }}
    >
      <FlexBox $flex $direction="column" $gap="xxs">
        <PanelTitle>Setting</PanelTitle>
        <PanelSettingDialog
          isShow
          currency={props.currency}
          language={i18n.language as LanguageId}
          onMenuClick={handleMenuClick}
        />
      </FlexBox>
      <FlexBox $flex $direction="column" $gap="xl">
        <FooterWrapper $flex $gap="md" $direction="column">
          <a target="_blank" href={docsLink} onClick={handleDocsClick}>
            Docs
          </a>
          <a
            target="_blank"
            href={LINK_HELP_CENTER}
            onClick={handleHelpCenterClick}
          >
            Help Center
          </a>
          <Link href={PATH_TERMS} onClick={handlePolicyClick}>
            Privacy Policy & Terms of Use
          </Link>
        </FooterWrapper>
        <span>&copy; 2024 Bifrosoft PTE LTD</span>
      </FlexBox>
    </Wrapper>
  );
}
