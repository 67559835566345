import { Token } from '@bifrost-platform/bifront-sdk-react-biholder';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import ActionType from '@/types/action/ActionType';
import { CurrencyId } from '@/types/env/Currency';
import IsTestnet from '@/types/env/IsTestnet';
import { LanguageId } from '@/types/env/Language';

export type IsShow = boolean;
export interface IsShowDict {
  isShow?: IsShow;
}
export interface SnackbarProps extends IsShowDict {
  message?: string;
}
export interface DialogActionProps extends IsShowDict {
  token?: Token;
  amount?: string;
  tokens?: Token[];
  amounts?: string[];
  explorerUrl?: string;
  type?: ActionType;
}

export const appInitAtom = atom(false);
export const languageIdAtom = atomWithStorage<LanguageId>('language', 'ko');
export const currencyIdAtom = atomWithStorage<CurrencyId>('currency', 'usd');
export const isTestnetAtom = atomWithStorage<IsTestnet>('isTestnet', false);
export const isRiskDisclaimerDisableAtom = atomWithStorage<boolean>(
  'isRiskDisclaimerDisable',
  false
);

export const snackbarAtom = atom<SnackbarProps>({});
export const isShowConnectWalletAtom = atom<IsShow>(false);

export const popoverConnectWalletAtom = atom<IsShow>(false);
export const popoverWalletAtom = atom<IsShow>(false);

export const dialogActionAtom = atom<DialogActionProps>({});
