import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import LANGUAGES from '@/configs/languages';
import { languageIdAtom } from '@/store/uiStore';
import { LanguageId } from '@/types/env/Language';
import { setStorageItem } from '@/utils/storage';

const STORAGE_ID_LANGUAGE = 'language';

const useLanguage = () => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const [languageId, setLanguageId] = useAtom(languageIdAtom);

  const changeLanguage = useCallback(
    async (newLanguage: LanguageId) => {
      if (!LANGUAGES.find((language) => language.id === newLanguage)) {
        return;
      }

      await router.replace({ pathname, query }, asPath, {
        locale: newLanguage,
      });
    },
    [asPath, pathname, query, router]
  );
  const sync = useCallback(() => {
    if (locale && LANGUAGES.find((language) => language.id === locale)) {
      setLanguageId(locale as LanguageId);
      setStorageItem(STORAGE_ID_LANGUAGE, locale);
    }
  }, [locale, setLanguageId]);

  return { languageId, locale, changeLanguage, sync };
};

export default useLanguage;
