import { atom } from 'jotai';
import LendingStatus from '@/types/LendingStatus';

// lending
export const bifiLendingStatusesAtom = atom<LendingStatus[]>([]);
export const isLoadingBifiLendingStatusesAtom = atom(false);

// reward
export const bifiRewardAmountAtom = atom('0');
export const bifiRewardAprAtom = atom('0');
export const isLoadingBifiRewardAtom = atom(false);
