import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { isLoadingBifiRewardAtom } from '@/store/bifiStore';
import useBifiRewardAmount from './useBifiRewardAmount';
import useBifiRewardApr from './useBifiRewardApr';

const useBifiReward = () => {
  // reward amount
  const { amount: rewardAmount, sync: syncRewardAmount } =
    useBifiRewardAmount();

  // reward apr
  const { apr: rewardApr, sync: syncRewardApr } = useBifiRewardApr();

  // store
  const [isLoadingReward, setIsLoadingRward] = useAtom(isLoadingBifiRewardAtom);

  // callback
  const sync = useCallback(async () => {
    setIsLoadingRward(true);

    try {
      await Promise.all([syncRewardAmount(), syncRewardApr()]);
    } catch (error) {}

    setIsLoadingRward(false);
  }, [setIsLoadingRward, syncRewardAmount, syncRewardApr]);

  return {
    amount: rewardAmount,
    apr: rewardApr,
    isLoading: isLoadingReward,
    sync,
  };
};

export default useBifiReward;
