export const makeStorageKey = (key: string, address?: string) =>
  `${address ? `${address}-` : ''}${key}`;
export const getStorageItemString = (key: string, address?: string) =>
  localStorage.getItem(makeStorageKey(key, address)) || undefined;
export const getStorageItem = <T>(key: string, address?: string) => {
  let result: undefined | T;
  const str = getStorageItemString(key, address);
  try {
    result = str ? (JSON.parse(str) as T) : undefined;
  } catch (error) {}
  return result;
};
export const setStorageItemString = (
  key: string,
  value: string,
  address?: string
) => localStorage.setItem(makeStorageKey(key, address), value);
export const setStorageItem = (key: string, value: any, address?: string) =>
  setStorageItemString(key, JSON.stringify(value), address);
export const removeStorageItem = (key: string, address?: string) =>
  localStorage.removeItem(makeStorageKey(key, address));
export const clearStorage = () => localStorage.clear();
