import {
  formatUnit,
  isValidAddress,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FRAG_LP_REWARD_REWARD_RATE } from '@/configs/fragment';
import useEnv from '@/hooks/useEnv';
import useIsBifrostChain from '@/hooks/useIsBifrostChain';
import { formatNumber } from '@/libs/formatNumber';
import {
  everdexLpRewardRewardRateAtom,
  everdexIsLoadingLpRewardRewardRateAtom,
} from '@/store/everdexStore';

const useEverdexLpRewardRewardRate = (
  proxyAddress?: string,
  rewardStateAddress?: string
) => {
  const { chainBifrost } = useEnv();

  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  const [rewardRate, setRewardRate] = useAtom(everdexLpRewardRewardRateAtom);
  const [isLoading, setIsLoading] = useAtom(
    everdexIsLoadingLpRewardRewardRateAtom
  );

  const sync = useCallback(async () => {
    if (
      !(
        proxyAddress &&
        rewardStateAddress &&
        isValidAddress(proxyAddress) &&
        isValidAddress(rewardStateAddress)
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const to = proxyAddress;
      const fragment = FRAG_LP_REWARD_REWARD_RATE;
      const values: ReadonlyArray<any> = [rewardStateAddress];
      const from = account;
      const defaultBlock = 'latest';

      let rewardRate = '0';

      if (isBifrostChain) {
        rewardRate = pickNumberResponse(
          await wallet.call(to, fragment, values, from, defaultBlock)
        );
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        rewardRate = pickNumberResponse(
          await rpcContractCall(callParams, rpcURL, defaultBlock)
        );
      }

      setRewardRate(formatNumber(formatUnit(rewardRate, 18)));
    } catch (error) {}

    setIsLoading(false);
  }, [
    account,
    chainBifrost.rpcUrls,
    isBifrostChain,
    proxyAddress,
    rewardStateAddress,
    setIsLoading,
    setRewardRate,
    wallet,
  ]);

  return { rewardRate, isLoading, sync };
};

export default useEverdexLpRewardRewardRate;
