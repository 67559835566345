// docs
export const LINK_DOCS_EN = 'https://docs.bifrostnetwork.com/eng.btcfi.one/';
export const LINK_DOCS_KO = 'https://docs.bifrostnetwork.com/btcfi.one/';
export const LINK_HELP_CENTER =
  'https://pi-lab.atlassian.net/servicedesk/customer/portal/18';
// app
export const LINK_BIFI_LANDING = 'https://bifi.finance';
export const LINK_BDAO = 'https://www.withtally.com/governance/bifi';
export const LINK_GBIFI_SWAP = 'https://gov.bifi.finance';
// family
export const LINK_BIFROST = 'https://bifrostnetwork.com/';
export const LINK_GITHUB = 'https://github.com/bifrost-platform';
export const LINK_DISCORD = 'https://discord.gg/bifrostcity';

export const LINK_FAUCET = 'https://bifrostnetwork.com/faucet';
export const LINK_FEEDBACK = 'https://discord.gg/bifrostcity';
