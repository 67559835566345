import useEnv from './useEnv';
import useIsCorrectChain from './useIsCorrectChain';

const useIsBifrostChain = () => {
  const { chainBifrost } = useEnv();

  const isCorrectChain = useIsCorrectChain(chainBifrost.id);

  return isCorrectChain;
};

export default useIsBifrostChain;
