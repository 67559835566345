export const DASHBOARD_WIDTH_BREAKPOINT = 1840;
export const WIDTH_BREAKPOINT = 1600;
export const WIDTH_MIN = 1080;

export const MEDIA_QUERY_DASHBOARD_BREAKPOINT = `max-width: ${DASHBOARD_WIDTH_BREAKPOINT}px`;
export const MEDIA_QUERY_BREAKPOINT = `max-width: ${WIDTH_BREAKPOINT}px`;
export const MEDIA_QUERY_MIN = `min-width: ${WIDTH_MIN}px`;

export const MEDIA_QUERY_WIDTH_DESKTOP_XL = 'max-width: 1600px';
export const MEDIA_QUERY_WIDTH_DESKTOP_L = 'max-width: 1480px';
export const MEDIA_QUERY_WIDTH_DESKTOP_1200 = 'max-width: 1200px';
export const MEDIA_QUERY_WIDTH_DESKTOP_M = 'max-width: 1080px';
export const MEDIA_QUERY_WIDTH_DESKTOP_S = 'max-width: 744px';
export const MEDIA_QUERY_WIDTH_DESKTOP_MOBILE = 'max-width: 743px';
export const MEDIA_QUERY_WIDTH_MOBILE_M = 'max-width: 430px';
export const MEDIA_QUERY_WIDTH_MOBILE_S = 'max-width: 375px';
