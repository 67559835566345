import {
  Chain,
  ChainByAPI,
  ChainId,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { EVERY_CHAINS, NETWORK_CHAIN_ID_SET } from '@/configs/chains';

export const getChainByApiId = (apiId: ChainByAPI): Chain | undefined =>
  EVERY_CHAINS.find((chain) => chain.apiId === apiId);
export const getChain = (id: ChainId): Chain | undefined =>
  EVERY_CHAINS.find((chain) => chain.id === id);

export const getRpcUrls = (id: ChainId): string[] =>
  getChain(id)?.rpcUrls || [];
export const getTxScanner = (id: ChainId): string =>
  `${getChain(id)?.explorerUrl || ''}tx/`;
export const getScanner = (id: ChainId): string =>
  getChain(id)?.explorerUrl || '';
export const getChainName = (id: ChainId): string => getChain(id)?.name || '';

export const getMainneChain = (chainId?: ChainId) =>
  NETWORK_CHAIN_ID_SET.find(
    (chainIdSet) => chainIdSet.indexOf(chainId) >= 0
  )?.[0];
