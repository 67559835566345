import { useEffect } from 'react';

interface Props {
  isEnable: boolean;
}

export default function useScrollLock({ isEnable }: Props) {
  useEffect(() => {
    if (isEnable) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [isEnable]);
}
