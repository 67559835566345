// name
export const MAINNET_CHAIN_NAME_BIFROST = 'Bifrost';
export const MAINNET_CHAIN_NAME_ETHEREUM = 'Ethereum';
export const MAINNET_CHAIN_NAME_ARBITRUM = 'Arbitrum';
export const MAINNET_CHAIN_NAME_POLYGON = 'Polygon';
export const MAINNET_CHAIN_NAME_BSC = 'BNB';
export const MAINNET_CHAIN_NAME_BASE = 'Base';

export const MAINNET_CHAIN_NAMES = [
  MAINNET_CHAIN_NAME_BIFROST,
  MAINNET_CHAIN_NAME_ETHEREUM,
  MAINNET_CHAIN_NAME_ARBITRUM,
  MAINNET_CHAIN_NAME_POLYGON,
  MAINNET_CHAIN_NAME_BSC,
  MAINNET_CHAIN_NAME_BASE,
];
