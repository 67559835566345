import {
  WalletConstructorProps,
  WalletProvider,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { createContext, PropsWithChildren } from 'react';
import BiholderProviderWithWallet from './BiholderProviderWithWallet';
import InitializerProvider from './InitializerProvider';

export const Context = createContext({});

export default function BifrontSdkProvider({
  walletProps,
  children,
}: PropsWithChildren & {
  walletProps?: WalletConstructorProps & {
    providerCheckTerm?: number | undefined;
  };
}) {
  return (
    <Context.Provider value={{}}>
      <WalletProvider props={walletProps}>
        <BiholderProviderWithWallet>
          <InitializerProvider>{children}</InitializerProvider>
        </BiholderProviderWithWallet>
      </WalletProvider>
    </Context.Provider>
  );
}
