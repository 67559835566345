import {
  BN,
  ChainId,
  Token,
  compareLowerStr,
  useAssetInfoMap,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  isNormalPositive,
  isValidAddress,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import {
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_WBTC,
} from '@/configs/tokens/tokenSymbols';
import { makeActionSendData, sendWithSendParams } from '@/libs/contract/wallet';
import { formatNumber } from '@/libs/formatNumber';
import log from '@/libs/log';
import {
  btcfiLendingStatusesAtom,
  isLoadingBtcfiLendingStatusesAtom,
} from '@/store/btcfiStore';
import useAvailableChains from './useAvailableChains';
import useAvailableHandlers from './useAvailableHandlers';
import useAvailablePairs from './useAvailablePairs';
import useLendingStatuses from './useLendingStatuses';
import useGetCurrencyString from '../store/useGetCurrencyString';
import useEnv from '../useEnv';
import { getIsCorrectChain } from '../useIsCorrectChain';

const useBtcfi = () => {
  // env
  const { networkApiPath, chainBifrost, tokensBifrostBtc } = useEnv();

  // wallet
  const { wallet, account } = useWallet();

  // currency
  const { krwByUsd } = useAssetInfoMap();
  const { currencyId, getAmountValue } = useGetCurrencyString();

  // store
  const [statuses, setStatuses] = useAtom(btcfiLendingStatusesAtom);
  const [isLoadingLendingStatuses, setIsLoadingLendingStatuses] = useAtom(
    isLoadingBtcfiLendingStatusesAtom
  );

  // available chains
  const { chains, isLoading: isLoadingChains } = useAvailableChains();

  // available handlers
  const { handlers, isLoading: isLoadingHandlers } =
    useAvailableHandlers('btcfi');

  // available pairs
  const {
    tokens,
    symbols,
    pairs,
    isLoading: isLoadingPairs,
  } = useAvailablePairs();

  // lending statuses
  const { sync: syncLendingStatuses } = useLendingStatuses(
    'btcfi',
    { handlers },
    {
      setStatuses,
      setIsLoading: setIsLoadingLendingStatuses,
    }
  );

  // memo
  const statusesWithPrice = useMemo(
    () =>
      statuses.map((status) => ({
        ...status,
        limitOfAction: formatNumber(
          new BN(status?.limitOfAction ?? '0').multipliedBy(
            currencyId === 'krw' ? krwByUsd ?? 1 : 1
          )
        ),
        price: formatNumber(
          new BN(status?.price ?? '0').multipliedBy(
            currencyId === 'krw' ? krwByUsd ?? 1 : 1
          )
        ),
      })),
    [currencyId, krwByUsd, statuses]
  );
  const statusBtcusd = useMemo(
    () =>
      statusesWithPrice.find((status) =>
        compareLowerStr(status.symbol, TOKEN_SYMBOL_BTCUSD)
      ),
    [statusesWithPrice]
  );
  const assetSets = useMemo(
    () =>
      tokensBifrostBtc.map((token) => {
        const { symbol } = token;
        const status = statusesWithPrice.find((status) =>
          compareLowerStr(status.symbol, symbol)
        );
        const depositAmount = status?.deposit?.amount ?? '0';
        const maxWithdrawAmount = status?.maxAmount?.withdraw ?? '0';
        const collateralRate = status?.borrow?.limit ?? '0';
        const collateralAmount = formatNumber(
          new BN(depositAmount).multipliedBy(collateralRate).div(100)
        );
        const price = status?.price;

        return {
          symbol,
          token,
          depositAmount,
          maxWithdrawAmount,
          collateralRate,
          collateralAmount,
          price,
        };
      }),
    [statusesWithPrice, tokensBifrostBtc]
  );
  const priceWbtc = useMemo(
    () =>
      assetSets.find(({ symbol }) => compareLowerStr(symbol, TOKEN_SYMBOL_WBTC))
        ?.price ?? '0',
    [assetSets]
  );
  const priceBtcb = useMemo(
    () =>
      assetSets.find(({ symbol }) => compareLowerStr(symbol, TOKEN_SYMBOL_BTCB))
        ?.price ?? '0',
    [assetSets]
  );
  const priceBtcusd = useMemo(
    () => statusBtcusd?.price ?? '0',
    [statusBtcusd?.price]
  );
  const depositAmountValue = useMemo(
    () =>
      formatNumber(
        assetSets.reduce(
          (pre, cur) => pre.plus(getAmountValue(cur.depositAmount, cur.price)),
          new BN(0)
        )
      ),
    [assetSets, getAmountValue]
  );
  const collateralAmountValue = useMemo(
    () =>
      formatNumber(
        assetSets.reduce(
          (pre, cur) =>
            pre.plus(getAmountValue(cur.collateralAmount, cur.price)),
          new BN(0)
        )
      ),
    [assetSets, getAmountValue]
  );
  const borrowAmount = useMemo(
    () =>
      isNormalPositive(statusBtcusd?.borrow?.amount, true)
        ? statusBtcusd?.borrow?.amount ?? '0'
        : '0',
    [statusBtcusd?.borrow?.amount]
  );
  const borrowAmountValue = useMemo(
    () => getAmountValue(borrowAmount, priceBtcusd),
    [borrowAmount, getAmountValue, priceBtcusd]
  );
  const ltv = useMemo(
    () =>
      formatNumber(
        isNormalPositive(depositAmountValue, true)
          ? new BN(borrowAmountValue).div(depositAmountValue).multipliedBy(100)
          : '0'
      ),
    [borrowAmountValue, depositAmountValue]
  );
  const maxBorrowAmountValue = useMemo(
    () =>
      formatNumber(
        isNormalPositive(statusBtcusd?.maxAmount?.borrow, true)
          ? statusBtcusd?.maxAmount?.borrow ?? '0'
          : '0'
      ),
    [statusBtcusd?.maxAmount?.borrow]
  );
  const borrowableAmountValue = useMemo(
    () => formatNumber(new BN(collateralAmountValue).minus(borrowAmountValue)),
    [borrowAmountValue, collateralAmountValue]
  );
  const isWarningLtv = useMemo(
    () => isNormalPositive(ltv, true) && new BN(ltv).gte(80),
    [ltv]
  );
  const isLoading = useMemo(
    () =>
      isLoadingChains ||
      isLoadingHandlers ||
      isLoadingPairs ||
      isLoadingLendingStatuses,
    [
      isLoadingChains,
      isLoadingHandlers,
      isLoadingLendingStatuses,
      isLoadingPairs,
    ]
  );

  // callback
  const deposit = useCallback(
    async ({
      token,
      amount,
      isCrossChain,
      toAddress,
    }: {
      token?: Token;
      amount?: string;
      isCrossChain?: boolean;
      toAddress?: string;
    }) => {
      log('deposit', token, amount, toAddress);

      if (
        !(
          token &&
          amount &&
          toAddress &&
          chainBifrost &&
          account &&
          isNormalPositive(token.chainId, true) &&
          isValidAddress(token.address) &&
          isNormalPositive(amount, true) &&
          isValidAddress(toAddress) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, token.chainId)
        )
      ) {
        throw new Error('arguments error');
      }

      const { encodedParams, sendParams } = await makeActionSendData({
        actionType: 'deposit',
        tokenAddress: token.address,
        from: account,
        to: toAddress,
        unknwonNumberValue: amount,
        isCrossChain,
        chainId: token.chainId,
        chainIdBifrost: chainBifrost.id,
        networkApiPath,
      });

      log('deposit', encodedParams, sendParams);

      return await sendWithSendParams(wallet, sendParams);
    },
    [account, chainBifrost, networkApiPath, wallet]
  );
  const withdraw = useCallback(
    async ({
      token,
      dstChainId,
      amount,
      isCrossChain,
      toAddress,
    }: {
      token?: Token;
      dstChainId?: ChainId;
      amount?: string;
      isCrossChain?: boolean;
      toAddress?: string;
    }) => {
      log('withdraw', token, amount, toAddress);

      if (
        !(
          token &&
          dstChainId &&
          amount &&
          toAddress &&
          chainBifrost &&
          account &&
          isNormalPositive(token.chainId, true) &&
          isValidAddress(token.address) &&
          isNormalPositive(dstChainId, true) &&
          isNormalPositive(amount, true) &&
          isValidAddress(toAddress) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, token.chainId)
        )
      ) {
        throw new Error('arguments error');
      }

      const { encodedParams, sendParams } = await makeActionSendData({
        actionType: 'withdraw',
        tokenAddress: token.address,
        from: account,
        to: toAddress,
        unknwonNumberValue: amount,
        isCrossChain,
        chainId: dstChainId,
        chainIdBifrost: chainBifrost.id,
        networkApiPath,
      });

      log('withdraw', encodedParams, sendParams);

      return await sendWithSendParams(wallet, sendParams);
    },
    [account, chainBifrost, networkApiPath, wallet]
  );
  const borrow = useCallback(
    async ({
      token,
      amount,
      toAddress,
    }: {
      token?: Token;
      amount?: string;
      toAddress?: string;
    }) => {
      log('borrow', token, amount, toAddress);

      if (
        !(
          token &&
          amount &&
          toAddress &&
          chainBifrost &&
          account &&
          isNormalPositive(token.chainId, true) &&
          isValidAddress(token.address) &&
          isNormalPositive(amount, true) &&
          isValidAddress(toAddress) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, token.chainId)
        )
      ) {
        throw new Error('arguments error');
      }

      const { encodedParams, sendParams } = await makeActionSendData({
        actionType: 'borrow',
        tokenAddress: token.address,
        from: account,
        to: toAddress,
        unknwonNumberValue: amount,
        isCrossChain: false,
        chainId: token.chainId,
        chainIdBifrost: chainBifrost.id,
        networkApiPath,
      });

      log('borrow', encodedParams, sendParams);

      return await sendWithSendParams(wallet, sendParams);
    },
    [account, chainBifrost, networkApiPath, wallet]
  );
  const repay = useCallback(
    async ({
      token,
      amount,
      toAddress,
    }: {
      token?: Token;
      amount?: string;
      toAddress?: string;
    }) => {
      log('repay', token, amount, toAddress);

      if (
        !(
          token &&
          amount &&
          toAddress &&
          chainBifrost &&
          account &&
          isNormalPositive(token.chainId, true) &&
          isValidAddress(token.address) &&
          isNormalPositive(amount, true) &&
          isValidAddress(toAddress) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, token.chainId)
        )
      ) {
        throw new Error('arguments error');
      }

      const { encodedParams, sendParams } = await makeActionSendData({
        actionType: 'repay',
        tokenAddress: token.address,
        from: account,
        to: toAddress,
        unknwonNumberValue: amount,
        isCrossChain: false,
        chainId: token.chainId,
        chainIdBifrost: chainBifrost.id,
        networkApiPath,
      });

      log('repay', encodedParams, sendParams);

      return await sendWithSendParams(wallet, sendParams);
    },
    [account, chainBifrost, networkApiPath, wallet]
  );
  const sync = useCallback(async () => {
    try {
      await Promise.all([syncLendingStatuses()]);
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncLendingStatuses]);

  // effect
  useEffect(() => {
    if (!account) {
      setStatuses([]);
    }
  }, [account, setStatuses]);

  // return
  return {
    tokens,
    symbols,
    pairs,
    handlers,
    chains,
    statuses: statusesWithPrice,
    statusBtcusd,
    assetSets,
    priceWbtc,
    priceBtcb,
    priceBtcusd,
    depositAmountValue,
    collateralAmountValue,
    borrowAmount,
    borrowAmountValue,
    ltv,
    maxBorrowAmountValue,
    borrowableAmountValue,
    isWarningLtv,
    isLoading,
    deposit,
    withdraw,
    borrow,
    repay,
    sync,
  };
};

export default useBtcfi;
