import '@/styles/globals.css';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import Layout from '@/components/Layout';
import LayoutWithoutSidebar from '@/components/LayoutWithoutSidebar';
import Cover from '@/components/atom/Cover';
import {
  PATH_DASHBOARD,
  PATH_EARN,
  PATH_DIALOGS,
  PATH_TERMS,
} from '@/configs/path';
import WALLET_CONSTRUCTOR_PROPS from '@/configs/walletConstructorProps';
import BifrontSdkProvider from '@/hooks/bifrontSdk/BifrontSdkProvider';
import { defaultTheme } from '@/styles/theme';
import nextI18nextConfig from '../next-i18next.config';

const pageComponentsWithLayout = [
  PATH_DASHBOARD,
  PATH_EARN,
  PATH_DIALOGS,
  PATH_TERMS,
];

function App({ Component, pageProps, router: appRouter }: AppProps) {
  const router = useRouter();

  const pageKey = router.asPath;

  const LayoutComponent = pageComponentsWithLayout.includes(appRouter.pathname)
    ? Layout
    : LayoutWithoutSidebar;

  return (
    <>
      <SWRConfig>
        <BifrontSdkProvider walletProps={WALLET_CONSTRUCTOR_PROPS}>
          <StyleSheetManager enableVendorPrefixes>
            <ThemeProvider theme={defaultTheme}>
              <MotionConfig
                transition={{
                  type: 'spring',
                  mass: 1,
                  damping: 26,
                  stiffness: 170,
                }}
              >
                <LayoutComponent>
                  <Cover />
                  <AnimatePresence mode="popLayout">
                    <Component key={pageKey} {...pageProps} />
                  </AnimatePresence>
                </LayoutComponent>
              </MotionConfig>
            </ThemeProvider>
          </StyleSheetManager>
        </BifrontSdkProvider>
      </SWRConfig>
    </>
  );
}

export default appWithTranslation(App, nextI18nextConfig);
