import { BN } from '@bifrost-platform/bifront-sdk-react-biholder';
import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useMemo } from 'react';
import useGetCurrencyString from '@/hooks/store/useGetCurrencyString';
import useEnv from '@/hooks/useEnv';
import { formatNumber } from '@/libs/formatNumber';
import useEverdexLpRewardRewardRate from './useEverdexLpRewardRewardRate';
import useEverdexLpRewardTotalSupply from './useEverdexLpRewardTotalSupply';

const useEverdexLpRewardApr = (
  proxyAddress?: string,
  rewardStateAddress?: string
) => {
  // env
  const { tokenBifrostBfc, tokenBifrostEverdexStakeLp } = useEnv();

  // price
  const { getPrice } = useGetCurrencyString();

  // reward contract
  const {
    totalSupply,
    isLoading: isLoadingTotalSupply,
    sync: syncTotalSupply,
  } = useEverdexLpRewardTotalSupply(proxyAddress);
  const {
    rewardRate,
    isLoading: isLoadingRewardRate,
    sync: syncRewardRate,
  } = useEverdexLpRewardRewardRate(proxyAddress, rewardStateAddress);

  // memo
  const rewardPrice = useMemo(
    () => getPrice(tokenBifrostBfc),
    [getPrice, tokenBifrostBfc]
  );
  const lpPrice = useMemo(
    () => getPrice(tokenBifrostEverdexStakeLp),
    [getPrice, tokenBifrostEverdexStakeLp]
  );
  const totalSupplyValue = useMemo(
    () => new BN(totalSupply ?? 0).multipliedBy(lpPrice ?? 0),
    [lpPrice, totalSupply]
  );
  const rewardAmountPerYear = useMemo(
    () => new BN(rewardRate ?? 0).multipliedBy(86400 * 365),
    [rewardRate]
  );
  const rewardValuePerYear = useMemo(
    () =>
      rewardAmountPerYear.multipliedBy(
        isNormalPositive(rewardPrice, true) ? rewardPrice ?? 0 : 0
      ),
    [rewardAmountPerYear, rewardPrice]
  );
  const apr = useMemo(
    () =>
      formatNumber(
        rewardValuePerYear
          .div(isNormalPositive(totalSupplyValue, true) ? totalSupplyValue : 0)
          .multipliedBy(100)
      ),
    [rewardValuePerYear, totalSupplyValue]
  );
  const isLoading = useMemo(
    () => isLoadingTotalSupply || isLoadingRewardRate,
    [isLoadingRewardRate, isLoadingTotalSupply]
  );

  // callback
  const sync = useCallback(() => {
    syncTotalSupply();
    syncRewardRate();
  }, [syncRewardRate, syncTotalSupply]);

  return { totalSupply, rewardRate, apr, isLoading, sync };
};

export default useEverdexLpRewardApr;
