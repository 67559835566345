import Currency from '@/types/env/Currency';

export const CURRENCY_USD: Currency = {
  id: 'usd',
  title: 'USD',
};
export const CURRENCY_KRW: Currency = {
  id: 'krw',
  title: 'KRW',
};

const CURRENCIES: Currency[] = [CURRENCY_USD, CURRENCY_KRW];

export default CURRENCIES;
