import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'next-i18next';
import { ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import IconFaucet from '@/assets/icons/icon-faucet.svg';
import { LINK_FAUCET } from '@/configs/link';
import { MEDIA_QUERY_BREAKPOINT } from '@/configs/mediaQuery';
import useEnv from '@/hooks/useEnv';
import { eventFaucetClick } from '@/libs/gtag';
import AddToken from './AddToken';
import ConnectWallet from './ConnectWallet';
import GlobalSetting from './GlobalSetting';
import Sidebar from './Sidebar';
import Banner from './atom/Banner';

const MainBG = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  background: radial-gradient(
    80.51% 76.77% at 100% 0%,
    #1f3da1 0%,
    #02021c 100%
  );
`;

const StyledLayout = styled.div`
  display: flex;
  min-width: 1024px;
  max-width: 1920px;
  margin: 0 auto;

  & > div {
    width: 100%;
    min-width: 1264px;
    padding: 64px 80px 64px 40px;
  }

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    & > div {
      min-width: auto;
    }
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    font-size: ${(p) => p.theme.size.xl};
    ${(p) => p.theme.font.bold}
  }

  & > aside {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
  }
`;

// export type Menu = 'dashboard' | 'earn' | 'terms';
interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props) {
  const { t, i18n } = useTranslation('common');

  const pathName = usePathname();

  const { isTestnet } = useEnv();

  const menu = useMemo(() => {
    switch (pathName.replace('/', '').toLowerCase()) {
      case 'earn':
        return 'earn';
      case 'terms':
        return 'Privacy Policy & Terms of Use';
      default:
        return 'dashboard';
    }
  }, [pathName]);

  const pageTitle = useMemo(
    () => menu.charAt(0).toUpperCase() + menu.slice(1),
    [menu]
  );

  const handleFaucetClick = useCallback(
    () => eventFaucetClick(isTestnet),
    [isTestnet]
  );

  return (
    <>
      <Head>
        <title>{t('common:metadata:title')}</title>
        <meta name="keywords" content={t('common:metadata:keywords')} />
        <meta name="description" content={t('common:metadata:description')} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={t('common:metadata:openGraph:title')}
        />
        <meta
          property="og:image"
          content={t('common:metadata:openGraph:image')}
        />
        <meta
          property="og:description"
          content={t('common:metadata:openGraph:description')}
        />
        <meta property="og:site_name" content="BTCFi" />
        <meta property="og:locale" content={i18n.language} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>
      <MainBG />
      <StyledLayout>
        <Sidebar activeMenu={menu} />
        <div>
          <Banner />
          <Header>
            {pageTitle && <h1>{pageTitle}</h1>}
            <aside>
              <ConnectWallet />
              <AddToken />
              {isTestnet ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={LINK_FAUCET}
                  onClick={handleFaucetClick}
                >
                  <IconFaucet />
                </a>
              ) : (
                ''
              )}
              <GlobalSetting />
            </aside>
          </Header>
          {children}
        </div>
      </StyledLayout>
    </>
  );
}
