import {
  BN,
  isNormalPositive,
  num2Hex,
  UnknownNumber,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import DEFAULT_DEADLINE from '@/configs/defaultValue/defaultDeadline';

const toTimestamp = (deadline: UnknownNumber) =>
  num2Hex(
    BN(Math.floor(new Date().getTime() / 1000)).plus(
      new BN(
        isNormalPositive(deadline) ? deadline : DEFAULT_DEADLINE
      ).multipliedBy(60)
    )
  );

export default toTimestamp;
