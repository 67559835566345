// name
export const TESTNET_CHAIN_NAME_BIFROST = 'Bifrost Testnet';
export const TESTNET_CHAIN_NAME_ETHEREUM = 'Ethereum Sepolia';
export const TESTNET_CHAIN_NAME_ARBITRUM = 'Arbitrum Sepolia';
export const TESTNET_CHAIN_NAME_POLYGON = 'Polygon Mumbai';
export const TESTNET_CHAIN_NAME_BSC = 'BNB Testnet';
export const TESTNET_CHAIN_NAME_BASE = 'Base Goerli';

export const TESTNET_CHAIN_NAMES = [
  TESTNET_CHAIN_NAME_BIFROST,
  TESTNET_CHAIN_NAME_ETHEREUM,
  TESTNET_CHAIN_NAME_ARBITRUM,
  TESTNET_CHAIN_NAME_POLYGON,
  TESTNET_CHAIN_NAME_BSC,
  TESTNET_CHAIN_NAME_BASE,
];
