// explorer url
export const MAINNET_CHAIN_EXPLORER_URL_BIFROST =
  'https://explorer.mainnet.bifrostnetwork.com/';
export const MAINNET_CHAIN_EXPLORER_URL_ETHEREUM = 'https://etherscan.io/';
export const MAINNET_CHAIN_EXPLORER_URL_ARBITRUM = 'https://arbiscan.io/';
export const MAINNET_CHAIN_EXPLORER_URL_POLYGON = 'https://polygonscan.com/';
export const MAINNET_CHAIN_EXPLORER_URL_BSC = 'https://bscscan.com/';
export const MAINNET_CHAIN_EXPLORER_URL_BASE = 'https://basescan.org/';

export const MAINNET_CHAIN_EXPLORER_URLS = [
  MAINNET_CHAIN_EXPLORER_URL_BIFROST,
  MAINNET_CHAIN_EXPLORER_URL_ETHEREUM,
  MAINNET_CHAIN_EXPLORER_URL_ARBITRUM,
  MAINNET_CHAIN_EXPLORER_URL_POLYGON,
  MAINNET_CHAIN_EXPLORER_URL_BSC,
  MAINNET_CHAIN_EXPLORER_URL_BASE,
];
