import {
  isValidAddress,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FRAG_LP_REWARD_PERIOD_FINISH } from '@/configs/fragment';
import useEnv from '@/hooks/useEnv';
import useIsBifrostChain from '@/hooks/useIsBifrostChain';
import {
  everdexLpRewardFinishPeriodAtom,
  everdexIsLoadingLpRewardFinishPeriodAtom,
} from '@/store/everdexStore';

const useEverdexLpRewardPeriodFinish = (
  proxyAddress?: string,
  rewardStateAddress?: string
) => {
  const { chainBifrost } = useEnv();

  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  const [finishPeriod, setFinishPeriod] = useAtom(
    everdexLpRewardFinishPeriodAtom
  );
  const [isLoading, setIsLoading] = useAtom(
    everdexIsLoadingLpRewardFinishPeriodAtom
  );

  const sync = useCallback(async () => {
    if (
      !(
        proxyAddress &&
        rewardStateAddress &&
        isValidAddress(proxyAddress) &&
        isValidAddress(rewardStateAddress)
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const to = proxyAddress;
      const fragment = FRAG_LP_REWARD_PERIOD_FINISH;
      const values: ReadonlyArray<any> = [rewardStateAddress];
      const from = account;
      const defaultBlock = 'latest';

      let finishPeriod = 0;

      if (isBifrostChain) {
        finishPeriod = pickNumberResponse(
          await wallet.call(to, fragment, values, from, defaultBlock)
        ).toNumber();
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        finishPeriod = pickNumberResponse(
          await rpcContractCall(callParams, rpcURL, defaultBlock)
        ).toNumber();
      }

      setFinishPeriod(finishPeriod);
    } catch (error) {}

    setIsLoading(false);
  }, [
    account,
    chainBifrost.rpcUrls,
    isBifrostChain,
    proxyAddress,
    rewardStateAddress,
    setIsLoading,
    setFinishPeriod,
    wallet,
  ]);

  return { finishPeriod, isLoading, sync };
};

export default useEverdexLpRewardPeriodFinish;
