import { useBalance } from '@bifrost-platform/bifront-sdk-react-biholder';
import { useCallback, useMemo } from 'react';
import useEnv from '@/hooks/useEnv';
import useEverdexLpRewardAmount from './useEverdexLpRewardAmount';
import useEverdexLpRewardApr from './useEverdexLpRewardApr';
import useEverdexLpRewardDepositedAmount from './useEverdexLpRewardDepositedAmount';
import useEverdexLpRewardPeriodFinish from './useEverdexLpRewardPeriodFinish';
import useEverdexLpRewardReserves from './useEverdexLpRewardReserves';

const useEverdexLpReward = () => {
  // env
  const { addressSwapMerger, everdexLpRewardPool, tokenBifrostEverdexStakeLp } =
    useEnv();

  // balance
  const { balance } = useBalance(tokenBifrostEverdexStakeLp);

  // everdex
  const {
    totalSupply,
    rewardRate,
    apr,
    isLoading: isLoadingApr,
    sync: syncApr,
  } = useEverdexLpRewardApr(
    everdexLpRewardPool?.rewardContract?.proxyAddress,
    everdexLpRewardPool?.rewardContract?.rewardStateAddresses?.[0]
  );
  const {
    amount: depositedAmount,
    isLoading: isLoadingDepositedAmount,
    sync: syncDepositedAmount,
  } = useEverdexLpRewardDepositedAmount(
    everdexLpRewardPool?.rewardContract?.proxyAddress
  );
  const {
    amount: rewardAmount,
    isLoading: isLoadingAmount,
    sync: syncRewardAmount,
  } = useEverdexLpRewardAmount(
    everdexLpRewardPool?.rewardContract?.proxyAddress,
    everdexLpRewardPool?.rewardContract?.rewardStateAddresses?.[0]
  );
  const {
    reserves,
    isLoading: isLoadingReserves,
    sync: syncRewardReserves,
  } = useEverdexLpRewardReserves(
    addressSwapMerger,
    everdexLpRewardPool?.address
  );
  const {
    finishPeriod,
    isLoading: isLoadingFinishPeriod,
    sync: syncFinishPeriod,
  } = useEverdexLpRewardPeriodFinish(
    everdexLpRewardPool?.rewardContract?.proxyAddress,
    everdexLpRewardPool?.rewardContract?.rewardStateAddresses?.[0]
  );

  // memo
  const isLoading = useMemo(
    () =>
      isLoadingApr ||
      isLoadingDepositedAmount ||
      isLoadingAmount ||
      isLoadingReserves ||
      isLoadingFinishPeriod,
    [
      isLoadingAmount,
      isLoadingApr,
      isLoadingDepositedAmount,
      isLoadingReserves,
      isLoadingFinishPeriod,
    ]
  );

  // callback
  const sync = useCallback(() => {
    syncApr();
    syncDepositedAmount();
    syncRewardAmount();
    syncRewardReserves();
    syncFinishPeriod();
  }, [
    syncApr,
    syncDepositedAmount,
    syncRewardAmount,
    syncRewardReserves,
    syncFinishPeriod,
  ]);

  return {
    balance,
    totalSupply,
    rewardRate,
    apr,
    depositedAmount,
    rewardAmount,
    reserves,
    finishPeriod,
    isLoading,
    sync,
  };
};

export default useEverdexLpReward;
