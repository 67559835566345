import {
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCUSD,
} from './tokens/tokenSymbols';

// required symbols of allowance reset
export const SYMBOLS_ALLOWANCE_RESET_REQUIRE: string[] = [TOKEN_SYMBOL_USDT];

// available symbols
export const AVAILABLE_SYMBOLS = [
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
];

// btc symbols
export const BTC_SYMBOLS: string[] = [TOKEN_SYMBOL_WBTC, TOKEN_SYMBOL_BTCB];
