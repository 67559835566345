import {
  BN,
  Chain,
  ChainId,
  Token,
  TokenAddress,
  num2Hex,
  parseUnit,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  UnknownNumber,
  minMax,
  encode,
  AddChainParameter,
  Wallet,
  SendParams,
  AddTokenOption,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import DEFAULT_BLOCK_TERM from '@/configs/defaultValue/defaultBlock';
import { FRAG_REQUEST, ACTION_FRAGMENT_MAP } from '@/configs/fragment';
import bridgeEncoder from '@/libs/bridgeEncoder';
import bridgeStatusGetter, {
  BridgeStatus,
  BRIDGE_PROGRESS_LENGTH as progressLength,
} from '@/libs/bridgeStatusGetter';
import { formatNumber } from '@/libs/formatNumber';
import log from '@/libs/log';
import ActionType from '@/types/action/ActionType';

// bridge progress length
export const BRIDGE_PROGRESS_LENGTH = progressLength;

export const makeServiceFeeAmount = (
  value: UnknownNumber,
  rate: UnknownNumber,
  min?: UnknownNumber,
  max?: UnknownNumber
) => minMax(new BN(value).multipliedBy(rate).div('100'), min, max);

export const getIsOutbound = (actionType?: ActionType) =>
  actionType === 'withdraw' || actionType === 'borrow';

// sender
export const changeChain = async (wallet: Wallet, chain: Chain) => {
  const chainInfo: AddChainParameter = {
    chainId: num2Hex(chain.id),
    chainName: chain.name,
    rpcUrls: chain.rpcUrls,
    nativeCurrency: {
      name: chain?.coin?.symbol || '',
      symbol: chain?.coin?.symbol || '',
      decimals: chain?.coin?.decimals || 18,
    },
    blockExplorerUrls: [chain.explorerUrl],
  };

  return wallet.switchAndAddChain(chainInfo);
};
export const addToken = async (
  wallet: Wallet,
  token: Token,
  image?: string
) => {
  const tokenInfo: AddTokenOption = {
    address: token.address,
    symbol: token.symbol,
    decimals: token.decimals,
    image: image ?? '',
  };

  return wallet.addToken(tokenInfo);
};

// bridge
export const waitBridgeStatus = (
  transactionHash: string,
  networkApiPath: string,
  setProgress?: (value: number) => void
): Promise<BridgeStatus> =>
  new Promise((resolve, reject) => {
    let progress = 0;

    const intervalId = setInterval(async () => {
      const bridgeStatusData = await bridgeStatusGetter(
        transactionHash,
        networkApiPath
      );
      const { progress: statusProgress, src, dst } = bridgeStatusData;
      log('bridgeStatusData', src?.status, dst?.status, progress);

      if (statusProgress !== progress) {
        progress = statusProgress;
        setProgress && setProgress(statusProgress);
      }

      if (!(src && dst)) {
        return;
      }

      if (
        bridgeStatusData.status === 'failed' ||
        src.status === 'FAILED' ||
        src.status === 'REVERTED' ||
        src.status === 'REJECTED' ||
        src.status === 'ROLLBACKED' ||
        dst.status === 'FAILED' ||
        dst.status === 'REVERTED' ||
        dst.status === 'REJECTED' ||
        dst.status === 'ROLLBACKED'
      ) {
        reject(bridgeStatusData);
      } else if (statusProgress >= BRIDGE_PROGRESS_LENGTH - 1) {
        clearInterval(intervalId);
        resolve(bridgeStatusData);
      }
    }, DEFAULT_BLOCK_TERM);
  });

// maker
export const makeActionSendData = async ({
  actionType,
  tokenAddress,
  from,
  to,
  unknwonNumberValue,
  isCrossChain,
  chainId,
  chainIdBifrost,
  networkApiPath,
}: {
  actionType: ActionType;
  tokenAddress: TokenAddress;
  from: string;
  to: string;
  unknwonNumberValue: UnknownNumber;
  isCrossChain?: boolean;
  chainId: ChainId;
  chainIdBifrost: ChainId;
  networkApiPath: string;
}) => {
  const sendValue = formatNumber(unknwonNumberValue);
  const formatedSendValue = num2Hex(parseUnit(sendValue, 18));
  const value = '0x0';

  let toAddress: string = to;
  let fragment = ACTION_FRAGMENT_MAP[actionType];
  let args: any[] = [formatedSendValue, false];

  if (isCrossChain) {
    const amount = sendValue;

    const { parameter, contractAddress } = await bridgeEncoder({
      isOutbound: getIsOutbound(actionType),
      chainId,
      address: from,
      tokenAddress,
      amount,
      actionType,
      chainIdBifrost,
      networkApiPath,
    });

    toAddress = contractAddress;
    fragment = FRAG_REQUEST;
    args = [
      [
        [parameter.chain, parameter.method],
        [
          parameter.token0,
          parameter.token1,
          parameter.from,
          parameter.to,
          parameter.amount,
          parameter.variant,
        ],
      ],
    ];
  }

  const data = encode(fragment, args);

  return {
    encodedParams: {
      from,
      to: toAddress,
      value,
      data,
    },
    sendParams: {
      from,
      to: toAddress,
      value,
      signature: fragment,
      values: args,
    },
  };
};

// send
export const sendWithSendParams = (wallet: Wallet, sendParams: SendParams) =>
  wallet.send(
    sendParams.to,
    sendParams.signature,
    sendParams.values,
    sendParams.value,
    sendParams.from ?? wallet.getAddress(),
    sendParams.gasPrice,
    sendParams.gas
  );
