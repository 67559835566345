import {
  Token,
  useAssetInfoMap,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { AnimatePresence, motion } from 'framer-motion';
import hexToRgba from 'hex-to-rgba';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import IconArrow from '@/assets/icons/icon-arrow.svg';
import Popover from '@/components/Popover';
import useEnv from '@/hooks/useEnv';
import useIsConnected from '@/hooks/useIsConnected';
import { getIsCorrectChain } from '@/hooks/useIsCorrectChain';
import { addToken, changeChain } from '@/libs/contract/wallet';
import { isShowConnectWalletAtom } from '@/store/uiStore';
import AddDialog from './AddDialog';
import DefaultAddDialog from './DefaultAddDialog';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  height: 48px;
  border-radius: 24px;
  background: ${({ theme }) => hexToRgba(theme.colors.white, 0.2)};
  font-size: ${({ theme }) => theme.size.md};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.border.gray}
`;
const AddWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  padding: 16px;
  border-radius: 24px;
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, 0.2)};
  backdrop-filter: blur(12px);
  overflow: hidden;
  ${({ theme }) => theme.border.gray};
`;

export default function AddToken() {
  // translate
  const { t } = useTranslation();

  // env
  const { chains, tokenBifrostBtcusd, tokensBifrostBtc } = useEnv();

  // wallet
  const { wallet } = useWallet();
  const isConnected = useIsConnected();

  // asset info
  const { assetInfoMap } = useAssetInfoMap();

  // store
  const [, setIsShowConnectWallet] = useAtom(isShowConnectWalletAtom);

  // state
  const [token, setToken] = useState<Token | undefined>();

  // handler
  const handlePopoverTriggerClick = useCallback(() => setToken(undefined), []);
  const handleTokenSelectClick = useCallback(
    (token?: Token) => () => setToken(token),
    []
  );
  const handleTokenClick = useCallback(
    (token: Token) => async () => {
      if (!isConnected) {
        setIsShowConnectWallet(true);
        return;
      }

      const chain = chains.find((chain) => chain.id === token.chainId);

      if (!chain) {
        return;
      }

      if (!getIsCorrectChain(wallet, chain.id)) {
        await changeChain(wallet, chain);
      }

      const image =
        assetInfoMap[chain.id]?.[token.address.toLowerCase()]?.imageUrl;

      await addToken(wallet, token, image);
    },
    [assetInfoMap, chains, isConnected, setIsShowConnectWallet, wallet]
  );
  const handleBackClick = useCallback(() => setToken(undefined), []);

  return (
    <Wrapper>
      <Popover
        triggerElement={
          <AddButton onClick={handlePopoverTriggerClick}>
            {t('common:addToken')}
            <IconArrow />
          </AddButton>
        }
      >
        <AddWrapper as={motion.div} layout="size">
          <AnimatePresence initial={false}>
            <DefaultAddDialog
              isShow={!token}
              tokenBtcusd={tokenBifrostBtcusd}
              tokens={tokensBifrostBtc}
              onTokenSelectClick={handleTokenSelectClick}
              onTokenClick={handleTokenClick}
            />
            <AddDialog
              token={token}
              onTokenClick={handleTokenClick}
              onBackClick={handleBackClick}
            />
          </AnimatePresence>
        </AddWrapper>
      </Popover>
    </Wrapper>
  );
}
