import {
  getStorageItem,
  setStorageItem,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import CURRENCIES from '@/configs/currencies';
import { currencyIdAtom } from '@/store/uiStore';
import { CurrencyId } from '@/types/env/Currency';

const STORAGE_ID_CURRENCY = 'currency';

const useCurrency = () => {
  const [currencyId, setCurrencyId] = useAtom(currencyIdAtom);

  const changeCurrency = useCallback(
    (newCurrency: CurrencyId) => {
      if (!CURRENCIES.find((currency) => currency.id === newCurrency)) {
        return;
      }

      setStorageItem(STORAGE_ID_CURRENCY, newCurrency);
      setCurrencyId(newCurrency);
    },
    [setCurrencyId]
  );
  const sync = useCallback(() => {
    const storageCurrency =
      getStorageItem<CurrencyId>(STORAGE_ID_CURRENCY) || 'usd';

    if (CURRENCIES.find((currency) => currency.id === storageCurrency)) {
      setCurrencyId(storageCurrency);
    }
  }, [setCurrencyId]);

  return { currencyId, changeCurrency, sync };
};

export default useCurrency;
