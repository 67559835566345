import SentryEvent from '@/types/SentryEvent';

export const SENTRY_EVENT_CONNECT_WALLET: SentryEvent = {
  event: 'connect_wallet',
  message: 'Connect wallet',
  type: 'Wallet',
};
export const SENTRY_EVENT_WITHDRAW_DONE: SentryEvent = {
  event: 'withdraw_done',
  screen: 'Dashboard',
  message: 'Done',
  type: 'Token',
  label: 'Withdraw',
};
export const SENTRY_EVENT_SUPPLY_DONE: SentryEvent = {
  event: 'supply_done',
  screen: 'Dashboard',
  message: 'Done',
  type: 'Token',
  label: 'Supply',
};
export const SENTRY_EVENT_BIFI_CLAIM_REWARDS_CLICK: SentryEvent = {
  event: 'claim_rewards_click',
  screen: 'Dashboard',
  message: 'Click',
  label: 'Claim BiFi rewards',
};
export const SENTRY_EVENT_BIFI_CLAIM_REWARDS_DONE: SentryEvent = {
  event: 'claim_rewards_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Claim BiFi rewards',
};
export const SENTRY_EVENT_BIFI_WITHDRAW_CLICK: SentryEvent = {
  event: 'withdraw_click',
  screen: 'Dashboard',
  message: 'Click',
  label: 'BiFi withdraw',
};
export const SENTRY_EVENT_BIFI_WITHDRAW_DONE: SentryEvent = {
  event: 'withdraw_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'BiFi withdraw',
};
export const SENTRY_EVENT_EVERDEX_CLAIM_REWARDS_CLICK: SentryEvent = {
  event: 'claim_rewards_click',
  screen: 'Dashboard',
  message: 'Click',
  label: 'Claim LP rewards',
};
export const SENTRY_EVENT_EVERDEX_CLAIM_REWARDS_DONE: SentryEvent = {
  event: 'claim_rewards_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Claim LP rewards',
};
export const SENTRY_EVENT_EVERDEX_UNSTAKING_CLICK: SentryEvent = {
  event: 'unstaking_click',
  screen: 'Dashboard',
  message: 'Click',
  label: 'Unstake',
};
export const SENTRY_EVENT_EVERDEX_UNSTAKING_DONE: SentryEvent = {
  event: 'unstaking_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Unstake',
};
export const SENTRY_EVENT_EVERDEX_REMOVE_LIQUIDITY_CLICK: SentryEvent = {
  event: 'remove_liquidity_click',
  screen: 'Dashboard',
  message: 'Click',
  label: 'Everdex withdraw',
};
export const SENTRY_EVENT_EVERDEX_REMOVE_LIQUIDITY_DONE: SentryEvent = {
  event: 'remove_liquidity_done',
  screen: 'Dashboard',
  message: 'Done',
  label: 'Everdex withdraw',
};
export const SENTRY_EVENT_MIGRATE_CLICK: SentryEvent = {
  event: 'migrate_click',
  screen: 'Points',
  message: 'Click',
  label: 'Migration',
};
export const SENTRY_EVENT_MIGRATE_DONE: SentryEvent = {
  event: 'migrate_done',
  screen: 'Points',
  message: 'Done',
  label: 'Migration',
};
