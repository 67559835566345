var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"55ecc758684e2081c2f832fa073126eb5ba60426"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN: string =
  process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN ?? '';

const RAW_IGNORE_ERRORS: string = process.env.NEXT_PUBLIC_IGNORE_ERRORS ?? '';

let ignoreErrors: string[] = [];

try {
  ignoreErrors = (JSON.parse(RAW_IGNORE_ERRORS) as string[]).filter(
    (ignoreError) => typeof ignoreError === 'string'
  );
} catch (error) {
  ignoreErrors = [];
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors,
});
