import {
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import type { SetStateAction } from 'jotai';
import React, { cloneElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  backdrop-filter: blur(4px);
  border-radius: ${(p) => p.theme.size.xl};
  z-index: 2;
`;

const Popover = ({
  placement = 'bottom-end',
  offsetPx = 16,
  triggerElement,
  globalOpen,
  globalOpener,
  children,
}: {
  placement?: Placement;
  offsetPx?: number;
  triggerElement: JSX.Element;
  globalOpen?: boolean;
  globalOpener?: (update: SetStateAction<boolean>) => void;
  children: JSX.Element;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = useCallback(() => {
    setOpen((prev) => !prev);

    if (globalOpener) {
      globalOpener((prev) => !prev);
    }
  }, [globalOpener]);

  const { x, y, refs, context } = useFloating({
    open: typeof globalOpen !== 'undefined' ? globalOpen : open,
    onOpenChange: handleOpenChange,
    placement,
    middleware: [offset(offsetPx), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      outsidePressEvent: 'click',
    }),
  ]);

  useEffect(() => {
    const resizeHandler = () => {
      setOpen(false);

      if (globalOpener) {
        globalOpener(false);
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [globalOpener]);

  return (
    <>
      {cloneElement(
        triggerElement,
        getReferenceProps({ ref: refs.setReference, ...triggerElement.props })
      )}
      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <Container
              ref={refs.setFloating}
              style={{
                top: y ?? '',
                left: x ?? '',
              }}
              {...getFloatingProps()}
            >
              <div style={styles}>
                {React.cloneElement(children, { setOpen })}
              </div>
            </Container>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default Popover;
