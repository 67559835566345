import {
  Token,
  compareLowerStr,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import MAINNET_TOKENS from '@/configs/tokens/mainnetTokens';
import { getMainneChain } from './chain';

export const getMainneToken = (token?: Token) => {
  const mainnetChainId = getMainneChain(token?.chainId);

  return MAINNET_TOKENS.find(
    (mainnetToken) =>
      mainnetToken.chainId === mainnetChainId &&
      compareLowerStr(mainnetToken.symbol, token?.symbol)
  );
};
