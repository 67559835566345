export const ADD_EMPTY_BY_API: string =
  process.env.NEXT_PUBLIC_ADD_EMPTY_BY_API || '';
export const ADD_EMPTY: string = process.env.NEXT_PUBLIC_ADD_EMPTY || '';
export const ADD_NATIVE_TOKEN: string =
  process.env.NEXT_PUBLIC_ADD_NATIVE_TOKEN || '';
export const ADD_DEFAULT: string = process.env.NEXT_PUBLIC_ADD_DEFAULT || '';

// bifi reward pool
export const MAINNET_ADD_BIFI_REWARD_POOL: string =
  '0x9F654ED26c015ED18D85Bd410cc03D24FBadc74d';
export const TESTNET_ADD_BIFI_REWARD_POOL: string =
  '0xAaf5a6a8D9E8990CFEd192DF8e5eCaAdb13F2243';

// everdex reward pool
export const MAINNET_ADD_EVERDEX_REWARD_POOL: string =
  '0x840Cf4522ED96CBBEB0924672Ea170456eea3a4c';
export const TESTNET_ADD_EVERDEX_REWARD_POOL: string =
  '0x1A70bB0c4d4D3cE4E58C97e4f4F1Fc64686ee26C';

// everdex reward proxy
export const MAINNET_ADD_EVERDEX_REWARD_PROXY: string =
  '0x49Ef8EFCB4C1C90C4c3C089B11398b44DC801456';
export const TESTNET_ADD_EVERDEX_REWARD_PROXY: string =
  '0x333F96742191C8b0723D8EDd3295a92205F80e54';

// everdex reward staking state
export const MAINNET_ADD_EVERDEX_REWARD_STAKING_STATE: string =
  '0x289122630B94Bc8Bc0bE01041af5Abe668585A70';
export const TESTNET_ADD_EVERDEX_REWARD_STAKING_STATE: string =
  '0xD278231C6ad6FeF6134024A4bb0569d38aE00Ba2';

// everdex reward state
export const MAINNET_ADD_EVERDEX_REWARD_STATE: string =
  '0x573c42b20059829612C19bF11324FC1225A9833C';
export const TESTNET_ADD_EVERDEX_REWARD_STATE: string =
  '0x51138cfFa84b0070DEB16264994948B06c8bF6fB';

// everdex swap merger
export const TESTNET_ADD_EVERDEX_SWAP_MERGER: string =
  '0x0790070D296458de036bd25787c8dFfCF7E11B4b';
export const MAINNET_ADD_EVERDEX_SWAP_MERGER: string =
  '0x7Ace89E21a5c3300B22DFE168Ae256246EBFA9E2';
