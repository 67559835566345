import {
  AssetInfo,
  Token,
  useAssetInfoMap,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { useCallback } from 'react';
import { CurrencyId } from '@/types/env/Currency';
import { getMainneToken } from '@/utils/token';
import useEnv from '../useEnv';

const useGetPrice = (forceCurrencyId?: CurrencyId) => {
  // env
  const { isTestnet, currencyId } = useEnv();

  // asset info
  const { assetInfoMap } = useAssetInfoMap();

  // callback
  const getPrice = useCallback(
    (token?: Token) => {
      const targetToken = isTestnet ? getMainneToken(token) : token;
      const assetInfo = assetInfoMap?.[targetToken?.chainId ?? 0]?.[
        targetToken?.address?.toLowerCase() ?? ''
      ] as AssetInfo | undefined;
      const result =
        assetInfo?.[
          (forceCurrencyId ?? currencyId) === 'krw'
            ? 'priceInKrw'
            : 'priceInUsd'
        ] ?? '0';

      return result;
    },
    [assetInfoMap, currencyId, forceCurrencyId, isTestnet]
  );

  return getPrice;
};

export default useGetPrice;
