import {
  formatUnit,
  isValidAddress,
  pickNumberResponse,
  rpcContractCall,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FRAG_LP_REWARD_BALANCE_OF } from '@/configs/fragment';
import useEnv from '@/hooks/useEnv';
import useIsBifrostChain from '@/hooks/useIsBifrostChain';
import { formatNumber } from '@/libs/formatNumber';
import {
  everdexLpRewardDepositedAmountAtom,
  everdexIsLoadingLpRewardDepositedAmountAtom,
} from '@/store/everdexStore';

const useEverdexLpRewardDepositedAmount = (proxyAddress?: string) => {
  const { chainBifrost } = useEnv();

  const { wallet, account } = useWallet();
  const isBifrostChain = useIsBifrostChain();

  const [depositedAmount, setDepositedAmount] = useAtom(
    everdexLpRewardDepositedAmountAtom
  );
  const [isLoading, setIsLoading] = useAtom(
    everdexIsLoadingLpRewardDepositedAmountAtom
  );

  const sync = useCallback(async () => {
    if (!(proxyAddress && isValidAddress(proxyAddress))) {
      return;
    }

    setIsLoading(true);

    try {
      const to = proxyAddress;
      const fragment = FRAG_LP_REWARD_BALANCE_OF;
      const values: ReadonlyArray<any> = [account];
      const from = account;
      const defaultBlock = 'latest';

      let depositedAmount = '0';

      if (isBifrostChain) {
        depositedAmount = pickNumberResponse(
          await wallet.call(to, fragment, values, from, defaultBlock)
        );
      } else {
        const rpcURL = chainBifrost.rpcUrls[0];
        const callParams = {
          from,
          to,
          signature: fragment,
          values,
        };

        depositedAmount = pickNumberResponse(
          await rpcContractCall(callParams, rpcURL, defaultBlock)
        );
      }

      setDepositedAmount(formatNumber(formatUnit(depositedAmount, 18)));
    } catch (error) {}

    setIsLoading(false);
  }, [
    account,
    chainBifrost.rpcUrls,
    isBifrostChain,
    proxyAddress,
    setDepositedAmount,
    setIsLoading,
    wallet,
  ]);

  return { amount: depositedAmount, isLoading, sync };
};

export default useEverdexLpRewardDepositedAmount;
