// rpc urls
export const TESTNET_CHAIN_RPC_URLS_BIFROST = [
  'https://public-02.testnet.thebifrost.io/rpc',
  'https://public-01.testnet.thebifrost.io/rpc',
];
export const TESTNET_CHAIN_RPC_URLS_ETHEREUM = [
  'https://rpc.sepolia.org',
  'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
  'https://rpc-sepolia.rockx.com',
  'https://1rpc.io/sepolia',
];
export const TESTNET_CHAIN_RPC_URLS_ARBITRUM = [
  'https://arbitrum-sepolia.blockpi.network/v1/rpc/public',
  'https://endpoints.omniatech.io/v1/arbitrum/sepolia/public',
  'https://public.stackup.sh/api/v1/node/arbitrum-sepolia',
];
export const TESTNET_CHAIN_RPC_URLS_POLYGON = [
  'https://rpc.ankr.com/polygon_mumbai',
  'https://matic-mumbai.chainstacklabs.com',
];
export const TESTNET_CHAIN_RPC_URLS_BSC = [
  'https://data-seed-prebsc-1-s1.binance.org:8545',
  'https://data-seed-prebsc-1-s2.binance.org:8545',
];
export const TESTNET_CHAIN_RPC_URLS_BASE = [
  'https://goerli.base.org',
  'https://1rpc.io/base-goerli',
  'https://base-goerli.public.blastapi.io',
];

export const TESTNET_CHAIN_RPC_URL_BIFROST = TESTNET_CHAIN_RPC_URLS_BIFROST[0];
export const TESTNET_CHAIN_RPC_URL_ETHEREUM =
  TESTNET_CHAIN_RPC_URLS_ETHEREUM[0];
export const TESTNET_CHAIN_RPC_URL_ARBITRUM =
  TESTNET_CHAIN_RPC_URLS_ARBITRUM[0];
export const TESTNET_CHAIN_RPC_URL_POLYGON = TESTNET_CHAIN_RPC_URLS_POLYGON[0];
export const TESTNET_CHAIN_RPC_URL_BSC = TESTNET_CHAIN_RPC_URLS_BSC[0];
export const TESTNET_CHAIN_RPC_URL_BASE = TESTNET_CHAIN_RPC_URLS_BASE[0];

export const TESTNET_CHAIN_RPC_URLS = [
  TESTNET_CHAIN_RPC_URLS_BIFROST,
  TESTNET_CHAIN_RPC_URLS_ETHEREUM,
  TESTNET_CHAIN_RPC_URLS_ARBITRUM,
  TESTNET_CHAIN_RPC_URLS_BSC,
  TESTNET_CHAIN_RPC_URLS_POLYGON,
  TESTNET_CHAIN_RPC_URLS_BASE,
];
