import IS_DEV from '@/libs/devEnv/isDev';

const HTTP_PREFIX = 'https://';
const DOMAIN_BIFROST_DEV = 'thebifrost.dev';
const DOMAIN_BIFROST = 'thebifrost.io';

export const API_HOST_BRIDGE = IS_DEV
  ? `${HTTP_PREFIX}bifrost-bridge.${DOMAIN_BIFROST_DEV}`
  : `${HTTP_PREFIX}bridge-api.${DOMAIN_BIFROST}`;
export const API_HOST_BIHOLDER = IS_DEV
  ? `${HTTP_PREFIX}biholder-view.${DOMAIN_BIFROST_DEV}`
  : `${HTTP_PREFIX}biholder-view.${DOMAIN_BIFROST}`;
export const API_HOST_EVERDEX = IS_DEV
  ? `${HTTP_PREFIX}api-everdex.${DOMAIN_BIFROST_DEV}`
  : `${HTTP_PREFIX}api.everdex.app`;
