import {
  Wallet,
  isNormalPositive,
  isValidAddress,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';

export const getIsConnected = (wallet?: Wallet) =>
  !!(
    wallet &&
    wallet.getProvider() &&
    isValidAddress(wallet.getAddress()) &&
    isNormalPositive(wallet.getChainId(), true)
  );

const useIsConnected = () => {
  const { wallet } = useWallet();

  return getIsConnected(wallet);
};

export default useIsConnected;
