import {
  ChainId,
  ChainByAPI,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { MAINNET_CHAINS } from './mainnet';
import {
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_ETHEREUM,
  MAINNET_CHAIN_ID_ARBITRUM,
  MAINNET_CHAIN_ID_POLYGON,
  MAINNET_CHAIN_ID_BSC,
  MAINNET_CHAIN_ID_BASE,
} from './mainnet/chainId';
import { TESTNET_CHAINS } from './testnet';
import {
  TESTNET_CHAIN_ID_BIFROST,
  TESTNET_CHAIN_ID_ETHEREUM,
  TESTNET_CHAIN_ID_ARBITRUM,
  TESTNET_CHAIN_ID_POLYGON,
  TESTNET_CHAIN_ID_BSC,
  TESTNET_CHAIN_ID_BASE,
} from './testnet/chainId';

export const NETWORK_CHAIN_ID_SET: [
  ChainId | undefined,
  ChainId | undefined,
][] = [
  [MAINNET_CHAIN_ID_BIFROST, TESTNET_CHAIN_ID_BIFROST],
  [MAINNET_CHAIN_ID_ETHEREUM, TESTNET_CHAIN_ID_ETHEREUM],
  [MAINNET_CHAIN_ID_ARBITRUM, TESTNET_CHAIN_ID_ARBITRUM],
  [MAINNET_CHAIN_ID_POLYGON, TESTNET_CHAIN_ID_POLYGON],
  [MAINNET_CHAIN_ID_BSC, TESTNET_CHAIN_ID_BSC],
  [MAINNET_CHAIN_ID_BASE, TESTNET_CHAIN_ID_BASE],
];

export const EVERY_CHAINS = [...MAINNET_CHAINS, ...TESTNET_CHAINS];

export const EVERY_CHAIN_IDS_BY_API: ChainByAPI[] = EVERY_CHAINS.map(
  (chain) => chain.apiId
).filter((apiId) => !!apiId) as ChainByAPI[];
export const EVERY_CHAIN_IDS: ChainId[] = EVERY_CHAINS.map(
  (chain) => chain.id
).filter((id) => !!id);
export const RPC_MAP = Object.fromEntries(
  EVERY_CHAINS.map((chain) => [chain.id, chain.rpcUrls[0]])
);
