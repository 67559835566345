import { Token } from '@bifrost-platform/bifront-sdk-react-biholder';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import AddTokenItem from './AddTokenItem';

interface Props {
  isShow: boolean;
  tokenBtcusd?: Token;
  tokens?: Token[];
  onTokenSelectClick?: (token?: Token) => () => void;
  onTokenClick?: (token: Token) => () => void;
}

const Wrapper = styled.div`
  position: relative;
  width: 240px;
`;
const Title = styled.p`
  padding-bottom: 16px;
  font-size: ${(p) => p.theme.size.md};
  ${(p) => p.theme.font.bold};
  line-height: 1.5;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default function DefaultAddDialog({
  isShow,
  tokenBtcusd,
  tokens,
  onTokenSelectClick: handleTokenSelectClick,
  onTokenClick: handleTokenClick,
  ...rest
}: Props) {
  const { t } = useTranslation();

  if (!isShow) {
    return;
  }

  return (
    <Wrapper
      as={motion.div}
      initial={{
        x: '-100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: '-100%',
      }}
      {...rest}
    >
      <Title>{t('addTokenToWallet')}</Title>
      <List>
        {tokenBtcusd ? (
          <AddTokenItem
            isAddable
            token={tokenBtcusd}
            onClick={handleTokenClick?.(tokenBtcusd)}
          />
        ) : (
          ''
        )}
        {tokens?.map((token, tokenIndex) => (
          <AddTokenItem
            token={token}
            key={tokenIndex}
            onClick={handleTokenSelectClick?.(token)}
          />
        ))}
      </List>
    </Wrapper>
  );
}
