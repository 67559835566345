import hexToRgba from 'hex-to-rgba';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import IconBtcfi from '@/assets/icons/icon-btcfi.svg';
import IconDashboard from '@/assets/icons/icon-dashboard.svg';
import IconDiscord from '@/assets/icons/icon-discord.svg';
import IconDocs from '@/assets/icons/icon-docs.svg';
import IconDocs2 from '@/assets/icons/icon-docs2.svg';
import IconEarn from '@/assets/icons/icon-earn.svg';
import IconTalk from '@/assets/icons/icon-talk.svg';
import {
  LINK_FEEDBACK,
  LINK_DOCS_EN,
  LINK_DOCS_KO,
  LINK_HELP_CENTER,
} from '@/configs/link';
import { MEDIA_QUERY_BREAKPOINT } from '@/configs/mediaQuery';
import {
  PATH_DASHBOARD,
  PATH_EARN,
  PATH_HOME,
  PATH_TERMS,
} from '@/configs/path';
import useEnv from '@/hooks/useEnv';
import {
  eventDocsClick,
  eventHelpCenterClick,
  eventPolicyClick,
} from '@/libs/gtag';
import { FlexBox } from './atom/FlexBox';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from './atom/Tooltip';

const StyledSidebar = styled.aside`
  position: sticky;
  top: 0;
  padding: 24px 40px;
  height: 100vh;

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    padding: 24px 0px 24px 40px;
  }
`;
const CopyRight = styled.div`
  p,
  span {
    margin-top: 4px;
    color: ${(p) => hexToRgba(p.theme.colors.textWhite, 0.4)};
  }

  span {
    display: none;
  }

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    span {
      display: block;
      text-indent: 0 !important;
    }

    p {
      display: none;
    }
  }
`;
const Wrapper = styled.div`
  position: sticky;
  top: 40px;
  min-height: 720px;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  width: 320px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  border: 1px solid ${(p) => hexToRgba(p.theme.colors.white, 0.2)};
  ${(p) => p.theme.rounded.xl};
  transition: linear 0.2s all;
  will-change: width;

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    padding: 24px;
    width: 80px;
  }
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: ${(p) => p.theme.size.xl};
    font-style: italic;
    ${(p) => p.theme.font.black};
  }

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    gap: 0;
    justify-content: center;

    span {
      display: none;
    }
  }
`;
const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    display: flex;
    gap: 10px;
    font-size: ${(p) => p.theme.size.md};
    ${(p) => p.theme.font.medium};
    line-height: 1.5;
    color: ${(p) => hexToRgba(p.theme.colors.textWhite, 0.4)};

    &:hover {
      color: ${(p) => p.theme.colors.textWhite};
      cursor: pointer;
    }

    @media (${MEDIA_QUERY_BREAKPOINT}) {
      gap: 0;
      justify-content: center;
    }
  }
`;
const FooterMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.size.md};
  ${(p) => p.theme.font.regular}

  svg {
    margin-bottom: 4px;
  }

  hr {
    margin: 20px 0;
    border-color: ${(p) => hexToRgba(p.theme.colors.gray, 0.4)};
  }

  a + a {
    margin-top: 16px;
  }

  a > span > svg {
    display: none;
  }

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    a > span > svg {
      display: inline-block;
    }

    a {
      margin: 0 auto;
      text-align: center;
    }
  }
`;
const StyledLink = styled(Link)<{ $isActive?: boolean }>`
  ${(p) =>
    p.$isActive &&
    `
      color: ${p.theme.colors.white}!important;
  `}

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
const BreakPointEl = styled.span`
  display: none;

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    display: inline-block !important;
  }
`;
const HighResolutionEl = styled(FlexBox)`
  display: flex;
  overflow: visible;

  @media (${MEDIA_QUERY_BREAKPOINT}) {
    display: none;
  }
`;

interface Props {
  activeMenu: 'dashboard' | 'earn' | string;
}

export default function Sidebar({ activeMenu }: Props) {
  const { i18n } = useTranslation();

  const { isTestnet } = useEnv();

  const docsLink = useMemo(
    () => (i18n.language?.startsWith('ko') ? LINK_DOCS_KO : LINK_DOCS_EN),
    [i18n]
  );

  const handleDocsClick = useCallback(
    () => eventDocsClick(isTestnet),
    [isTestnet]
  );
  const handleHelpCenterClick = useCallback(
    () => eventHelpCenterClick(isTestnet),
    [isTestnet]
  );
  const handlePolicyClick = useCallback(
    () => eventPolicyClick(isTestnet),
    [isTestnet]
  );

  return (
    <StyledSidebar>
      <Wrapper>
        <Link href={PATH_HOME}>
          <Logo>
            <IconBtcfi />
            <span>BTCFi</span>
          </Logo>
        </Link>
        <MenuContainer>
          <MenuWrapper>
            <StyledLink
              href={PATH_DASHBOARD}
              $isActive={activeMenu === 'dashboard'}
            >
              <HighResolutionEl $gap={10}>
                <IconDashboard />
                <span>Dashboard</span>
              </HighResolutionEl>
              <TooltipInLowResolution
                triggerElement={<IconDashboard />}
                message="Dashboard"
              />
            </StyledLink>
            <StyledLink href={PATH_EARN} $isActive={activeMenu === 'earn'}>
              <HighResolutionEl $gap={10}>
                <IconEarn />
                <span>Earn</span>
              </HighResolutionEl>
              <TooltipInLowResolution
                triggerElement={<IconEarn />}
                message="Earn"
              />
            </StyledLink>
          </MenuWrapper>
          <FooterMenuWrapper>
            <a target="_blank" rel="noreferrer" href={LINK_FEEDBACK}>
              <IconDiscord />
            </a>
            <hr />
            <a
              target="_blank"
              rel="noreferrer"
              href={docsLink}
              onClick={handleDocsClick}
            >
              <HighResolutionEl>
                <span>Docs</span>
              </HighResolutionEl>
              <TooltipInLowResolution
                triggerElement={<IconDocs />}
                message="Docs"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={LINK_HELP_CENTER}
              onClick={handleHelpCenterClick}
            >
              <HighResolutionEl>
                <span>Help Center</span>
              </HighResolutionEl>
              <TooltipInLowResolution
                triggerElement={<IconTalk />}
                message="Help Center"
              />
            </a>
            <StyledLink href={PATH_TERMS} onClick={handlePolicyClick}>
              <HighResolutionEl>
                <span>Privacy Policy & Terms of Use</span>
              </HighResolutionEl>
              <TooltipInLowResolution
                triggerElement={<IconDocs2 />}
                message="Privacy Policy & Terms of Use"
              />
            </StyledLink>
            <hr />
            <CopyRight>
              <p>&copy; 2024 Bifrost PTE LTD v1.0</p>
              <span>v1.0</span>
            </CopyRight>
          </FooterMenuWrapper>
        </MenuContainer>
      </Wrapper>
    </StyledSidebar>
  );
}

function TooltipInLowResolution({
  message,
  triggerElement,
}: {
  message: string;
  triggerElement: ReactNode;
}) {
  return (
    <BreakPointEl>
      <Tooltip placement="right">
        <TooltipTrigger asChild>
          <button type="button">{triggerElement}</button>
        </TooltipTrigger>
        <TooltipContent $blur={2} style={{ whiteSpace: 'pre-line' }}>
          {message}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </BreakPointEl>
  );
}
