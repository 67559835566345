import styled from 'styled-components';
import useBannerContent from '@/hooks/useBannerContent';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-32px);
  font-weight: 600;
`;

export default function Banner() {
  const bannerContent = useBannerContent();

  return bannerContent ? <Root>{bannerContent}</Root> : '';
}
