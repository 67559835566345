import styled from 'styled-components';
import { Theme } from '@/styles/theme';
import { PaddingProps } from '@/types/ui';

export interface Props {
  $padding: PaddingProps;
  $rounded?: keyof Theme['rounded'];
  $gap?: keyof Theme['gap'];
  $textSize?: keyof Theme['size'];
  $fontWeight?: keyof Theme['font'];
  $variant: 'primary' | 'secondary';
}

const Button = styled.button<Props>`
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: ${(p) =>
    typeof p.$padding === 'object'
      ? `${p.theme.size[p.$padding.y]} ${p.theme.size[p.$padding.x]}`
      : p.theme.size[p.$padding]};
  background-color: ${(p) =>
    p.$variant === 'primary' ? p.theme.colors.point : '#4068df99'};
  font-size: ${(p) => p.theme.size[p.$textSize ?? 'md']};
  ${(p) => p.theme.border.gray};
  ${(p) => p.theme.gap[p.$gap ?? 'zero']};
  ${(p) => p.theme.font[p.$fontWeight ?? 'regular']};
  ${(p) => p.theme.rounded[p.$rounded ?? 'md']};
  ${(p) => p.theme.transition.default};

  &:hover:not(:disabled) {
    filter: brightness(0.85);
  }

  &:active:not(:disabled) {
    filter: brightness(0.7);
  }

  &:disabled {
    background-color: #22447a66;
    color: rgba(248, 248, 248, 0.4);
    border: 0;
    cursor: default;
  }
`;

export default Button;
