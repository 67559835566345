import {
  Token,
  isValidAddress,
  useChainImage,
  useTokenImage,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { useMemo } from 'react';
import styled from 'styled-components';
import { MAINNET_CHAIN_ID_BIFROST } from '@/configs/chains/mainnet/chainId';
import ImageIcon, { Props as PropsImage } from './atom/ImageIcon';

export interface Props extends Partial<Token>, Pick<PropsImage, 'size'> {
  isHideChain?: boolean;
  chainBorderColor?: string;
}

export const TokenContainer = styled.div<{ size: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size}px;
  font-size: ${({ theme }) => theme.size.xs};
  ${({ theme }) => theme.font.bold}
  color: ${({ theme }) => theme.colors.textGray};
`;
export const SymbolContainer = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.08);
  overflow: hidden;
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size}px;
`;
const ChainImageWrapper = styled.div<{ background?: string }>`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: ${({ background }) => background ?? '#1f1f32'};
  padding: 2px;
  border-radius: 100%;
`;

export default function TokenIcon({
  address,
  chainId,
  symbol,
  isHideChain,
  size = 32,
  chainBorderColor,
}: Props) {
  const validAddress = useMemo(
    () => (isValidAddress(address) ? address : undefined),
    [address]
  );
  const validChainId = useMemo(
    () => (chainId && chainId > 0 ? chainId : undefined),
    [chainId]
  );
  const validToken = useMemo(
    () =>
      validAddress
        ? {
            address: validAddress,
            chainId: validChainId ?? MAINNET_CHAIN_ID_BIFROST,
          }
        : undefined,
    [validAddress, validChainId]
  );

  const { tokenImage } = useTokenImage(validToken);
  const { chainImage } = useChainImage(validChainId);

  const chainSize = useMemo(() => size * 0.375, [size]);

  return (
    <TokenContainer size={size}>
      {tokenImage ? (
        <ImageIcon size={size} src={tokenImage} />
      ) : symbol ? (
        <SymbolContainer size={size}>{symbol}</SymbolContainer>
      ) : (
        ''
      )}
      {!isHideChain && chainImage ? (
        <ChainImageWrapper background={chainBorderColor}>
          <ImageIcon size={chainSize} src={chainImage} />
        </ChainImageWrapper>
      ) : (
        ''
      )}
    </TokenContainer>
  );
}
