import styled from 'styled-components';
import { Theme } from '@/styles/theme';

/**
 * Base Flex Box.
 * If you want to use `display:flex`, set `$flex` to `true`.
 */
export const FlexBox = styled.div<{
  $flex?: boolean;
  $direction?: 'row' | 'column';
  $justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  $align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  $wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  $gap?: keyof Theme['gap'] | number;
  $shrink?: number;
  $basis?:
    | 'auto'
    | 'fill'
    | 'max-content'
    | 'min-content'
    | 'fit-content'
    | 'content'
    | 'inherit'
    | 'initial'
    | 'unset'
    | number;
  $grow?: number;
  $width?: number | string;
  $height?: number | string;
  $minWidth?: number | string;
  $minHeight?: number | string;
  $maxWidth?: number | string;
  $maxHeight?: number | string;
}>`
  display: ${(p) => (p.$flex ? 'flex' : 'block')};
  flex-direction: ${(p) => p.$direction ?? 'row'};
  justify-content: ${(p) => p.$justify ?? 'flex-start'};
  align-items: ${(p) => p.$align ?? 'normal'};
  flex-wrap: ${(p) => p.$wrap ?? 'nowrap'};
  flex-shrink: ${(p) => p.$shrink ?? 'initial'};
  flex-basis: ${(p) =>
    (typeof p.$basis === 'number' ? `${p.$basis}px` : p.$basis) ?? 'initial'};
  flex-grow: ${(p) => p.$grow ?? 'initial'};
  ${(p) =>
    typeof p.$gap === 'number'
      ? `gap: ${p.$gap}px;`
      : p.theme.gap[p.$gap ?? 'zero']};
  width: ${(p) =>
    typeof p.$width === 'string'
      ? p.$width
      : typeof p.$width === 'number'
        ? `${p.$width}px`
        : 'unset'};
  height: ${(p) =>
    typeof p.$height === 'string'
      ? p.$height
      : typeof p.$height === 'number'
        ? `${p.$height}px`
        : 'auto'};
  ${(p) =>
    typeof p.$minWidth === 'string'
      ? `min-width: ${p.$minWidth};`
      : typeof p.$minWidth === 'number'
        ? `min-width: ${p.$minWidth}px;`
        : ''};
  ${(p) =>
    typeof p.$minHeight === 'string'
      ? `min-height: ${p.$minHeight};`
      : typeof p.$minHeight === 'number'
        ? `min-height: ${p.$minHeight}px;`
        : ''};
  ${(p) =>
    typeof p.$maxWidth === 'string'
      ? `max-width: ${p.$maxWidth};`
      : typeof p.$maxWidth === 'number'
        ? `max-width: ${p.$maxWidth}px;`
        : ''};
  ${(p) =>
    typeof p.$maxHeight === 'string'
      ? `max-height: ${p.$maxHeight};`
      : typeof p.$maxHeight === 'number'
        ? `max-height: ${p.$maxHeight}px;`
        : ''};
`;
