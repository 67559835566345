import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import IconInfo from '@/assets/icons/icon-info-24.svg';
import { FlexBox } from '@/components/atom/FlexBox';
import { StyledText } from '@/components/atom/Text';
import useEnv from './useEnv';

const TestnetContainer = styled(FlexBox)`
  display: flex;
  padding: ${(p) => p.theme.size.xxs} ${(p) => p.theme.size.xs};
  border-radius: ${(p) => p.theme.size.xl};
  background-color: ${(p) => p.theme.colors.yellow};
  gap: ${(p) => p.theme.size.xxxs};
`;

const useBannerContent = (): React.ReactNode | undefined => {
  const { t } = useTranslation('common');

  const { isTestnet } = useEnv();

  const bannerContent = useMemo(
    () =>
      isTestnet ? (
        <TestnetContainer>
          <IconInfo />
          <StyledText $fontSize="md" $fontWeight="medium">
            {t('banner.testnetNow')}
          </StyledText>
        </TestnetContainer>
      ) : undefined,
    [isTestnet, t]
  );

  return bannerContent;
};

export default useBannerContent;
