import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '@/components/Icon';
import { CurrencyId } from '@/types/Currency';
import { LanguageId } from '@/types/Language';
import { SettingMenu } from '.';

const Wrapper = styled.div`
  position: relative;

  ul {
    margin-top: 8px;
    text-align: center;
  }

  li + li {
    margin-top: 8px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  color: ${(p) => p.theme.colors.textWhite};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;

  button {
    align-items: center;
    display: flex;
    gap: 4px;
    outline: none;

    p {
      display: inline-block;
      padding: 0 4px;
      background: ${(p) => p.theme.colors.black};
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400;
      color: ${(p) => p.theme.colors.green};
      line-height: 16px;
    }
  }
`;

interface Props {
  isShow: boolean;
  language: LanguageId;
  currency: CurrencyId;
  onMenuClick: (menu: SettingMenu) => () => void;
}

function DefaultSettingDialog({
  isShow,
  language,
  currency,
  onMenuClick: handleMenuClick,
}: Props) {
  const { t } = useTranslation();

  if (!isShow) {
    return <></>;
  }

  return (
    <Wrapper>
      <ul>
        <li>
          <FlexRow>
            <p>{t('common:language')}</p>
            <button type="button" onClick={handleMenuClick('language')}>
              <p>{language === 'ko' ? '한국어' : 'English'}</p>
              <ArrowIcon />
            </button>
          </FlexRow>
        </li>
        <li>
          <FlexRow>
            <p>{t('common:baseUnit')}</p>
            <button type="button" onClick={handleMenuClick('currency')}>
              <p>{currency?.toUpperCase()}</p>
              <ArrowIcon />
            </button>
          </FlexRow>
        </li>
      </ul>
    </Wrapper>
  );
}

export default DefaultSettingDialog;
