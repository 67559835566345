import { useWatchingAddressMap } from '@bifrost-platform/bifront-sdk-react-biholder';
import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MAINNET_TOKENS from '@/configs/tokens/mainnetTokens';
import TESTNET_TOKENS from '@/configs/tokens/testnetTokens';
import getWindow from '@/libs/getWindow';
import * as gtag from '@/libs/gtag';
import isCorrectOrigin from '@/libs/isCorrectOrigin';
import { sendSentryEventConnectWallet } from '@/libs/sentry';
import useBifi from '../api/bifi/useBifi';
import useEverdex from '../api/everdex/useEverdex';
import useAvailableChains from '../api/useAvailableChains';
import useAvailableHandlers from '../api/useAvailableHandlers';
import useAvailablePairs from '../api/useAvailablePairs';
import useBtcfi from '../api/useBtcfi';
import useEnv from '../useEnv';

export type Props = {
  synced?: boolean;
  syncedEnv?: boolean;
  syncedChains?: boolean;
  syncedBtcfiHandlers?: boolean;
  syncedBifiHandlers?: boolean;
  syncedPairs?: boolean;
  syncedBtcfi?: boolean;
  syncedBifi?: boolean;
  syncedEverdex?: boolean;
};

export const Context = createContext<Props>({});

export default function InitializerProvider({ children }: PropsWithChildren) {
  // router
  const router = useRouter();

  // env
  const { isTestnet, sync: syncEnv } = useEnv();

  // wallet
  const { account, providerType, providerInfo } = useWallet();

  // availables
  const { isLoading: isLoadingChains } = useAvailableChains();
  const { isLoading: isLoadingBtcfiHandlers } = useAvailableHandlers('btcfi');
  const { isLoading: isLoadingBifiHandlers } = useAvailableHandlers('bifi');
  const { isLoading: isLoadingPairs } = useAvailablePairs();

  // btcfi
  const { sync: syncBtcfi } = useBtcfi();

  // bifi
  const { sync: syncBifi } = useBifi();

  // everdex
  const { sync: syncEverdex } = useEverdex();

  // watching address
  const { addWatchingAddress } = useWatchingAddressMap();

  // state
  const [syncedEnv, setSyncedEnv] = useState(false);
  const [syncedChains, setSyncedChains] = useState(false);
  const [syncedBtcfiHandlers, setSyncedBtcfiHandlers] = useState(false);
  const [syncedBifiHandlers, setSyncedBifiHandlers] = useState(false);
  const [syncedPairs, setSyncedPairs] = useState(false);
  const [syncedBtcfi, setSyncedBtcfi] = useState(false);
  const [syncedBifi, setSyncedBifi] = useState(false);
  const [syncedEverdex, setSyncedEverdex] = useState(false);

  // memo
  const synced = useMemo(
    () =>
      syncedEnv &&
      syncedChains &&
      syncedBtcfiHandlers &&
      syncedBifiHandlers &&
      syncedPairs &&
      syncedBtcfi &&
      syncedBifi &&
      syncedEverdex,
    [
      syncedBifi,
      syncedBifiHandlers,
      syncedBtcfi,
      syncedBtcfiHandlers,
      syncedChains,
      syncedEnv,
      syncedEverdex,
      syncedPairs,
    ]
  );

  // effect
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vw',
      `${(getWindow()?.innerWidth ?? 0) * 0.01}px`
    );
    document.documentElement.style.setProperty(
      '--vh',
      `${(getWindow()?.innerHeight ?? 0) * 0.01}px`
    );
    getWindow()?.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--vw',
        `${(getWindow()?.innerWidth ?? 0) * 0.01}px`
      );
      document.documentElement.style.setProperty(
        '--vh',
        `${(getWindow()?.innerHeight ?? 0) * 0.01}px`
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let effectDestructor = undefined;

    if (isCorrectOrigin()) {
      const handleRouteChange = (url: string) => {
        gtag.pageview(url, isTestnet);
      };

      router.events.on('routeChangeComplete', handleRouteChange);
      router.events.on('hashChangeComplete', handleRouteChange);

      effectDestructor = () => {
        router.events.off('routeChangeComplete', handleRouteChange);
        router.events.off('hashChangeComplete', handleRouteChange);
      };
    }

    return effectDestructor;
  }, [router.events, isTestnet]);

  // sync
  useEffect(() => {
    syncEnv();
    setSyncedEnv(true);
  }, [syncEnv]);
  useEffect(() => {
    syncBtcfi().then(() => setSyncedBtcfi(true));
  }, [syncBtcfi]);
  useEffect(() => {
    syncBifi().then(() => setSyncedBifi(true));
  }, [syncBifi]);
  useEffect(() => {
    syncEverdex().then(() => setSyncedEverdex(true));
  }, [syncEverdex]);

  useEffect(() => {
    if (!isLoadingChains) {
      setSyncedChains(true);
    }
  }, [isLoadingChains]);
  useEffect(() => {
    if (!isLoadingBtcfiHandlers) {
      setSyncedBtcfiHandlers(true);
    }
  }, [isLoadingBtcfiHandlers]);
  useEffect(() => {
    if (!isLoadingBifiHandlers) {
      setSyncedBifiHandlers(true);
    }
  }, [isLoadingBifiHandlers]);
  useEffect(() => {
    if (!isLoadingPairs) {
      setSyncedPairs(true);
    }
  }, [isLoadingPairs]);

  useEffect(() => {
    setSyncedBtcfi(false);
    setSyncedBifi(false);
    setSyncedEverdex(false);
  }, [account]);

  // connect wallet
  useEffect(() => {
    const connectedProviderType = (
      providerInfo?.info?.name ??
      providerType ??
      ''
    ).toLowerCase();

    if (account && connectedProviderType) {
      sendSentryEventConnectWallet(isTestnet, {
        providerType: connectedProviderType,
        address: account,
      });
    }
  }, [isTestnet, providerInfo?.info?.name, providerType, account]);

  // watching address
  useEffect(() => {
    [...MAINNET_TOKENS, ...TESTNET_TOKENS].forEach(({ chainId, address }) =>
      addWatchingAddress(address, chainId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        synced,
        syncedEnv,
        syncedChains,
        syncedBtcfiHandlers,
        syncedBifiHandlers,
        syncedPairs,
        syncedBtcfi,
        syncedBifi,
        syncedEverdex,
      }}
    >
      {children}
    </Context.Provider>
  );
}
