import { ProviderType } from '@bifrost-platform/bifront-sdk-react-wallet';
import { ImageProps } from 'next/image';
// @ts-ignore
import ImageBiport from '@/assets/icons/icon-biport.svg?url';
// @ts-ignore
import ImageCoinbase from '@/assets/icons/icon-coinbase.svg?url';
// @ts-ignore
import ImageMetamask from '@/assets/icons/icon-metamask.svg?url';
// @ts-ignore
import ImagePockie from '@/assets/icons/icon-pockie.svg?url';
// @ts-ignore
import ImageWalletconnect from '@/assets/icons/icon-walletconnect.svg?url';

const PROVIDERS: {
  name: string;
  type: ProviderType;
  src: ImageProps['src'];
}[] = [
  { name: 'Pockie', type: 'pockie', src: ImagePockie },
  { name: 'Biport', type: 'biport', src: ImageBiport },
  { name: 'MetaMask', type: 'metamask', src: ImageMetamask },
  { name: 'Coinbase', type: 'coinbase', src: ImageCoinbase },
  {
    name: 'Walletconnect',
    type: 'walletconnect',
    src: ImageWalletconnect,
  },
];

export default PROVIDERS;
