// id
export const TESTNET_CHAIN_ID_BIFROST = 49088;
export const TESTNET_CHAIN_ID_ETHEREUM = 11155111;
export const TESTNET_CHAIN_ID_ARBITRUM = 421614;
export const TESTNET_CHAIN_ID_POLYGON = 80001;
export const TESTNET_CHAIN_ID_BSC = 97;
export const TESTNET_CHAIN_ID_BASE = 84531;

export const TESTNET_CHAIN_IDS = [
  TESTNET_CHAIN_ID_BIFROST,
  TESTNET_CHAIN_ID_ETHEREUM,
  TESTNET_CHAIN_ID_ARBITRUM,
  TESTNET_CHAIN_ID_POLYGON,
  TESTNET_CHAIN_ID_BSC,
  TESTNET_CHAIN_ID_BASE,
];
