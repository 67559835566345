import {
  Token,
  ZERO_ADDRESS,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import {
  TESTNET_CHAIN_ID_ARBITRUM,
  TESTNET_CHAIN_ID_BIFROST,
  TESTNET_CHAIN_ID_BSC,
  TESTNET_CHAIN_ID_ETHEREUM,
  TESTNET_CHAIN_ID_POLYGON,
} from '@/configs/chains/testnet/chainId';
import {
  TOKEN_NAME_BFC,
  TOKEN_NAME_BIFI,
  TOKEN_NAME_ETH,
  TOKEN_NAME_BNB,
  TOKEN_NAME_MATIC,
  TOKEN_NAME_WBTC,
  TOKEN_NAME_BTCB,
  TOKEN_NAME_BTCUSD,
  TOKEN_NAME_USDC,
  TOKEN_NAME_USDT,
  TOKEN_NAME_EVERDEX_STAKE_LP,
} from './tokenNames';
import {
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_BIFI,
  TOKEN_SYMBOL_ETH,
  TOKEN_SYMBOL_BNB,
  TOKEN_SYMBOL_MATIC,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_EVERDEX_STAKE_LP,
} from './tokenSymbols';

// native coin
export const TESTNET_TOKEN_ADDRESS_BIFROST_BFC = ZERO_ADDRESS;
export const TESTNET_TOKEN_ADDRESS_ETHEREUM_ETH = ZERO_ADDRESS;
export const TESTNET_TOKEN_ADDRESS_ARBITRUM_ETH = ZERO_ADDRESS;
export const TESTNET_TOKEN_ADDRESS_POLYGON_MATIC = ZERO_ADDRESS;
export const TESTNET_TOKEN_ADDRESS_BSC_BNB = ZERO_ADDRESS;

// bifi
export const TESTNET_TOKEN_ADDRESS_BIFROST_BIFI =
  '0x472C5F1Fe1a4f1e1F2ef0a4e78573a33ba873979';

// wbtc
export const TESTNET_TOKEN_ADDRESS_BIFROST_WBTC =
  '0xb710c446e2e4e162d734580bd6f13725c345e1f7';
export const TESTNET_TOKEN_ADDRESS_ETHEREUM_WBTC =
  '0xfb227d3d049a2cdb7b5b75d5227b50219ede3224';
export const TESTNET_TOKEN_ADDRESS_ARBITRUM_WBTC =
  '0xdd3b4c386729ae16da6f961d7e0d4775c28eca09';
export const TESTNET_TOKEN_ADDRESS_POLYGON_WBTC =
  '0x8ed42d9ae83213813ab077b71a80b9331fb690a2';

// btcb
export const TESTNET_TOKEN_ADDRESS_BIFROST_BTCB =
  '0xa4bde980daabdf9861f87ea89d4854a5ff062755';
export const TESTNET_TOKEN_ADDRESS_BSC_BTCB =
  '0xcb3bcc6300d416a96b6fad1814c00af0031efff0';

// btcusd
export const TESTNET_TOKEN_ADDRESS_BIFROST_BTCUSD =
  '0x9039B6f30aa5bD00c303A3644c16B8Cc8031CE53';

// usdc
export const TESTNET_TOKEN_ADDRESS_BIFROST_USDC =
  '0x28661511CDA7119B2185c647F23106a637CC074f';

// usdt
export const TESTNET_TOKEN_ADDRESS_BIFROST_USDT =
  '0x815e850CDDb2BB8C8afb61266525daFfB9adD7dc';

// everdex stake lp
export const TESTNET_TOKEN_ADDRESS_BIFROST_EVERDEX_STAKE_LP =
  '0xBB3f7BA5d8549129A6AF00DBf058895cedE76252';

export const TESTNET_TOKEN_BIFROST_BFC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_BFC,
  name: TOKEN_NAME_BFC,
  symbol: TOKEN_SYMBOL_BFC,
  decimals: 18,
  isCoin: true,
};
export const TESTNET_TOKEN_ETHEREUM_ETH: Token = {
  chainId: TESTNET_CHAIN_ID_ETHEREUM,
  address: TESTNET_TOKEN_ADDRESS_ETHEREUM_ETH,
  name: TOKEN_NAME_ETH,
  symbol: TOKEN_SYMBOL_ETH,
  decimals: 18,
  isCoin: true,
};
export const TESTNET_TOKEN_ARBITRUM_ETH: Token = {
  chainId: TESTNET_CHAIN_ID_ARBITRUM,
  address: TESTNET_TOKEN_ADDRESS_ARBITRUM_ETH,
  name: TOKEN_NAME_ETH,
  symbol: TOKEN_SYMBOL_ETH,
  decimals: 18,
  isCoin: true,
};
export const TESTNET_TOKEN_POLYGON_MATIC: Token = {
  chainId: TESTNET_CHAIN_ID_POLYGON,
  address: TESTNET_TOKEN_ADDRESS_POLYGON_MATIC,
  name: TOKEN_NAME_MATIC,
  symbol: TOKEN_SYMBOL_MATIC,
  decimals: 18,
  isCoin: true,
};
export const TESTNET_TOKEN_BSC_BNB: Token = {
  chainId: TESTNET_CHAIN_ID_BSC,
  address: TESTNET_TOKEN_ADDRESS_BSC_BNB,
  name: TOKEN_NAME_BNB,
  symbol: TOKEN_SYMBOL_BNB,
  decimals: 18,
  isCoin: true,
};
export const TESTNET_TOKEN_BIFROST_BIFI: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_BIFI,
  name: TOKEN_NAME_BIFI,
  symbol: TOKEN_SYMBOL_BIFI,
  decimals: 18,
};
export const TESTNET_TOKEN_BIFROST_WBTC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const TESTNET_TOKEN_ETHEREUM_WBTC: Token = {
  chainId: TESTNET_CHAIN_ID_ETHEREUM,
  address: TESTNET_TOKEN_ADDRESS_ETHEREUM_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const TESTNET_TOKEN_ARBITRUM_WBTC: Token = {
  chainId: TESTNET_CHAIN_ID_ARBITRUM,
  address: TESTNET_TOKEN_ADDRESS_ARBITRUM_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const TESTNET_TOKEN_POLYGON_WBTC: Token = {
  chainId: TESTNET_CHAIN_ID_POLYGON,
  address: TESTNET_TOKEN_ADDRESS_POLYGON_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
};
export const TESTNET_TOKEN_BIFROST_BTCB: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
};
export const TESTNET_TOKEN_BSC_BTCB: Token = {
  chainId: TESTNET_CHAIN_ID_BSC,
  address: TESTNET_TOKEN_ADDRESS_BSC_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
};
export const TESTNET_TOKEN_BIFROST_BTCUSD: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_BTCUSD,
  name: TOKEN_NAME_BTCUSD,
  symbol: TOKEN_SYMBOL_BTCUSD,
  decimals: 18,
};
export const TESTNET_TOKEN_BIFROST_USDC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_USDC,
  name: TOKEN_NAME_USDC,
  symbol: TOKEN_SYMBOL_USDC,
  decimals: 6,
};
export const TESTNET_TOKEN_BIFROST_USDT: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_USDT,
  name: TOKEN_NAME_USDT,
  symbol: TOKEN_SYMBOL_USDT,
  decimals: 6,
};
export const TESTNET_TOKEN_BIFROST_EVERDEX_STAKE_LP: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFROST_EVERDEX_STAKE_LP,
  name: TOKEN_NAME_EVERDEX_STAKE_LP,
  symbol: TOKEN_SYMBOL_EVERDEX_STAKE_LP,
  decimals: 18,
};

const TESTNET_TOKENS = [
  TESTNET_TOKEN_BIFROST_BFC,
  TESTNET_TOKEN_ETHEREUM_ETH,
  TESTNET_TOKEN_BSC_BNB,
  TESTNET_TOKEN_BIFROST_BIFI,
  TESTNET_TOKEN_BIFROST_WBTC,
  TESTNET_TOKEN_ETHEREUM_WBTC,
  TESTNET_TOKEN_ARBITRUM_WBTC,
  TESTNET_TOKEN_BIFROST_BTCB,
  TESTNET_TOKEN_BSC_BTCB,
  TESTNET_TOKEN_BIFROST_BTCUSD,
  TESTNET_TOKEN_BIFROST_USDC,
  TESTNET_TOKEN_BIFROST_USDT,
  TESTNET_TOKEN_BIFROST_EVERDEX_STAKE_LP,
];

export default TESTNET_TOKENS;
